<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_arrivaldataimport" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 取引先 -->
            <div class="search-autocomplete first-search-item" style="min-width: 360px">
              <c-client-input
                v-model="searchForm.supplierSelected"
                :rules="[rules.supplierRequired]"
                required
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 取込ファイル -->
            <div class="file-input" style="min-width: 600px">
              <c-file-input v-model="searchForm.importfile" required accept=".csv" width="100%" />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 取込ボタン -->
            <v-btn color="primary" id="get-search" class="api-btn" @click="importBtn()">{{
              $t("btn.btn_import")
            }}</v-btn>
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn d-flex justify-space-between">
              <div class="btn-area d-flex">
                <!--検索結果件数表示-->
                <div class="text-label" style="float: left">
                  <span class="toatlNum">総件数：{{ totalCount }}件</span>
                </div>
              </div>
              <div class="btn-area d-flex">
                <!--数量合計領域-->
                <div
                  style="
                    font-weight: bold;
                    font-size: large;
                    border-bottom: double;
                    margin-right: 265px;
                  "
                >
                  <!-- 数量合計-->
                  {{ $t("label.lbl_totalQty") + " " + this.sumQty.toLocaleString() }}
                </div>
                <!-- 登録ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="uplaodBtnDialog()"
                  v-bind:disabled="!activate"
                  >{{ $t("btn.btn_importConfirm") }}</v-btn
                >
                <!-- 画面クリアボタン -->
                <v-btn color="primary" id="btn-search" class="api-btn" @click="clearBtnDialog()">{{
                  $t("btn.btn_clear")
                }}</v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="630px"
        >
        </v-data-table>
        <!-- 詳細画面ポップアップ -->
        <v-dialog v-model="dialogDetails" :max-width="2000" persistent no-click-animation>
          <ReceivingDataImportError
            :isOpenDetailDialog.sync="dialogDetails"
            :entity="detailDialogData"
            class="overflow"
            v-on:closePopup="closePopup"
          />
        </v-dialog>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :closeFlag="infoDialog.closeFlag"
      />
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :title="ConfirmDialog.title"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import Loading from "@/components/loading";
import NavBar from "../../components/NavBar.vue";
import sideMenu from "@/components/Menu";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import ReceivingDataImportError from "../receiving/ReceivingDataImportError.vue";

export default {
  name: appConfig.SCREEN_ID.P_RCV_101,
  components: {
    Loading,
    NavBar,
    sideMenu,
    SimpleDialog,
    ConfirmDialog,
    ReceivingDataImportError,
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    searchForm: {
      // 取引先
      supplierList: [],
      supplierSelected: "",

      // 取込ファイル
      importfile: {},
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      closeFlag: true,
    },
    u_10: false,
    // 検索ボタン押下後、trueにする
    // 総件数
    totalCount: 0,
    // 数量合計
    sumQty: "",
    // 表示の切り替え
    activate: false,
    // 詳細ポップアップ
    dialogDetails: false,
    errorDetailDialogData: [],
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 1,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    // メニュー
    openMenu: null,

    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // テーブルフッター項目
    inputList: [],
    // ソート
    sortItem: "",
    sortOptions: {},
    // テーブルヘッダ項目
    headerItems: [
      // 入荷No
      {
        text: i18n.tc("入荷No"),
        value: "receivingNumber",
        align: "left",
        width: "10%",
        sortable: true,
      },
      // 入荷予定日
      {
        text: i18n.tc("入荷予定日"),
        value: "plannedArrivalDate",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 品番
      {
        text: i18n.tc("品番"),
        value: "productNo",
        align: "left",
        width: "10%",
        sortable: true,
      },
      // 品名
      {
        text: i18n.tc("品名"),
        value: "productName",
        align: "left",
        width: "10%",
        sortable: true,
      },

      // 数量
      {
        text: i18n.tc("入り数"),
        value: "quantity",
        align: "right",
        width: "10%",
        sortable: true,
      },
      // 納品種別
      {
        text: i18n.tc("納品種別"),
        value: "deliveryType",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // グループ名
      {
        text: i18n.tc("グループ名"),
        value: "groupName",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 部品要求者
      {
        text: i18n.tc("部品要求者"),
        value: "partsRequester",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 部品要求者所属
      {
        text: i18n.tc("部品要求者所属"),
        value: "partsRequesterAffiliation",
        align: "left",
        width: "15%",
        sortable: true,
      },
    ],
    // 詳細ERROR画面へ渡す
    detailDialogData: {
      suppliersSelectedName: "",
      list: [],
    },
  }),
  methods: {
    // 初期化
    init() {
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.getCustomInfo();
    },
    // 在庫依頼登録ボタン押下
    getItemList() {
      this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
      this.infoDialog.title = appConfig.DIALOG.title;
      this.infoDialog.isOpen = true;
      this.infoDialog.firstPageFlag = true;
    },
    // 取込ボタン押下後、一覧表示
    importBtn() {
      console.log("importBtn");
      // 初期化
      this.inputList = [];
      this.totalCount = 0;
      this.sumQty = "";

      if (this.$refs.form.validate()) {
        // 取込確定確認POPUP処理
        this.csvImport();
      }
    },
    /**
     * 取込ボタン押下処理
     */
    csvImport() {
      // 取込確定処理
      // 選択したファイルを読み込む
      const selectFile = this.searchForm.importfile;
      // 拡張子チェック
      let fileType = selectFile.type;

      // 取り込んだファイルはCSVの場合
      if (fileType == "text/csv") {
        this.importCSV(selectFile, true)
          .then((result) => {
            this.inListCsvData = result;
          })
          .then(() => {
            this.postCsv();
          });
      } else if (
        fileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType == "application/vnd.ms-excel"
      ) {
        // 取り込んだファイルはエクセルの場合;
        this.importExcel(selectFile)
          .then((result) => {
            this.inListCsvData = result;
          })
          .then(() => {
            this.postCsv();
          });
      } else {
        // 取り込んだファイルはCSVまたはExcel以外場合エラーメッセージを表示する
        this.infoDialog.message = i18n.tc("check.chk_limitSelectFileType");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }
    },

    /**
     * POSTAPI(仮登録POST)
     */

    postCsv() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_101;
      // 取引先Cd
      body.reqIdv.clientCd = this.searchForm.supplierList.map((element) => {
        if (element.value == this.searchForm.supplierSelected) {
          return element;
        }
      })[0].clientCd;
      // 取引先SID
      body.reqIdv.clientSid = this.searchForm.supplierSelected;
      // ファイル名
      body.reqIdv.fileName = this.searchForm.importfile.name;
      // 入荷予定配列
      body.reqIdv.listCsvData = this.inListCsvData;
      // 入出区分(0003)
      body.reqIdv.inOutDiv = "0003";
      // 管理区分(01)
      body.reqIdv.qualityDiv = "01";
      // 有償無償区分(01)
      body.reqIdv.freeCostDiv = "01";
      // 無償理由区分
      body.reqIdv.freecostReasonDiv = "";
      // 処理区分(01)
      body.reqIdv.processDiv = "01";
      // 責任区分(01)
      body.reqIdv.blameDiv = "01";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_INSCHDULESFILE_HAC, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (jsonData.resIdv.schedulesFileTmp.csvErrorCheckResultList.length != 0) {
                const errorList = [];
                this.errorDetailDialogData = [];
                jsonData.resIdv.schedulesFileTmp.csvErrorCheckResultList.forEach((row) => {
                  errorList.push({
                    listNo: row.listNo,
                    row: row.row,
                    column: row.column,
                    message: row.message,
                    value: row.value,
                  });
                  this.errorDetailDialogData = errorList;
                });
                // エラー一覧タブが開く
                this.dialogDetails = true;
                // 詳細画面へ渡す値を設定します。
                this.detailDialogData.list = this.errorDetailDialogData;
                // 選択された取引先名
                this.detailDialogData.suppliersSelectedName = this.setSrhSupplier();
              } else {
                const list = [];
                // ファイル名
                this.fileName = jsonData.resIdv.schedulesFileTmp.fileName;
                // S3登録ファイル名
                this.s3FileName = jsonData.resIdv.schedulesFileTmp.s3FileName;
                // 画面表示項目
                jsonData.resIdv.schedulesFileTmp.scheduleCsvResults.forEach((row) => {
                  let isErrFlg = false;
                  let errMsg = "";
                  let rcvDate = "";
                  if (row.errMsg) {
                    isErrFlg = true;
                    errMsg = row.errMsg;
                    errMsg = errMsg.replace("\r\n", "<br />");
                    this.activate = false;
                  }
                  if (row.scheduleDate.length == 8) {
                    rcvDate =
                      row.scheduleDate.substr(0, 4) +
                      "/" +
                      row.scheduleDate.substr(4, 2) +
                      "/" +
                      row.scheduleDate.substr(6, 2);
                  } else if (row.scheduleDate.length == 6) {
                    rcvDate =
                      row.scheduleDate.substr(0, 2) +
                      "/" +
                      row.scheduleDate.substr(2, 2) +
                      "/" +
                      row.scheduleDate.substr(4, 2);
                  } else {
                    rcvDate =
                      row.scheduleDate.substr(0, 4) +
                      "/" +
                      row.scheduleDate.substr(5, 2) +
                      "/" +
                      row.scheduleDate.substr(8, 2);
                  }
                  list.push({
                    isInListNoFlg: isErrFlg,
                    isShipInsDateFlg: isErrFlg,
                    isItemCdFlg: isErrFlg,
                    isItemNameFlg: isErrFlg,
                    isInQtyeFlg: isErrFlg,
                    isFromNameFlg: isErrFlg,
                    isAddInfoFlg: isErrFlg,
                    // No
                    No: row.no,
                    // 入荷No
                    receivingNumber: row.listNo,
                    // 入荷予定日
                    // plannedArrivalDate: row.scheduleDate,
                    plannedArrivalDate: rcvDate,
                    // rcvInDate: rcvDate,
                    // 品番
                    productNo: row.itemCd,
                    // 品名
                    productName: row.itemName,
                    // 付帯品番
                    itemSubCd: row.itemSubCd ?? "",
                    // ロット
                    lotNo: row.lotNo,
                    // 入り数
                    quantity: row.scheduleQuantity,
                    // 納品種別
                    deliveryType: row.deliveryType,
                    // グループ名
                    groupName: row.groupName,
                    // 部品要求者
                    partsRequester: row.partsRequester,
                    // 部品要求者所属
                    partsRequesterAffiliation: row.belongPartsRequester,
                    // 入荷元
                    fromName: row.fromName,
                    // 入荷元SID
                    fromSid: row.fromSid,
                    // 出荷先SID
                    toSid: row.toSid,
                    // 付帯情報
                    // addInfo: row.addInfo,
                    accInfos: errMsg,
                    csvProcessDiv: row.csvProcessDiv,
                    // errInList:0か1か入って来てエラーか判断する
                  });
                });
                // 画面表示用リストに格納
                this.inputList = list;
                this.isErr();
                // 総件数取得
                this.totalCount = this.inputList.length;
                // 数量合計取得
                let cnt = 0;
                this.sumQty = 0;
                for (let i = 0; i < this.inputList.length; i++) {
                  cnt = cnt + (this.inputList[i].quantity ?? 0);
                }
                this.sumQty = cnt;
                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (let i = 0; i < this.searchForm.supplierList.length; i++) {
        if (this.searchForm.supplierList[i].clientSid == this.searchForm.supplierSelected) {
          return this.searchForm.supplierList[i].name;
        }
      }
    },
    isErr() {
      let countErr = 0;
      for (let i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].isInListNoFlg) {
          countErr += 1;
        }
      }
      if (countErr == 0) {
        this.activate = true;
      }
    },

    // ダイアログオープン
    openPopup() {
      this.dialogDetails = true;
    },
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },
    // u_10_check() {
    uplaodBtnDialog() {
      console.log("uplaodBtnDialog");
      i18n.tc("label.lbl_upload"), (this.ConfirmDialog.title = i18n.tc("btn.btn_ok"));
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", []);
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.uplaodBtn;
    },
    // 登録ボタン押下
    async uplaodBtn() {
      await this.csvConfirm();

      this.infoDialog.title = "結果";
      this.infoDialog.isOpen = true;
      this.infoDialog.firstPageFlag = true;
      this.infoDialog.message = await messsageUtil.getMessage("P-COM-001_005_C", [
        i18n.tc("label.lbl_upload"),
      ]);
    },

    /**
     * POSTAPI(本登録POST)
     */
    csvConfirm() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_101;
      // 取引先Cd
      body.reqIdv.clientCd = this.searchForm.supplierList.map((element) => {
        if (element.value == this.searchForm.supplierSelected) {
          return element;
        }
      })[0].clientCd;
      // 取引先SID
      body.reqIdv.clientSid = this.searchForm.supplierSelected;
      // ファイル名
      body.reqIdv.fileName = this.searchForm.importfile.name;
      // 入荷予定配列
      body.reqIdv.listCsvData = this.inListCsvData;
      // 入出区分(0003)
      body.reqIdv.inOutDiv = "0003";
      // 管理区分(01)
      body.reqIdv.qualityDiv = "01";
      // 有償無償区分(01)
      body.reqIdv.freeCostDiv = "01";
      // 無償理由区分
      body.reqIdv.freecostReasonDiv = "";
      // 処理区分(01)
      body.reqIdv.processDiv = "01";
      // 責任区分(01)
      body.reqIdv.blameDiv = "01";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_INSCHDULESFILEMAIN_HAC, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = false;
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    clearBtnDialog() {
      this.ConfirmDialog.title = i18n.tc("btn.btn_ok");
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.clearBtn;
    },
    clearBtn() {
      this.inputList = [];
      this.totalCount = 0;
      this.sumQty = "";

      this.searchForm.supplierSelected = "";
      this.searchForm.importfile = {};
      this.activate = false;
      this.$refs.form.resetValidation();
    },
    // 取引先API接続
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertJST()
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.searchForm.supplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    page: function (newValue) {
      this.page = newValue;
      this.getItemList("");
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          this.sortItem = sortBy + " " + ascdesc;
          if (sortBy == "No") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.No - b.No;
              } else {
                return b.No - a.No;
              }
            });
          } else if (sortBy == "inListNo") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.inListNo < b.inListNo) {
                  return -1;
                } else if (a.inListNo > b.inListNo) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.inListNo > b.inListNo) {
                  return -1;
                } else if (a.inListNo < b.inListNo) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "shipInsDate") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.shipInsDate < b.shipInsDate) {
                  return -1;
                } else if (a.shipInsDate > b.shipInsDate) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.shipInsDate > b.shipInsDate) {
                  return -1;
                } else if (a.shipInsDate < b.shipInsDate) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "inScheduleQuantity") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.inScheduleQuantity - b.inScheduleQuantity;
              } else {
                return b.inScheduleQuantity - a.inScheduleQuantity;
              }
            });
          } else if (sortBy == "fromName") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.fromName < b.fromName) {
                  return -1;
                } else if (a.fromName > b.fromName) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.fromName > b.fromName) {
                  return -1;
                } else if (a.fromName < b.fromName) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "product") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.itemCd < b.itemCd) {
                  return -1;
                } else if (a.itemCd > b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.itemCd > b.itemCd) {
                  return -1;
                } else if (a.itemCd < b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          }
          return this.inputList;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}
.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
.link {
  text-decoration: none;
}
</style>
