<template>
  <div>
    <v-dialog :value="isShow" :max-width="600" persistent="" no-click-animation>
      <Loading :loadingCounter="loadingCounter" />
      <Loading />
      <v-form ref="newProductForm" lazy-validation>
        <v-card>
          <v-card-title id="sub-bar" class="font-weight-bold pb-4">
            <span id="lbl-screen-name">{{ $t("label.lbl_ProductAdd") }}</span>
          </v-card-title>
          <v-card-text class="pt-8">
            <v-container>
              <v-row align-content="center" class="pr-16">
                <span class="text-h6 font-weight-bold pt-1 pr-16">{{
                  $t("label.lbl_ProductNo")
                }}</span>
                <v-text-field
                  outlined
                  dense
                  v-model="itemCd"
                  :label="$t('label.lbl_ProductNo')"
                  clear-icon="mdi-close-circle"
                  :rules="[rules.isStrLen(this.itemCd, 50)]"
                  clearable
                ></v-text-field>
              </v-row>
              <v-row align-content="center" class="pr-12">
                <span class="text-h6 font-weight-bold pt-1 pr-16">{{
                  $t("label.lbl_ProductName")
                }}</span>
                <v-text-field
                  outlined
                  dense
                  v-model="itemName"
                  :label="$t('label.lbl_ProductName')"
                  clear-icon="mdi-close-circle"
                  :rules="[rules.isStrLen(this.itemName, 50)]"
                  maxlength="50"
                  clearable
                  :error-messages="alertMessageItemName"
                  require
                ></v-text-field>
                <span class="require pl-2" style="padding-top: 20px">*</span>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" class="api-btn" @click="dialogClose">
              {{ $t("btn.btn_close") }}
            </v-btn>

            <v-btn color="primary" class="api-btn" @click="itemRegister">
              {{ $t("btn.btn_insert") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :firstPageFlag="infoDialog.firstPageFlag"
      :okAction="init"
    />
  </div>
</template>

<script>
import Loading from "./loading"; // ローディング画面
import SimpleDialog from "@/components/SimpleDialog";
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "@/assets/scripts/js/MesssageUtil"; // メッセージユーティリティ
import { i18n } from "@/lang/lang.js";
import commonRules from "@/mixins/CommonRules";

export default {
  name: "ItemRegisterDialog", // 表示名
  components: { Loading, SimpleDialog },
  // コンポーネント
  mixins: [commonRules],
  props: {
    clientSid: {
      type: String,
      required: true,
    },
    productSid: {
      type: String,
      default: "",
    },
    // 有効日付
    date: {
      required: false,
      type: [String],
      default: undefined,
    },
    isShow: { type: Boolean, required: true, default: true },
  },
  data: () => ({
    loadingCounter: 0,
    alertMessageItemName: "",
    itemCd: "",
    registerItemCd: "", //登録処理用品番
    itemName: "",
    popNewProductSid: "",
    effectiveDate: "",
    infoDialog: {
      title: "",
      isOpen: false,
    },
  }),
  methods: {
    init() {
      this.itemCd = "";
      this.itemName = "";
      this.popNewProductSid = this.productSid ?? "";
      this.effectiveDate = this.date;
    },
    dialogClose() {
      this.alertMessageItemName = "";
      this.itemCd = "";
      this.itemName = "";
      this.popNewProductSid = "";
      this.infoDialog = {
        title: "",
        isOpen: false,
      };
      this.$refs.newProductForm.resetValidation();
      this.$emit("update:productSid", "");
      this.$emit("update:isShow", false);
    },
    /**
     * 独自採番(試作部品)API ：
     */
    numbering() {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = this.$route.name;
      // 各IF項目
      body.reqIdv = {
        clientSid: this.clientSid, // 取引先SID
        numberingDiv: "01", // 採番区分
        numberingTarget: "ITEM", // 採番対象
      };
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_NUMBERING_PARTS, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // 登録用の品番に独自採番APIから取得した値を設定する
              this.registerItemCd = jsonData.resIdv.generatedNumber;
              resolve();
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          });
      });
    },
    /**
     * 商品登録
     */
    async itemRegister() {
      // 親画面で入荷予定日がブランクになっている場合は、品番登録をさせない
      if (!this.effectiveDate) {
        this.infoDialog.title = appConfig.DIALOG.confirm;
        this.infoDialog.message = messsageUtil.getMessage("P-DST-002_001_E", [
          i18n.tc("label.lbl_arrivalScheduleDate"),
        ]);
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }

      if (!this.itemName) {
        this.infoDialog.message = messsageUtil.getMessage("P-DST-002_001_E", [
          i18n.tc("label.lbl_ProductName"),
        ]);
        this.infoDialog.title = appConfig.DIALOG.confirm;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }

      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 登録する品番の値に画面からの入力値を設定する
      this.registerItemCd = this.itemCd;
      // 品番が未設定の時は独自採番APIによって品番を採番する
      if (!this.registerItemCd) {
        await this.numbering();
      }
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = this.$route.name;
      body.reqIdv = {
        clientSid: this.clientSid, // 取引先SID
        itemCd: this.registerItemCd, // 品番
        validFrom: dateTimeHelper.convertUTC(this.getValidFromDate()), // 有効開始日時
        validTo: "2099/12/31 14:59:59.999", // 有効終了日時
        itemCategoryDiv: "02", // 品目区分
        baseUnit: "09", // 基本単位
        actualWeight: 1, // *実重量
        items40Sid: "2100000040", // *40品目SID
        itemName: this.itemName, // *品名
        itemNameShort: this.itemName.substr(0, 20), // 品名略称
        itemNameKana: "",
        deleteFlg: "0", // *削除フラグ
        serialAircraftnumberFlg: "1", //S/N有無フラグ
        dueDateFlg: "0", //期限日有無フラグ
        itemCommon: [
          {
            officeSid: sessionStorage.getItem("sales_office_sid"), // *営業所SID
            validFrom: dateTimeHelper.convertUTC(this.getValidFromDate()), // 有効開始日時
            validTo: "2099/12/31 14:59:59.999", // *有効終了日時
            inboundUnit: "09", // *入庫単位
            inboundStevedoragePrice: null, // 入庫荷役料
            outboundUnit: "09", // *出庫単位
            outboundUnitQuantity: 1, // *出庫単位数
            outboundStevedoragePrice: null, // 出庫荷役料
            storageUnit: "09", // 保管単位
            storageUnitQuantity: 1, // 保管単位数
            storageChargePrice: null, // 保管荷役料
            inventorycountUnit: "09", //棚卸単位
            inventorycountUnitQuantity: 1, // 棚卸単位数
            inboundUnitQuantity: 1, // *入庫単位数
            lotAircraftnumberFlg: "0", // ロット有無フラグ
            jigCd: null, // 治具CD
            pieceCapacityQuantity: 1, // 個体容量
            pieceCapacityUnit: "", // 個体容量単位
            finalOutboundDate: null, // 最終出庫日
            finalInboundDate: null, // 最終入庫日
            // TODO : 後で単価GrはAPI取得する様に修正
            unitPriceGroup: "HACT-TAN", // 単価Gr
            janCd: "", // JANCD
            itfCd: "", // ITFCD
            itemSurrogateCd: "", // 品番代替CD
            itemPropertiesDiv: "1", // 製品特性区分
            inPackingQuantity: null, // 入荷荷姿数
            orderingPointFlg: 0, // 発注点管理フラグ
            deleteFlg: "0", // *削除フラグ
            subItem: [],
          },
        ],
      };
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_ITEMSEARCH, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.popNewProductSid = jsonData.resIdv.item.itemStandardSid;
              this.$emit("update:productSid", this.popNewProductSid);
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              resolve(response);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            this.$emit("update:isShow", false);
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 有効開始日を yyyy/MM/dd 形式の文字列で取得
     */
    getValidFromDate() {
      const clientSysDatetime = dateTimeHelper.toStringNowYYYY_MM_DD1(dateTimeHelper.convertJST());
      const inscheduleDateobj = new Date(this.effectiveDate);
      const clientSysDateobj = new Date(clientSysDatetime);
      // 入荷予定日が未来日の場合
      if (inscheduleDateobj > clientSysDateobj) {
        // システム日付を返却
        return clientSysDatetime;
      }
      // それ以外は入荷予定日を返却
      return this.effectiveDate;
    },
  },
  computed: {},
  created() {
    this.init();
  },
  mounted() {
    this.init();
  },
  watch: {
    // 親画面での日付の変更を監視
    date(newValue) {
      this.effectiveDate = newValue;
    },
    // productSidが更新された場合
    productSid(newValue) {
      if (!newValue) {
        return;
      }
      this.popNewProductSid = this.productSid ?? "";
      this.itemCd = "";
      this.itemName = "";
    },
    // 部品番号をAPIから取得した場合
    popNewProductSid() {
      this.$emit("update:productSid", this.popNewProductSid);
      this.itemCd = "";
      this.itemName = "";
    },
  },
};
</script>

<style>
#sub-bar {
  color: #000;
  background: #dee6f3;
}
</style>
