export default {
  mixins: [],
  components: {},
  data: () => ({
    // ページング
    pagination: {
      // ページ
      currentPage: 0,
      ignoreWatchCurrentPage: false,
      // 表示件数
      itemsPerPage: 100,
      // 総件数
      totalCount: 0,
      // 総ページ数
      totalPage: 0,
      // リフレッシュ処理
      afterFunction: null,
    },
    // 選択状態管理
    selectionState: {
      // 全選択フラグ
      isAllSelected: false,
      // 部分選択フラグ
      isIndeterminate: false,
      // 全選択
      allSelected: false,
      // 選択
      selectedIds: [],
      // 除外
      excludedIds: [],
      // 対象項目
      targetColumn: "",
    },
  }),
  methods: {
    // ページング初期化
    resetPagination() {
      this.pagination.currentPage = 0;
      this.pagination.itemsPerPage = 100;
      this.pagination.totalCount = 0;
      this.pagination.totalPage = 0;
    },
    // 選択状態初期化
    resetSelectionState() {
      this.selectionState.isAllSelected = false;
      this.selectionState.isIndeterminate = false;
      this.selectionState.allSelected = false;
      this.selectionState.excludedIds = [];
      this.selectionState.selectedIds = [];
    },
    // 選択状態設定
    setSelectionState(reqCom) {
      reqCom.reqComSelectionState = this.selectionState;
      reqCom.reqComSelectionState.selectedIds = [...this.selectionState.selectedIds];
      reqCom.reqComSelectionState.excludedIds = [...this.selectionState.excludedIds];
    },
    // watchを無視してページ設定
    setCurrentPageIgnoringWatch(value) {
      this.pagination.ignoreWatchCurrentPage = true;
      this.pagination.currentPage = value;
      this.$nextTick(() => {
        this.pagination.ignoreWatchCurrentPage = false;
      });
    },
    // 検索後、チェック
    searchAfterSelect() {
      if (this.selectionState.allSelected) {
        // 全選択：true

        this.inputList.forEach((row) => {
          // 選択
          row.check = true;
          // 除外対象が存在する場合、未選択
          if (this.selectionState.excludedIds.length > 0) {
            if (this.selectionState.excludedIds.includes(row[this.selectionState.targetColumn])) {
              row.check = false;
            }
          }
        });

        // 除外対象が存在する場合
        this.selectionState.isIndeterminate =
          this.selectionState.excludedIds.length > 0 ? true : false;
      } else {
        // 全選択：false

        this.inputList.forEach((row) => {
          // 未選択
          row.check = false;
          // 選択対象が存在する場合、選択
          if (this.selectionState.selectedIds.length > 0) {
            if (this.selectionState.selectedIds.includes(row[this.selectionState.targetColumn])) {
              row.check = true;
            }
          }
        });

        // 選択対象が存在する場合
        this.selectionState.isIndeterminate =
          this.selectionState.selectedIds.length > 0 ? true : false;
      }
    },
  },
  computed: {},
  watch: {
    "selectionState.isAllSelected": function (newValue) {
      if (this.inputList.length > 0) {
        // リストが存在する場合

        if (this.selectionState.isAllSelected) {
          this.inputList.forEach((row) => {
            row.check = newValue;
          });
          this.selectionState.allSelected = true;
          this.selectionState.isIndeterminate = false;
          this.selectionState.excludedIds = [];
        } else {
          this.inputList.forEach((row) => {
            row.check = newValue;
          });
          this.selectionState.allSelected = false;
          this.selectionState.isIndeterminate = false;
          this.selectionState.selectedIds = [];
        }
      } else {
        // リストが存在しない場合、全選択：false
        this.$nextTick(() => {
          this.selectionState.isAllSelected = false;
        });
      }
    },
    "pagination.currentPage": function () {
      if (!this.pagination.ignoreWatchCurrentPage && Object.keys(this.searchFilter).length != 0) {
        if (typeof this.pagination.afterFunction === "function") {
          this.pagination.afterFunction();
        }
      }
    },
    "pagination.itemsPerPage": function () {
      this.pagination.currentPage = 1;
      if (Object.keys(this.searchFilter).length != 0) {
        if (typeof this.pagination.afterFunction === "function") {
          this.pagination.afterFunction();
        }
      }
    },
    inputList: {
      handler(newInputList) {
        newInputList.forEach((item) => {
          this.$watch(
            () => item.check,
            (newVal) => {
              if (this.selectionState.allSelected) {
                // 全選択：true

                if (!newVal) {
                  if (
                    !this.selectionState.excludedIds.includes(
                      item[this.selectionState.targetColumn]
                    )
                  ) {
                    this.selectionState.excludedIds.push(item[this.selectionState.targetColumn]);
                    // 総件数と未選択件数が一致する場合
                    if (this.selectionState.excludedIds.length == this.pagination.totalCount) {
                      this.selectionState.isAllSelected = false;
                      this.selectionState.allSelected = false;
                      this.selectionState.excludedIds = [];
                    }
                  }
                } else {
                  this.selectionState.excludedIds = this.selectionState.excludedIds.filter(
                    (id) => id != item[this.selectionState.targetColumn]
                  );
                }

                // 除外対象が存在する場合
                this.selectionState.isIndeterminate =
                  this.selectionState.excludedIds.length > 0 ? true : false;
              } else {
                // 全選択：false

                if (newVal) {
                  if (
                    !this.selectionState.selectedIds.includes(
                      item[this.selectionState.targetColumn]
                    )
                  ) {
                    this.selectionState.selectedIds.push(item[this.selectionState.targetColumn]);
                    // 総件数と選択件数が一致する場合
                    if (this.selectionState.selectedIds.length == this.pagination.totalCount) {
                      this.selectionState.isAllSelected = true;
                      this.selectionState.allSelected = true;
                      this.selectionState.selectedIds = [];
                    }
                  }
                } else {
                  this.selectionState.selectedIds = this.selectionState.selectedIds.filter(
                    (id) => id != item[this.selectionState.targetColumn]
                  );
                }

                // 選択対象が存在する場合
                this.selectionState.isIndeterminate =
                  this.selectionState.selectedIds.length > 0 ? true : false;
              }
            }
          );
        });
      },
      deep: true,
    },
  },
};
