<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <loading-spinner />
    <error-message-dialog />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_arrivalScheduleUpdateDelete" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <!-- 読み取り専用 -->
            <div class="search-autocomplete first-search-item" style="min-width: 320px">
              <c-client-input v-model="commonInput.suppliersSelected" required readonly />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷元-->
            <!-- 読み取り専用条件：以下のケースに１つでも当てはまる場合 -->
            <!-- ・工程実績がある明細が1件以上ある -->
            <!-- ・バッチで処理中 -->
            <!-- ・伝票が入荷済み -->
            <div class="search-autocomplete" style="min-width: 320px">
              <c-arrival-input
                v-model="commonInput.arrivalSelected"
                :clientSid="commonInput.clientSid"
                @getList="
                  (value) => {
                    commonInput.arrivalList = value;
                  }
                "
                :readonly="
                  existsDetailHasOpeHist || existsBatchImportInProgress || isArrivalCompleted
                "
                required
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷No-->
            <!-- 読み取り専用条件：以下のケースに１つでも当てはまる場合 -->
            <!-- ・工程実績がある明細が1件以上ある -->
            <!-- ・バッチで処理中 -->
            <!-- ・伝票が入荷済み -->
            <div
              v-if="existsDetailHasOpeHist || existsBatchImportInProgress || isArrivalCompleted"
              class="textbox-arrivalNo"
              style="min-width: 480px"
            >
              <v-text-field
                outlined
                dense
                class="txt-singles"
                :rules="[rules.inputRequired]"
                v-model="commonInput.inListNo"
                maxlength="50"
                :label="$t('label.lbl_slipNo')"
                :readonly="true"
              />
            </div>
            <div v-else class="textbox-arrivalNo" style="min-width: 480px">
              <v-text-field
                outlined
                dense
                :rules="[rules.inputRequired]"
                v-model="commonInput.inListNo"
                maxlength="50"
                :label="$t('label.lbl_slipNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷予定日付-->
            <!-- 読み取り専用条件：以下のケースに１つでも当てはまる場合 -->
            <!-- ・工程実績がある明細が1件以上ある -->
            <!-- ・バッチで処理中 -->
            <!-- ・伝票が入荷済み -->
            <c-date-picker
              :readonly="
                existsDetailHasOpeHist || existsBatchImportInProgress || isArrivalCompleted
              "
              v-model="commonInput.arrivalScheduleDate"
              :label="$t('label.lbl_arrivalScheduleDate')"
              required
            />
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <div class="btn-area" style="float: left">
                <!-- 戻るボタン-->
                <v-btn class="other-btn" v-on:click="backbtn">
                  {{ $t("btn.btn_back") }}
                </v-btn>

                <!-- 追加ボタン-->
                <!-- 非活性条件：以下のケースに１つでも当てはまる場合 -->
                <!-- ・バッチで処理中 -->
                <!-- ・伝票が入荷済み -->
                <v-btn
                  :disabled="existsBatchImportInProgress || isArrivalCompleted"
                  id="other-btn"
                  class="other-btn"
                  value="1"
                  @click="add"
                >
                  {{ $t("btn.btn_add") }}
                </v-btn>
              </div>
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <div class="btn-area">
                <!-- 修正登録ボタン-->
                <!-- 非活性条件：以下のケースに１つでも当てはまる場合 -->
                <!-- ・バッチで処理中 -->
                <!-- ・伝票が入荷済み -->
                <!-- ・未入力項目あり -->
                <v-btn
                  :disabled="existsBatchImportInProgress || isArrivalCompleted || hasEmptyFields"
                  class="post-btn"
                  value="1"
                  v-on:click="rcvScheAdd"
                >
                  {{ $t("btn.btn_fixinsert") }}
                </v-btn>

                <!-- 伝票削除ボタン-->
                <!-- 非活性条件：以下のケースに１つでも当てはまる場合 -->
                <!-- ・工程実績がある明細が1件以上ある -->
                <!-- ・バッチで処理中 -->
                <!-- ・伝票が入荷済み -->
                <v-btn
                  :disabled="
                    existsDetailHasOpeHist || existsBatchImportInProgress || isArrivalCompleted
                  "
                  class="other-btn"
                  value="2"
                  v-on:click="rcvScheDel"
                >
                  {{ $t("btn.btn_slipdelete") }}
                </v-btn>

                <!-- メッセージダイアログ-->
                <ConfirmDialog
                  :isShow.sync="ConfirmDialog.isOpen"
                  :message="ConfirmDialog.message"
                  :okAction="ConfirmDialog.okAction"
                  :redMessage="ConfirmDialog.redMessage"
                  :screenFlag="ConfirmDialog.screenFlag"
                  :changeFlag="ConfirmDialog.changeFlag"
                  :cancelBtnFlg="ConfirmDialog.cancelBtnFlg"
                />
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <!-- 商品コード/品名-->
            <!-- 読取専用条件：以下のケースに１つでも当てはまる場合 -->
            <!-- ・工程実績がある -->
            <!-- ・バッチで処理中 -->
            <template v-slot:[`item.productCd`]="{ item }" style="padding: 0 5px">
              <div id="table-productCd" v-if="item.cntOpeHist === 0 && !item.modifiedFlg">
                <CItemInputRegistable
                  v-model="item.value"
                  :itemCd.sync="item.itemCd"
                  :itemName.sync="item.itemName"
                  :clientSid="commonInput.suppliersSelected"
                  :date="commonInput.arrivalScheduleDate"
                  :items="commonInput.productList"
                  :rules="[rules.inputRequired]"
                  :errorMessages="item.requiredItemCheckMsg"
                />
              </div>
              <div v-else class="listNo-style" style="text-align: left; font-size: 16px">
                <div>{{ item.itemCd }}</div>
                <div style="text-align: left">({{ item.itemName }})</div>
              </div>
            </template>

            <!-- 部品補足情報-->
            <template v-slot:[`item.partsSubInformation`]="{ item }" style="padding: 0 5px">
              <div class="listNo-style" style="text-align: left; font-size: 16px">
                <div>{{ getSubInformationComment(item, "partsSubInformation") }}</div>
              </div>
            </template>

            <!-- 入り数-->
            <!-- 読取専用条件：以下のケースに１つでも当てはまる場合 -->
            <!-- ・工程実績がある -->
            <!-- ・バッチで処理中 -->
            <template v-slot:[`item.productQty`]="{ item, index }">
              <div id="table-productQty" v-if="item.cntOpeHist === 0 && !item.modifiedFlg">
                <v-text-field
                  class="input-number"
                  outlined
                  dense
                  v-model="item.productQty"
                  @change="changeQty(item, index)"
                  @click="clickPop(item.productQty)"
                  maxlength="9"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck0,
                    rules.limitNumLength,
                  ]"
                />
              </div>
              <div v-else>
                {{ item.productQty }}
              </div>
            </template>
            <!-- 詳細情報ボタン -->
            <template v-slot:[`item.detailInfo`]="{ index, item }">
              <v-btn small @click="openDetailsAdd(index, item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <!-- 削除アイコン -->
            <!-- 非表示条件：以下のケースに１つでも当てはまる場合 -->
            <!-- ・工程実績がある -->
            <!-- ・バッチで処理中 -->
            <template v-slot:[`item.deleteData`]="{ item }">
              <div id="table-deleteData" v-if="item.cntOpeHist === 0 && !item.modifiedFlg">
                <v-btn small @click="deleteItem(item)" text>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-form>
      <v-container fluid style="margin-top: -20px">
        <v-data-table
          id="listSumDataSpecial"
          class="tableData"
          fixed-header
          :page.sync="page"
          :server-items-length="itemsPerPage"
          :items="inputSumList"
          :headers="headerItems"
          disable-filtering
          disable-sort
          disable-pagination
          :hide-default-footer="true"
          hide-default-header
          height="49px"
        >
        </v-data-table>
      </v-container>

      <!-- 詳細情報画面ポップアップ -->
      <detail-info-dialog
        :inListSubSid="detailInfo.inListSubSid"
        :clientSid="detailInfo.clientSid"
        :inScheduleDate="detailInfo.inScheduleDate"
        :itemCd="detailInfo.itemCd"
        :itemName="detailInfo.itemName"
        :itemManageNo="detailInfo.itemManageNo"
        :warehouse="detailInfo.warehouse"
        :locationSid="detailInfo.locationSid"
        :deliveryType="detailInfo.deliveryType"
        :groupName="detailInfo.groupName"
        :retentionDuedate="detailInfo.retentionDuedate"
        :amount="detailInfo.amount"
        :currencyUnits="detailInfo.currencyUnits"
        :controlNumber="detailInfo.controlNumber"
        :specificationNoticeNumber="detailInfo.specificationNoticeNumber"
        :partsRequester="detailInfo.partsRequester"
        :invoiceNo="detailInfo.invoiceNo"
        :partsSubInformation="detailInfo.partsSubInformation"
        :remarks="detailInfo.remarks"
        :hasOpeHist="detailInfo.cntOpeHist !== 0"
        :isBatchImportInProgress="detailInfo.modifiedFlg"
        :isShow.sync="openDatailsAdd"
        @addDetailsInfo="addDetailsInfo"
        ref="detailInfo"
      />

      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :redMessage="infoDialog.redMessage"
        :homePageFlag="infoDialog.homePageFlag"
        :rcvSchHacListFlg="infoDialog.rcvSchHacListFlg"
        :rcvSchHacAddFlg="infoDialog.rcvSchHacAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :okAction="init"
      />
      <!-- 行追加モーダル -->
      <v-dialog v-model="isOpenAddRowDialog" :max-width="3000" persistent>
        <DetailListHACDialog
          :isOpenDetailDialog.sync="isOpenAddRowDialog"
          :viewDataList="changeList"
          :listLength="totalRecord"
          :suppliersSelected="commonInput.suppliersSelected"
          :arrivalScheduleDate="commonInput.arrivalScheduleDate"
          :screenId="screenId"
          v-on:child-event="parentMethod"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import DetailInfoDialog from "@/components/DetailInfoDialog";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import DetailListHACDialog from "@/components/DetailListHACDialog.vue"; // 行追加モーダルダイアログ
import CItemInputRegistable from "@/components/CItemInputRegistable.vue";
export default {
  name: appConfig.SCREEN_ID.P_RCV_103,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    NavBar,
    DetailListHACDialog,
    DetailInfoDialog,
    CItemInputRegistable,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // 画面ID
    screenId: appConfig.SCREEN_ID.P_RCV_103,
    // 入荷伝票SID
    inListSid: "",
    page: 1,
    itemsPerPage: 100,
    totalRecord: 0,
    // 共通入力
    commonInput: {
      // 取引先
      suppliersSelected: "",
      // 取引先SID
      clientSid: "",
      // 取引先プルダウン
      supplierList: [],
      // 入荷先
      arrivalSelected: "",
      // 入荷元プルダウン
      arrivalList: [],
      // 納品種別
      deliveryDivSelected: "",
      // 納品種別プルダウン
      deliveryDivList: [],
      // グループ
      txt_groupName: "",
      groupSelected: "",
      // グループプルダウン
      groupList: [],
      // 部品要求者
      partsRequesterSelected: "",
      // 部品要求者プルダウン
      partsRequesterList: [],
      // 入荷元取得用
      searchArrival: "",
      productHintArrival: "",
      // 入荷No
      inListNo: "",
      // 入荷予定日付
      arrivalScheduleDateCal: "",
      arrivalScheduleDate: "",
      // 品質区分
      arrivalStatusSelected: "01",
      // 品質区分プルダウン
      arrivalStatusList: [],
      // 責任
      isBlame: "01",
      isBlameBadValue: "02",
      blameDivList: [],
      // 無償理由プルダウン
      freeReasonList: [],
      // 処理区分プルダウン
      processDivList: [],
      freeCostDivList: [],
      isDisabledBlame: true,
      // 無償理由
      freeReasonSelected: "",
      // 処理区分
      processDivSelected: "",
      // 品番/品名
      productList: [],
    },
    // キャッシュ・変更部分登録用リスト
    changeList: [],
    // 修正前時点のヘッダ項目の値
    beforeHeaderItemsValueAsJson: {},
    // 修正登録内容の確認時点でのヘッダ項目の値
    afterHeaderItemsValueAsJson: {},

    detailDialog: {
      // 詳細画面KEY
      detailInfoKey: 0,
    },
    detailInfo: {
      warehouse: null,
      locationSid: null,
      deliveryType: null,
      groupName: null,
      retentionDuedate: null,
      amount: null,
      currencyUnits: null,
      controlNumber: null,
      specificationNoticeNumber: null,
      partsRequester: null,
      invoiceNo: null,
      partsSubInformation: null,
      remarks: null,
      cntOpeHist: null,
      modifiedFlg: null,
    },
    // 詳細画面ポップアップ
    openDatailsAdd: false,
    registerDialog: {
      isOpen: false,
    },
    // 総件数
    totalCount: 0,
    // 合計リスト
    inputSumList: [],
    loadingCounter: 0,
    // 一覧index
    editedIndex: -1,
    // バリデーションチェックリスト
    editedList: [],
    // 削除リスト
    deleteList: [],
    // 入荷予定登録ダイアログ
    dialogAddProudct: false,
    // 入力チェックエラーフラグ
    errorFlg: true,
    // フォーカス移動フラグ
    moveFocusFlag: false,
    // 行追加ダイアログが表示されているかどうか
    isOpenAddRowDialog: false,
    // 必須項目未入力エラーメッセージ
    requiredItemCheckMsg: "",
    // 倉庫未入力エラーメッセージ
    warehouseCheckMsg: "",
    // ロケーション未入力エラーメッセージ
    locationCheckMsg: "",
    // 初期データ
    defaultData: [],
    // 入荷予定品目一覧ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        class: "listHeader",
        value: "No",
        width: "60px",
        sortable: false,
        align: "center",
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "50%",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      // 部品補足情報
      {
        text: i18n.tc("label.lbl_partsSubInformation"),
        value: "partsSubInformation",
        width: "50%",
        align: "center",
        sortable: false,
      },
      // 入り数
      {
        text: i18n.tc("label.lbl_productHacQty"),
        value: "productQty",
        width: "200px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      // 詳細情報
      {
        text: i18n.tc("label.lbl_dateilsInfo"),
        value: "detailInfo",
        width: "180px",
        align: "center",
        class: "asta",
      },
      // 削除
      { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "3%", align: "center" },
    ],
    inputList: [],
    //予定一覧のデータ
    arrivalDataList: [],
    //検索に使う予定一覧のデータ
    arrivalInputlDataList: [],
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    accHeader: [
      { text: "キー", value: "accSelected", align: "center", width: "20%" },
      { text: "バリュー", value: "accValue", align: "center", width: "70%" },
      { text: "削除", value: "delete", align: "center", width: "10%" },
    ],
    targetData: [],
    // 伝票が入荷済みかどうか
    isArrivalCompleted: true,
    // 工程実績がある明細が1件以上あるかどうか
    existsDetailHasOpeHist: true,
    // バッチ取込のプロセスが進行中の明細が1件以上あるかどうか
    existsBatchImportInProgress: true,
    // 未入力項目があるかどうか
    hasEmptyFields: true,
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },
  created() {
    // 初期データ保持
    this.defaultData = Object.assign({}, this.$data);

    // 入荷予定APIの検索条件を取得する。
    // 入荷伝票SID
    this.inListSid = this.$route.params.arrSid;

    // 実績登録画面で必要な値
    // 取引先
    this.commonInput.suppliersSelected = this.$route.params.suppliersSelected;
    this.suppliersNm = this.$route.params.suppliersNm;
    // 取引先リスト
    this.commonInput.supplierList = this.$route.params.supplierList;
    // 取引先SID
    if (this.commonInput.supplierList?.length) {
      this.commonInput.clientSid = this.commonInput.supplierList.find((item) => {
        return (item.value = this.$route.params.suppliersSelected);
      }).clientSid;
    }
    // 入荷元ドロップダウン
    this.commonInput.arrivalList = this.$route.params.arrivalList;
    // 入荷元ドロップダウンヒント
    this.commonInput.arrivalNm = this.$route.params.arrivalOmitNm;
    // 入荷元SID（明細）
    this.fromSid = this.$route.params.fromSid;
    // 入荷元
    this.commonInput.arrivalSelected = this.commonInput.fromSid;
    // 入荷NO
    this.commonInput.inListNo = this.$route.params.inListNo;
    // 入荷予定日（明細）
    this.commonInput.arrivalScheduleDate = this.$route.params.arrivalScheduleDate;
    this.commonInput.arrivalScheduleDateCal = this.$route.params.arrivalScheduleDate;
    // 入荷予定伝票SID（明細）
    this.commonInput.arrivalSelected = this.$route.params.fromSid;
    // グループ名
    this.commonInput.txt_groupName = this.$route.params.groupName;
    // 納品種別
    this.commonInput.deliveryDivSelected = this.$route.params.deliveryDivSelected;
    // 部品要求者
    this.commonInput.partsRequesterSelected = this.$route.params.partsRequesterSelected;
    // 処理区分名
    this.commonInput.processDivSelected = this.$route.params.processDivName;
    // 無償理由名
    this.commonInput.freeReasonSelected = this.$route.params.reasonCdName;
    // 入荷予定一覧の検索条件
    this.previousSearchFilter = this.$route.params.searchFilter;
    // 取引先SID
    this.commonInput.suppliersSelected = this.$route.params.suppliersNm;
    // 画面遷移判断フラグ
    this.checkFlg = this.$route.params.checkFlg;
    this.totalRecord = this.inputList.length;
    // 選択状態リスト
    this.targetData = this.$route.params.targetData;

    // 入荷予定情報を取得
    this.getArrivalList();
    // 入荷予定情報明細を取得
    this.getDetail();
  },
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定
     */
    init() {},
    /**
     * 入荷予定修正削除画面：入荷予定を取得します。
     */
    getArrivalList() {
      // GetAPIリクエスト用のConfigを生成します。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 取引先SIDを設定します。
      config.params.clientSid = this.suppliersNm;

      // 営業所SIDを設定します。
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");

      // 入荷伝票SIDを設定します。
      config.params.inListSid = this.inListSid;

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 入荷予定を取得します。
          .secureGet(appConfig.API_URL.BIZ_INSCHEDULES, config)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == "000") {
              // JSONオブジェクトから入荷予定を取得します。
              this.arrivalDataList = jsonData.resIdv.inSchedules;
              // 予定一覧の取得結果を初期化
              this.arrivalInputlDataList = [];
              // 入荷予定に対する繰り返し処理です。
              for (let i = 0; i < this.arrivalDataList.length; i++) {
                // マップを生成します。
                let data = {
                  // 入荷伝票SID
                  inListSid: this.arrivalDataList[i].inListSid,
                  // 営業所SID
                  officeSid: this.arrivalDataList[i].officeSid,
                  // 取引先SID
                  clientSid: this.arrivalDataList[i].clientSid,
                  // 入荷伝票No
                  inListNo: this.arrivalDataList[i].inListNo,
                  // 入荷元名
                  fromName: this.arrivalDataList[i].fromName,
                  // 入荷予定日
                  inScheduleDate: this.arrivalDataList[i].inScheduleDate,
                  // 修正区分
                  modifyDiv: this.arrivalDataList[i].modifyDiv,
                  // 有償無償
                  freeCostDiv: this.arrivalDataList[i].freeCostDiv,
                  // 処理区分
                  processDiv: this.arrivalDataList[i].processDiv,
                  // 処理区分名
                  processDivName: this.arrivalDataList[i].processDivName,
                  // 責任区分
                  blameDiv: this.arrivalDataList[i].blameDiv,
                  // 入荷元SID
                  fromSid: this.arrivalDataList[i].fromSid,
                  // TOSID
                  toSid: this.arrivalDataList[i].toSid,
                  // 理由CD
                  reasonCd: this.arrivalDataList[i].reasonCd,
                  // 業務SID
                  businessSid: this.arrivalDataList[i].businessSid,
                  // 削除フラグ
                  deleteFlg: this.arrivalDataList[i].deleteFlg,
                  // 理由CD名
                  reasonCdName: this.arrivalDataList[i].reasonCdName,
                  // 予定伝票更新日時
                  updateDatetime: this.arrivalDataList[i].updateDatetime,
                  // 完了フラグ
                  completeFlg: this.arrivalDataList[i].completeFlg,
                  // 倉庫
                  warehouseList: this.warehouseList,
                };

                // リストを検索に使う予定一覧に設定します。
                this.arrivalInputlDataList.push(data);
              }

              // タスクを終了します（成功）。
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.homePageFlag = false;
              this.infoDialog.rcvSchHacListFlg = false;
              this.infoDialog.rcvSchHacAddFlg = false;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.isOpen = true;

              // タスクを終了します（失敗）。
              reject();
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.homePageFlag = false;
            this.infoDialog.rcvSchHacListFlg = false;
            this.infoDialog.rcvSchHacAddFlg = false;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.isOpen = true;

            // タスクを終了します（失敗）。
            reject();
          });
        // 成功時の処理です。
      }).then(() => {
        if (this.arrivalInputlDataList.length > 0) {
          // 検索条件領域に値を設定
          // 入荷予定日
          this.arrivalScheduleDate = dateTimeHelper
            .convertUTC2JST(this.arrivalInputlDataList[0].inScheduleDate)
            .substr(0, 10);
          // 入荷元SID
          this.fromSidDataList = this.arrivalInputlDataList[0].fromSid;

          // 入荷済みかどうかを判定するフラグを更新
          if (this.arrivalInputlDataList[0].completeFlg == 0) {
            this.isArrivalCompleted = false;
          }

          // 前後比較用の変更前ヘッダ項目 JSON を更新
          this.beforeHeaderItemsValueAsJson = this.copyHeaderItemsAsJson();
        } else {
          // 処理なし
        }
      });
    },

    /**
     * 入荷予定修正削除：入荷予定明細を取得します。
     */
    getDetail() {
      // ローディング画面の表示をONにします。
      this.loadingCounter = 1;

      this.inputList = [];

      // GetAPIリクエスト用のConfigを生成します。
      const config = this.$httpClient.createGetApiRequestConfig();
      // 入荷伝票SIDを設定します。
      config.params.inListSid = this.inListSid;

      // 削除フラグを設定します。
      config.params.deleteFlg = "0";

      // 基準日を設定します。
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.arrivalScheduleDate);
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageIndex = this.page;
      config.params.reqComPageLimit = this.itemsPerPage;

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 入荷予定明細を取得します。
          .secureGet(appConfig.API_URL.BIZ_INSCHEUDLES_DTL, config)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // JSONオブジェクトから入荷予定明細を取得します。
            const data = jsonData.resIdv.inScheduleDtls;
            this.totalCount = jsonData.resIdv.actualCnt;

            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == "000") {
              // 入荷予定明細に対する繰り返し処理です。
              for (var j = 0; j < data.length; j++) {
                let list = {};

                var itemCd = data[j].itemCd.substring(0, 50).trim();
                var incidental = data[j].itemCd.slice(-50).trim();
                // 入り数にカンマ付与
                const proQtyWithComma = commonUtil.formatToCurrency(
                  Number(data[j].inScheduleQuantity)
                );

                // リストに商品情報を追加します。
                list = {
                  No: j + 1,
                  text: `${itemCd}` + ` ${incidental}` + `（${data[j].itemName}）`,
                  value: data[j].itemStandardSid,
                  name: data[j].itemName,
                  code: data[j].itemCd,
                  isNew: false,

                  inListSid: data[j].inListSid,
                  inListSubSid: data[j].inListSubSid,
                  inListSubNo: data[j].inListSubNo,
                  itemCd: data[j].itemCd,
                  itemStandardSid: data[j].itemStandardSid,
                  itemName: data[j].itemName,
                  itemManageNo: data[j].itemManageNo,
                  warehouseSid: data[j].warehouseSid,
                  locationSid: data[j].locationReserve[0]?.locationSid ?? "",
                  locationNo: data[j].locationReserve[0]?.locationNo ?? "",
                  remarks: data[j].remarks,

                  lotFlg: data[j].lotAircraftnumberFlg,
                  serialFlg: data[j].serialAircraftnumberFlg,
                  dueDateFlg: data[j].dueDateFlg,
                  lotSubkey1Flg: data[j].lotSubkey1Flg,
                  lotSubkey2Flg: data[j].lotSubkey2Flg,
                  quantity: data[j].inboundUnitQuantity,
                  lotNo: data[j].lotNo,

                  productCd: `${data[j].itemCd}` + ` ${incidental}` + `（${data[j].itemName}）`,
                  productQty: proQtyWithComma,
                  caseQuantity: data[j].caseQuantity,
                  ballQuantity: data[j].ballQuantity,
                  pieceQuantity: data[j].pieceQuantity,
                  qualityDiv: data[j].qualityDiv,
                  deleteFlg: data[j].deleteFlg,
                  inScheduleSub: data[j].inScheduleSub,
                  updateDatetime: data[j].updateDatetime,
                  cntOpeHist: data[j].cntOpeHist,
                  completeFlg: data[j].completeFlg,
                  modifiedFlg: data[j].modifiedFlg,
                };
                list.inScheduleSub.map((item) => {
                  if (item.subInformation == "retentionDuedate") {
                    item.subInformationComment = item.subInformationComment
                      ? dateTimeHelper.convertUTC2JST(item.subInformationComment)
                      : "";
                  }
                });
                this.inputList.push(list);
              }
              this.defaultData = JSON.parse(JSON.stringify(this.inputList));

              // 工程実績がある明細が1件でもあるかどうか
              this.existsDetailHasOpeHist = this.checkExistsDetailHasOpeHist();
              // バッチ取込のプロセスが進行中の明細が1件以上あるかどうか
              this.existsBatchImportInProgress = this.checkExistsBatchImportInProgress();
              // タスクを終了します（成功）。
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.homePageFlag = false;
              this.infoDialog.rcvSchAddFlg = false;
              this.infoDialog.rcvSchHacListFlg = false;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.isOpen = true;
              // タスクを終了します（失敗）。
              reject();
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.homePageFlag = false;
            this.infoDialog.rcvSchAddFlg = false;
            this.infoDialog.rcvSchHacListFlg = false;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.isOpen = true;
            // タスクを終了します（失敗）。
            reject();
          })
          .finally(() => {
            // ローディング画面の表示をOFFにします。
            this.loadingCounter = 0;
            // 入荷予定明細取得後、バリデーション
            this.$refs.editedList.validate();
          });
        // 成功時の処理です。
      }).then(() => {
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(this.totalCount));
        let sumList = [];
        sumList.push({
          No: "",
          productCd: i18n.tc("label.lbl_totalNum") + ":",
          productQty: this.totalCount,
          lot: "",
          caseSu: "",
          ballSu: "",
          baraSu: "",
          warehouse: "",
          location: "",
          accInfos: "",
          deleteData: "",
        });
        this.inputSumList = [];
        this.inputSumList = sumList;
      });
    },

    /**
     * 追加モーダルから追加分リスト取得。
     */
    parentMethod: function (isConfig, target) {
      if (isConfig) {
        let totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");
        for (let i = 0; i < target.length; i++) {
          target[i].inListSubNo = target[i].No;
          target[i].value = target[i].productCd;
          target[i].itemStandardSid = target[i].productCd;
          target[i].itemName = target[i].productNm;
          target[i].qualityDiv = "01";
          target[i].deleteFlg = "0";
          target[i].cntOpeHist = 0;
          target[i].modifiedFlg = false;
          target[i].isNew = true;
          let productQty = commonUtil.zen2han(target[i].productQty).replaceAll(",", "");
          totalNum = Number(totalNum) + Number(productQty);
          this.changeList.push(Object.assign({}, target[i]));
          this.inputList.push(Object.assign({}, target[i]));
        }

        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));

        let sumList = [];
        sumList.push({
          No: "",
          productCd: i18n.tc("label.lbl_totalNum") + ":",
          productQty: this.totalCount,
          lot: "",
          caseSu: "",
          ballSu: "",
          baraSu: "",
          warehouse: "",
          location: "",
          accInfos: "",
          deleteData: "",
        });
        this.inputSumList = [];
        this.inputSumList = sumList;

        this.$nextTick(() => {
          this.$refs.editedList.validate();
        });
      }
    },

    /**
     * 入荷予定登録画面：yyyy/MM/ddに変換処理（カレンダコンポーネントの日付はyyyy-MM-ddのため）
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 入荷予定登録画面：行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 削除したリストを退避する。
      if (item.isAdd !== true) {
        this.deleteList.push(this.inputList[this.editedIndex]);
      }

      this.inputList.splice(this.editedIndex, 1);

      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
        this.inputList[i].inListSubNo = i + 1;
      }
      var proQty = commonUtil.zen2han(item.productQty).replaceAll(",", "");
      var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");

      // 予定数合計から削除したレコードの数を引く
      this.totalCount = Number(totalNum) - Number(proQty);
      // カンマ編集
      this.totalCount = commonUtil.formatToCurrency(Number(this.totalCount));
      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
    },

    /**
     * 入荷が進んでいるものがあるかどうかをチェック
     */
    checkExistsDetailHasOpeHist() {
      return (
        0 <
        this.inputList.filter((element) => {
          return element.cntOpeHist !== 0;
        }).length
      );
    },

    /**
     * バッチ取込のプロセスが進行中の明細があるかどうかをチェック
     */
    checkExistsBatchImportInProgress() {
      return (
        0 <
        this.inputList.filter((element) => {
          return element.modifiedFlg;
        }).length
      );
    },

    /**
     * 戻る処理(入荷予定一覧画面への遷移)
     */
    backScreen() {
      this.ConfirmDialog.isOpen = false;
      if (this.errorFlg) {
        this.checkFlg = true;
        this.$router.push({
          name: appConfig.SCREEN_ID.P_RCV_102,
          params: {
            // 取引先
            suppliersSelected: this.suppliersNm,
            // 画面遷移フラグ
            checkFlg: this.checkFlg,
            // 入荷元ドロップダウン
            arrivalSelected: this.arrivalSid,
            arrivalList: this.$route.params.arrivalList,
            // 入荷元ドロップダウンヒント
            arrivalNm: this.$route.params.arrivalOmitNm,
            // 入荷予定一覧検索条件
            searchFilter: this.previousSearchFilter,
            targetData: this.targetData,
          },
        });
      } else {
        this.errorFlg = true;
      }
    },

    /**
     * 入荷予定数0ダイアログの OK ボタン押下
     */
    // updateOk() {
    //   // if (this.errorFlg) {
    //     this.updateDialogMessage = false;
    //     this.checkFlg = true;

    //     this.$router.push({
    //       name: appConfig.SCREEN_ID.P_RCV_102,
    //       params: {
    //         // 取引先
    //         suppliersSelected: this.suppliersNm,
    //         // 画面遷移フラグ
    //         checkFlg: this.checkFlg,
    //         // 入荷元ドロップダウン
    //         arrivalSelected: this.arrivalSid,
    //         arrivalList: this.$route.params.arrivalList,
    //         // 入荷元ドロップダウンヒント
    //         arrivalNm: this.$route.params.arrivalOmitNm,
    //         // 入荷予定一覧検索条件
    //         searchFilter: this.previousSearchFilter,
    //         targetData: this.targetData,
    //       },
    //     });
    //   } else {
    //     this.updateDialogMessage = false;
    //     this.errorFlg = true;
    //   }
    // },

    /**
     * 入荷予定登録画面：行追加処理
     */
    add() {
      // 行追加ダイアログを開く
      this.isOpenAddRowDialog = true;
      this.totalRecord = this.inputList.length;

      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
    },

    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.isDoAddButton = false;
    },

    /**
     * 入荷予定修正削除画面：伝票削除ボタン押下
     */
    rcvScheDel() {
      // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
      this.delFlg = true;
      this.ConfirmDialog.cancelBtnFlg = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_001_C");
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = () => {
        this.deleteRcvSch();
      };
    },

    /**
     * 入荷予定登録画面：入荷予定数、入力カンマ処理
     */
    changeQty(val, index) {
      // カンマを除去して数値項目に変換
      let proQty = commonUtil.zen2han(val.productQty).replaceAll(",", "");
      proQty = proQty.replace(/[^0-9]/gi, "");

      if (proQty.length > 9) {
        proQty = proQty.substring(0, 9);
      }

      if (!isNaN(Number(proQty))) {
        this.inputList[index].productQty = commonUtil.formatToCurrency(Number(proQty));
      }
      let totalNum = 0;
      this.totalCount = 0;
      if (!isNaN(Number(proQty)) || Number(totalNum)) {
        for (let j = 0; j < this.inputList.length; j++) {
          var proNum = commonUtil.zen2han(this.inputList[j].productQty).replaceAll(",", "");

          // 各行の予定数の合計を計算
          totalNum = Number(totalNum) + Number(proNum);
        }
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));
      }

      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
    },

    /**
     * 数値の","(カンマ)を外す処理
     */
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },

    /**
     * 付帯情報/ロケーション予約POPUP：閉じる処理
     */
    close() {
      this.dialogAddProudct = false;
      this.editedIndex = -1;
    },

    /**
     * 入荷予定修正削除画面：戻るボタン処理
     */
    backbtn() {
      this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_003_W");
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.cancelBtnFlg = true;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.okAction = async () => {
        await this.backScreen();
      };
    },

    /**
     * 入荷予定登録画面：登録ボタン押下
     */
    rcvScheAdd() {
      // 前後比較用ヘッダ項目の変更後の JSON を更新
      this.afterHeaderItemsValueAsJson = this.copyHeaderItemsAsJson();

      // 更新チェック
      if (!this.isDataUpdated()) {
        this.infoDialog.message = messsageUtil.getMessage("P-SHP-010_001_E");
        this.infoDialog.title = appConfig.DIALOG.confirm;
        this.infoDialog.homePageFlag = false;
        this.infoDialog.rcvSchAddFlg = false;
        this.infoDialog.rcvSchHacListFlg = false;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.isOpen = true;
        return;
      }

      this.ConfirmDialog.cancelBtnFlg = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_002_C");
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = async () => {
        await this.addRcvSch();
        await this.getArrivalList();
        await this.getDetail();
      };
      // エラー内容初期化処理--------------------------------------------------
      this.loadingCounter = 1;
      for (let i = 0; i < this.inputList.length; i++) {
        this.inputList[i].requiredItemCheckMsg = i18n.tc("");
        this.inputList[i].warehouseCheckMsg = i18n.tc("");
        this.inputList[i].locationCheckMsg = i18n.tc("");
      }
      this.loadingCounter = 0;
      // 伝票部、入力チェック実施
      // 入力チェックエラーなしの場合
      if (this.$refs.form.validate() && this.$refs.editedList.validate()) {
        // 明細がない場合
        if (this.inputList.length === 0) {
          // 上記でのチェックリストが0件の場合は、警告メッセージ表示
          this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_006_E");
          this.ConfirmDialog.cancelBtnFlg = false;
          this.ConfirmDialog.okAction = async () => {
            return;
          };
        } else {
          // 明細部がある場合は、確認メッセージ表示
          this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
        }
      } else {
        // 伝票部、入力チェックエラーの場合、登録処理に進まない
        this.ConfirmDialog.isOpen = false;
      }
    },

    /**
     * 入荷予定登録画面：カーソル位置処理
     */
    setCursor() {
      let listLength = this.inputList.length;
      let cursolPosition = String(listLength - 10);
      eval("this.$refs.cursorProcd" + cursolPosition + ".focus()");
    },

    /**
     * 入荷予定登録画面：入荷予定API（POST）修正
     */
    addRcvSch() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_103;
      body.reqCom.reqComCompanySid = sessionStorage.getItem("comp_sid");
      body.reqCom.reqComOfficeSid = sessionStorage.getItem("sales_office_sid");
      body.reqCom.reqComPaginationFlg = "0";

      // 入荷予定
      // 入荷伝票SID
      body.reqIdv.inListSid = this.inListSid;
      // 営業所SID
      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
      // 入荷伝票No
      body.reqIdv.inListNo = this.commonInput.inListNo;
      // 取引先SID
      body.reqIdv.clientSid = this.commonInput.suppliersSelected;
      // 入荷予定日
      body.reqIdv.inScheduleDate = dateTimeHelper.convertUTC(this.commonInput.arrivalScheduleDate);
      // 有償無償区分
      body.reqIdv.freeCostDiv = this.arrivalInputlDataList[0].freeCostDiv;
      // 処理区分
      body.reqIdv.processDiv = this.arrivalInputlDataList[0].processDiv;
      // 責任区分
      body.reqIdv.blameDiv = this.arrivalInputlDataList[0].blameDiv;
      // 理由区分
      body.reqIdv.reasonCd = this.arrivalInputlDataList[0].reasonCd;
      // fromSid
      body.reqIdv.fromSid = this.commonInput.arrivalSelected;
      // toSid
      body.reqIdv.toSid = sessionStorage.getItem("sales_office_sid");
      // 業務SID
      body.reqIdv.businessSid = this.arrivalInputlDataList[0].businessSid;
      // 削除フラグ
      body.reqIdv.deleteFlg = 0;
      // 更新日時(入荷予定でGETした更新日時)
      body.reqIdv.updateDatetime = this.arrivalInputlDataList[0].updateDatetime;

      // 修正分の明細情報をマッピング
      const bodyInScheduleDtl = [];
      this.inputList.map((value) => {
        // カンマを除去して数値項目に変換
        const proQtyWithoutComma = commonUtil.zen2han(value.productQty).replaceAll(",", "");
        bodyInScheduleDtl.push({
          inListSubSid: value.inListSubSid,
          inListSubNo: value.inListSubNo,
          itemStandardSid: value.value,
          itemCd: value.itemCd,
          lotNo: value.lotNo,
          inScheduleQuantity: proQtyWithoutComma,
          caseQuantity: value.caseQuantity,
          ballQuantity: value.ballQuantity,
          pieceQuantity: value.pieceQuantity,
          qualityDiv: value.qualityDiv,
          deleteFlg: value.deleteFlg,
          updateDatetime: value.updateDatetime,
          locationReserve: value.locationSid !== "" ? [{ locationSid: value.locationSid }] : [],
          infoCategoryDtl: value.inScheduleSub.map((subInfo) => {
            return {
              inScheduleSubSid: subInfo.inScheduleSubSid,
              subInformation: subInfo.subInformation,
              subInformationComment: this.getSubInfoComment(subInfo),
              updateDatetime: subInfo.updateDatetime,
            };
          }),
        });
      });

      // 削除分の明細情報をマッピング
      this.deleteList.map((item) => {
        // カンマを除去して数値項目に変換
        const proQtyWithoutComma = commonUtil.zen2han(item.productQty).replaceAll(",", "");
        bodyInScheduleDtl.push({
          inListSubSid: item.inListSubSid,
          inListSubNo: item.inListSubNo,
          itemStandardSid: item.value,
          itemCd: item.itemCd,
          lotNo: item.lotNo,
          inScheduleQuantity: proQtyWithoutComma,
          caseQuantity: item.caseQuantity,
          ballQuantity: item.ballQuantity,
          pieceQuantity: item.pieceQuantity,
          qualityDiv: item.qualityDiv,
          // 明細削除のため、削除フラグを「１」に設定
          deleteFlg: "1",
          updateDatetime: item.updateDatetime,
          locationReserve: [{ locationSid: item.locationSid }],
          // 付属情報をマッピング
          infoCategoryDtl: item.inScheduleSub.map((subInfo) => {
            return {
              inScheduleSubSid: subInfo.inScheduleSubSid,
              subInformation: subInfo.subInformation,
              subInformationComment: this.getSubInfoComment(subInfo),
              updateDatetime: subInfo.updateDatetime,
              // 付属情報削除のため、削除フラグを「１」に設定
              deleteFlg: "1",
            };
          }),
        });
      });

      // マッピングした明細情報を、リクエストボディに設定
      body.reqIdv.inScheduleDtl = bodyInScheduleDtl;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_INSCHEDULES_HAC, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            // 更新処理成功時はホーム、入荷予定一覧(試作部品)、入荷予定登録画面への遷移ボタンを表示する
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = false;
            this.infoDialog.homePageFlag = true;
            this.infoDialog.rcvSchHacListFlg = true;
            this.infoDialog.rcvSchHacAddFlg = true;
            this.infoDialog.isOpen = true;
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.homePageFlag = false;
              this.infoDialog.rcvSchHacListFlg = false;
              this.infoDialog.rcvSchHacAddFlg = false;
              this.infoDialog.isOpen = true;
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.homePageFlag = false;
            this.infoDialog.rcvSchHacListFlg = false;
            this.infoDialog.rcvSchHacAddFlg = false;
            this.infoDialog.isOpen = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 入荷予定登録画面：入荷予定API（POST）削除
     */
    deleteRcvSch() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_103;
      body.reqCom.reqComCompanySid = sessionStorage.getItem("comp_sid");
      body.reqCom.reqComOfficeSid = sessionStorage.getItem("sales_office_sid");
      body.reqCom.reqComPaginationFlg = "1";

      // 入荷予定
      // 入荷伝票SID
      body.reqIdv.inListSid = this.inListSid;
      // 営業所SID
      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
      // 入荷伝票No
      body.reqIdv.inListNo = this.commonInput.inListNo;
      // 取引先SID
      body.reqIdv.clientSid = this.commonInput.suppliersSelected;
      // 入荷予定日
      body.reqIdv.inScheduleDate = dateTimeHelper.convertUTC(this.commonInput.arrivalScheduleDate);
      // 有償無償区分
      body.reqIdv.freeCostDiv = this.arrivalInputlDataList[0].freeCostDiv;
      // 処理区分
      body.reqIdv.processDiv = this.arrivalInputlDataList[0].processDiv;
      // 責任区分
      body.reqIdv.blameDiv = this.arrivalInputlDataList[0].blameDiv;
      // 理由区分
      body.reqIdv.reasonCd = this.arrivalInputlDataList[0].reasonCd;
      // fromSid
      body.reqIdv.fromSid = this.commonInput.arrivalSelected;
      // toSid
      body.reqIdv.toSid = sessionStorage.getItem("sales_office_sid");
      // 業務SID
      body.reqIdv.businessSid = this.arrivalInputlDataList[0].businessSid;
      // 削除フラグ
      body.reqIdv.deleteFlg = 1;
      // 更新日時(入荷予定でGETした更新日時)
      body.reqIdv.updateDatetime = this.arrivalInputlDataList[0].updateDatetime;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_INSCHEDULES_HAC, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = false;
            this.infoDialog.homePageFlag = true;
            this.infoDialog.rcvSchHacAddFlg = true;
            this.infoDialog.rcvSchHacListFlg = true;
            this.infoDialog.isOpen = true;
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.homePageFlag = false;
              this.infoDialog.rcvSchHacAddFlg = false;
              this.infoDialog.rcvSchHacListFlg = false;
              this.infoDialog.isOpen = true;
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.homePageFlag = false;
            this.infoDialog.rcvSchAddFlg = false;
            this.infoDialog.rcvSchHacListFlg = false;
            this.infoDialog.isOpen = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 付属情報内容を取得
     */
    getSubInfoComment(subInfo) {
      // 項目が保管期限の場合は、UTC に変換
      if (subInfo.subInformation === "retentionDuedate") {
        // UTC変換の共通部品にブランクを渡すと、現在日時が返ってくるため
        // 値がブランクの場合は、共通部品を使わないようにする
        return !subInfo.subInformationComment
          ? ""
          : dateTimeHelper.convertUTC(subInfo.subInformationComment);
      }
      // その以外の項目の場合は文字列に変換
      return String(subInfo.subInformationComment);
    },

    // 詳細情報登録画面をポップアップで開く
    openDetailsAdd(index, item) {
      this.index = index;

      this.detailInfo = {
        inListSubSid: item.inListSubSid,
        clientSid: this.commonInput.suppliersSelected,
        inScheduleDate: this.commonInput.inScheduleDate,
        itemCd: item.itemCd,
        itemName: item.itemName,
        warehouse: item.warehouseSid,
        locationSid: item.locationSid,
        cntOpeHist: item.cntOpeHist,
        modifiedFlg: item.modifiedFlg,
      };
      item.inScheduleSub.map((item) => {
        this.detailInfo[item.subInformation] = item.subInformationComment;
      });
      this.openDatailsAdd = true;
    },

    // 詳細情報登録ボタン押下
    addDetailsInfo(value) {
      this.openDatailsAdd = false;

      this.inputList[this.index].warehouseSid = value.infoCategoryDtl.warehouse;
      this.inputList[this.index].locationSid = value.infoCategoryDtl.locationSid;
      this.inputList[this.index].inScheduleSub.map((item) => {
        return (item.subInformationComment = value.infoCategoryDtl[item.subInformation]);
      });
    },

    /**
     * 更新されているか判定する
     */
    isDataUpdated() {
      // ヘッダ項目が更新されているか
      const isUpdatedHeaderItems = !this.isJsonEqual(
        this.beforeHeaderItemsValueAsJson,
        this.afterHeaderItemsValueAsJson
      );

      // 明細項目が更新されているか
      const updateObj = JSON.stringify(this.inputList);
      const tmpObj = JSON.stringify(this.defaultData);
      const isUpdatedDetailItems = updateObj !== tmpObj;

      if (!isUpdatedHeaderItems && !isUpdatedDetailItems) {
        return false;
      }
      return true;
    },

    /**
     * ヘッダ項目をコピーして差分比較しやすい JSON に変換して返却
     */
    copyHeaderItemsAsJson() {
      const originalObj = {
        clientSid: this.commonInput.suppliersSelected,
        fromSid: this.commonInput.arrivalSelected,
        inListNo: this.commonInput.inListNo,
        arrivalDate: this.commonInput.arrivalScheduleDate,
      };
      const jsonString = JSON.stringify(originalObj);
      const copiedObj = JSON.parse(jsonString);
      return copiedObj;
    },

    /**
     * ２つの JSON の内容が一致しているかどうか
     */
    isJsonEqual(obj1, obj2) {
      // 両方がオブジェクトであるかチェック
      if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
        return false;
      }

      // オブジェクトのキーを取得
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      // キーの数が異なる場合は一致しない
      if (keys1.length !== keys2.length) {
        return false;
      }

      // 各キーについて値を比較
      for (const key of keys1) {
        // もう一方のオブジェクトにキーが存在しない、または値が一致しない場合は一致しない
        if (!keys2.includes(key) || obj1[key] !== obj2[key]) {
          return false;
        }
      }

      // 全てのキーと値が一致する場合
      return true;
    },

    /**
     * 付属情報値取得
     */
    getSubInformationComment(item, key) {
      for (const inScheduleSub of item.inScheduleSub) {
        if (inScheduleSub.subInformation === key) {
          return inScheduleSub.subInformationComment;
        }
      }
      return "";
    },
  },
  computed: {},
  watch: {
    inputList: {
      handler() {
        // 品番/品名と入り数の入力状態を監視
        this.hasEmptyFields = this.inputList.some((input) => {
          const proQtyWithNoComma = commonUtil.zen2han(input.productQty).replaceAll(",", "");
          // 入力されていない箇所がある場合
          return !input.value || !proQtyWithNoComma || proQtyWithNoComma === "0";
        });
      },
      deep: true,
    },
    "commonInput.arrivalScheduleDateCal": {
      handler(val) {
        this.commonInput.arrivalScheduleDate = this.formatDate(val);
      },
      deep: true,
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    "commonInput.arrivalStatusSelected": function (newVal) {
      if (newVal == "02") {
        this.commonInput.isDisabledBlame = false;
        this.commonInput.isBlame = this.commonInput.isBlameBadValue;
      } else {
        this.commonInput.isBlameBadValue = this.commonInput.isBlame;
        this.commonInput.isBlame = "01";
        this.commonInput.isDisabledBlame = true;
      }
    },
  },
  mounted() {
    this.init();
  },
  onUpdate() {},
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}
#lbl-user-name {
  // font-size: $menu-fontsize !important;
  font-weight: bold;
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
}
#listSumDataSpecial {
  ::-webkit-scrollbar-thumb {
    background: red;
  }

  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child {
      background-color: #eee;
      height: 0rem;
    }

    // グループヘッダー
    .v-row-group__header {
      background-color: #dde5f0;
      height: 0px;
    }

    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #ffffff;
    }

    // テーブルデータ部分
    td {
      font-size: large;

      &:nth-child(1) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(2) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(3) {
        text-align: right;
        width: 11%;
      }

      &:nth-child(4) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(5) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(6) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(7) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(8) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(9) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(10) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(11) {
        text-align: right;
        width: 5%;
      }
    }
  }

  white-space: nowrap;
  pointer-events: none;
  font-weight: 700;
  // overflow-y: scroll;
  // overflow-y: hidden;
}

.tableData {
  border-collapse: collapse;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 12px;
}

.highlight-column {
  background-color: rgb(221, 229, 240);
}

::v-deep .custom-table table {
  border-collapse: collapse !important;
}

.custom-table th,
.custom-table td {
  border: 1px solid rgb(118, 118, 118) !important;
  height: 56px !important;
}

.custom-table thead tr:first-child th {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid rgb(118, 118, 118) !important;
}

.custom-table tr:hover {
  background-color: transparent !important;
}

::v-deep .right-align-input .v-input__control .v-input__slot input {
  text-align: right !important;
}
</style>
