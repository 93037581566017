<template>
  <v-container>
    <Loading :loadingCounter="loadingCounter" />
    <v-row justify="end" align="center">
      <v-img
        id="company-logo"
        alt="company Logo"
        class="shrink mr-2"
        contain
        src="../../assets/img/nikkon_logo.png"
        transition="scale-transition"
        width="50"
      />
      <v-img
        id="company-name-logo"
        alt="company Name Logo"
        class="shrink mr-2"
        contain
        src="../../assets/img/nikkon_name_logo.png"
        transition="scale-transition"
        width="250"
      />
    </v-row>
    <v-row justify="center" align-content="center">
      <v-col>
        <v-card id="login-form" class="login-form-img">
          <p class="text-left">{{ resultMessage }}</p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import Loading from "../../components/loading.vue";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { v4 } from "uuid";

export default {
  name: appConfig.SCREEN_ID.P_STK_111,
  components: {
    Loading,
  },
  data: () => ({
    sidParam: "",
    resultMessage: "",
    loadingCounter: 0,
  }),
  created() {
    //
  },
  mounted() {
    this.sidParam = this.$route.query.sid;
    this.init();
  },
  methods: {
    init() {
      this.download();
    },
    async download() {
      this.loadingCounter++;

      const reqCom = {};
      reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STK_110;
      reqCom.reqComCompanySid = "1000000100";
      reqCom.reqComOfficeSid = "2100000600";
      reqCom.reqComDeviceImei = "WEB";
      reqCom.reqComAppVersion = "24.12.01";
      reqCom.reqComExecUser = "600";
      reqCom.reqComExecTimestamp = dateTimeHelper.convertUTC();
      reqCom.reqComRequestId = v4();
      reqCom.reqComLanguageCd = "ja";
      reqCom.reqComTimeZone = dateTimeHelper.getTimeZone();

      const reqIdv = {};
      reqIdv.pickingGrSids = [this.sidParam];

      const body = {};
      body.reqCom = reqCom;
      body.reqIdv = reqIdv;

      const baseUrl = "https://fg-cirrus.nikkon-is.com";
      const apiUrl = appConfig.API_URL.BIZ_PICKING_LIST_PARTS.replace("business", "hacbiz");
      const redirectUrlOnError = `${baseUrl}/P-STK-110/${this.sidParam}`;
      const url = `${baseUrl}/${apiUrl}`;

      try {
        const bodyData = JSON.stringify(body);
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: headers,
          body: bodyData,
        });
        const jsonData = await response.json();
        this.loadingCounter--;
        if (jsonData.resCom.resComCode === appConfig.RESCOMCODE_SUCCESS) {
          this.resultMessage = "ファイルのダウンロードが完了しました。";
          location.href = jsonData.resIdv.filePath;
        } else {
          location.href = redirectUrlOnError;
        }
      } catch (ex) {
        console.log(ex);
        location.href = redirectUrlOnError;
      } finally {
        this.loadingCounter = 0;
      }
    },
  },
  computed: {
    //
  },
  watch: {
    //
  },
};
</script>

<style scoped lang="scss">
.span1 {
  width: 4.3rem;
}
</style>
