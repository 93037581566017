<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_receivingAchieveAdd" />
      <!-- （画面名）入荷実績登録-->
      <v-container fluid>
        <!-- 検索項目-->
        <v-row class="search-row">
          <div class="search-autocomplete first-search-item">
            <div v-if="suppliersSelected.length > 20">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div style="height: 20px" v-bind="attrs" v-on="on">
                    <v-text-field
                      dense
                      readonly
                      v-model="suppliersSelected"
                      :label="$t('label.lbl_supplier')"
                      class="txt-single"
                      persistent-hint
                      margin-bottom="0"
                      pa-3
                    ></v-text-field>
                  </div>
                </template>
                <span>{{ suppliersSelected }} </span>
              </v-tooltip>
            </div>
            <div v-else>
              <div>
                <v-text-field
                  dense
                  readonly
                  v-model="suppliersSelected"
                  :label="$t('label.lbl_supplier')"
                  class="txt-single"
                  persistent-hint
                  margin-bottom="0"
                  pa-3
                ></v-text-field>
              </div>
            </div>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <div class="search-autocomplete">
            <!-- 入荷元-->
            <v-text-field
              dense
              readonly
              v-model="arrivalSelected"
              :label="$t('label.lbl_arrival')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <div class="textbox-arrivalNo">
            <!-- 入荷No-->
            <v-text-field
              readonly
              dense
              class="txt-single"
              v-model="txt_slipNo"
              maxlength="50"
              :label="$t('label.lbl_slipNo')"
            />
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <div class="date-style">
            <!-- 入荷予定日-->
            <v-text-field
              readonly
              dense
              v-model="arrivalScheduleDate"
              :label="$t('label.lbl_arrivalScheduleDate')"
              class="txt-single"
            ></v-text-field>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <div class="search-autocomplete">
            <!-- 品質区分-->
            <v-text-field
              readonly
              dense
              v-model="arrivalStatusSelected"
              :label="$t('label.lbl_arrivalStatus')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>
        </v-row>
        <v-row>
          <div class="search-row-exeBtn first-search-item padding-bottom">
            <div class="search-autocomplete" style="float: left">
              <!-- 処理区分-->
              <v-text-field
                dense
                readonly
                v-model="processDivSelected"
                :label="$t('label.lbl_processingdivision')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
              ></v-text-field>
            </div>
          </div>
        </v-row>
        <v-row>
          <div class="search-row-exeBtn">
            <div class="btn-area" style="float: left">
              <!-- 戻るボタン-->
              <v-btn class="other-btn" v-on:click="backbtn">
                {{ $t("btn.btn_back") }}
              </v-btn>
            </div>
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <div class="btn-area">
              <!-- 登録ボタン-->
              <v-btn class="post-btn" value="1" v-on:click="rcvAchAdd">
                {{ $t("btn.btn_insert") }}
              </v-btn>
              <!-- メッセージダイアログ-->
              <ConfirmDialog
                :isShow.sync="ConfirmDialog.isOpen"
                :message="ConfirmDialog.message"
                :okAction="deleteStock"
                :redMessage="ConfirmDialog.redMessage"
                :screenFlag="ConfirmDialog.screenFlag"
                :changeFlag="ConfirmDialog.changeFlag"
              />
            </div>
          </div>
        </v-row>
      </v-container>
      <!-- データテーブル（項目） -->
      <v-form ref="editedList" @submit.prevent lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <!-- 品番/付帯 -->
            <template v-slot:[`item.itemCd`]="{ item }">
              <div class="listNo-style">
                {{ item.itemCd }}<br />
                {{ item.incidental }}
              </div>
            </template>
            <!-- ロット -->
            <template v-slot:[`item.lot`]="{ item }">
              <!-- S/Nが15文字以上だった場合ツールチップで表示 -->
              <div v-if="item.lot.length > 15">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-truncate" style="max-width: 350px" v-bind="attrs" v-on="on">
                      {{ item.lot }}
                    </div>
                  </template>
                  <span>{{ item.lot }}</span>
                </v-tooltip>
              </div>
              <!-- serialが15文字以下だった場合ツールチップ無しで表示 -->
              <div v-else>
                {{ item.lot }}
              </div>
            </template>
            <template v-slot:[`item.ballSu`]="{ item, index }">
              <div
                class="table-actualQuantity"
                v-if="item.completeFlg == '1' || item.preProcessingFlg == '1'"
              >
                <!-- ポール数-->
                <span class="actual-quantity">{{ item.ballSu }}</span>
              </div>
              <div v-else>
                <v-text-field
                  outlined
                  dense
                  v-model="item.ballSu"
                  class="input-number"
                  @change="changeBall(item, index)"
                  @click="clickPop(item.ballSu)"
                  persistent-hint
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheckBara,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'ballSuCheck' + index"
                />
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-form>
      <!-- 戻るボタンのダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p style="white-space: pre-wrap">{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn v-if="errorFlg" @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <v-dialog v-model="simpleDialog" persistent="" no-click-animation>
        <SimpleDialog
          :isShow.sync="simpleDialog"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :homePageFlag="infoDialog.homePageFlag"
          :firstPageFlag="infoDialog.firstPageFlag"
          :rcvSchHacListFlg="infoDialog.rcvSchHacListFlg"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import NavBar from "../../components/NavBar.vue";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { controlNumberUtil } from "../../assets/scripts/js/ControlNumberUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { i18n } from "../../lang/lang.js";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_RCV_111,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    loadingCounter: 0,
    // バリデーションチェックリスト
    editedList: [],
    // 取引先
    suppliersSelected: "",
    // 入荷元
    arrivalSelected: "",
    // 入荷No
    txt_slipNo: "",
    // 入荷予定日付
    arrivalScheduleDate: "",
    // 品質区分
    arrivalStatusSelected: "",
    // 責任
    isRes: "",
    blameDivList: [],
    // 有償無償
    isPaid: "",
    freeCostDivList: [],
    // 無償理由
    freeReasonSelected: "",
    // 処理区分
    processDivSelected: "",
    // メニュー
    openMenu: null,
    // 入力チェックエラーフラグ
    errorFlg: true,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    simpleDialog: false,
    //チェックcount
    checkCount: 0,
    // 入荷予定品目一覧ヘッダ
    headerItems: [
      {
        // No
        text: "No",
        value: "inListSubNo",
        width: "2%",
        sortable: false,
        align: "center",
      },
      {
        // 品番
        text: i18n.tc("label.lbl_productCode"),
        value: "itemCd",
        width: "13%",
        align: "left",
        sortable: false,
      },
      {
        // 品名
        text: i18n.tc("label.lbl_productCodeName"),
        value: "itemName",
        width: "9%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot"),
        value: "lot",
        width: "9%",
        align: "left",
      },
      {
        // 入荷予定数
        text: i18n.tc("label.lbl_productQty"),
        value: "productQty",
        width: "5%",
        align: "center",
      },
      {
        // 差
        text: i18n.tc("label.lbl_difference"),
        value: "difference",
        width: "5%",
        align: "center",
      },
      {
        // 入荷実績数
        text: i18n.tc("label.lbl_productAchieveQty"),
        value: "acheieveQty",
        width: "5%",
        align: "center",
      },
      {
        // 数量
        text: i18n.tc("label.lbl_quantity"),
        value: "ballSu",
        width: "9%",
        align: "center",
      },
    ],
    inputList: [],
    chengeCount: [],
    checkFlg: false,
  }),

  created() {
    // 実績登録画面で必要な値
    // 取引先(実績登録画面表示用)
    this.suppliersSelected = this.$route.params.suppliersSelected;
    // 入荷元名（実績登録画面表示用）
    this.arrivalSelected = this.$route.params.arrivalSelected;
    // 入荷元SID（明細）
    this.fromSid = this.$route.params.fromSid;
    // 入荷No（明細）
    this.txt_slipNo = this.$route.params.inListNo;
    // 入荷予定日（明細）
    this.arrivalScheduleDate = this.$route.params.arrivalScheduleDate.substr(0, 10);
    // 入荷予定伝票SID（明細）
    this.arrSid = this.$route.params.arrSid;
    // 有償無償
    this.isPaid = this.$route.params.isPaid;
    // 責任区分(自責/他責)
    this.isRes = this.$route.params.blameDiv;
    // 処理区分名
    this.processDivSelected = this.$route.params.processDivName;
    // 無償理由名
    this.freeReasonSelected = this.$route.params.reasonCdName;
    // 予定更新日時
    this.scheduleUpdateDatetime = this.$route.params.scheduleUpdateDatetime;
    // 品質区分
    this.qualityDiv = this.$route.params.qualityDiv;
    // 入荷予定一覧の検索条件
    this.previousSearchFilter = this.$route.params.searchFilter;
    // 取引先SID
    this.suppliersNm = this.$route.params.suppliersNm;
    // 入荷元SID
    this.arrivalSid = this.$route.params.arrivalSid;
    // 処理区分(通常/返品)（明細）
    this.arrivalStatusSelectedList = this.$route.params.processingdivision;
    // 画面遷移判断フラグ
    this.checkFlg = this.$route.params.checkFlg;
    // コードマスタ取得
    this.getMstCode();
    //入荷予定から取得した値をセット
    this.getDetail();
  },
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定(入荷予定一覧から値受け取る)
     */
    init() {
      // ceatedにて記載
    },

    /**
     * コードマスタ取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // 責任区分
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);
      Promise.all([freeCostList, blameList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.freeCostDivList = result[0];
          // 責任区分の最初のなしを削除
          result[1].splice(0, 1);
          this.blameDivList = result[1];
        })
        .catch((ex) => {
          this.showMessage(ex);
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * メニューダイアログ処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 入荷実績数、差計算
     */
    changeQty(val, index) {
      // 数量、数値変換
      var ballSu = commonUtil.zen2han(val.ballSu).replaceAll(",", "");

      if (Number(ballSu) || ballSu == 0) {
        // 差の計算
        var calculation = Number(ballSu);
        var calculationAfter = Number(this.inputList[index].oriAcheieveQty) + Number(calculation);

        // 入荷予定数、数値変換
        var inScheduleQuantity = commonUtil
          .zen2han(this.inputList[index].productQty)
          .replaceAll(",", "");
        // 差の計算（入荷実績数-入荷予定数）
        var subtraction = calculationAfter - inScheduleQuantity;
        if (Math.sign(subtraction) == 1) {
          this.inputList[index].difference = "+" + commonUtil.formatToCurrency(Number(subtraction));
        } else if (Math.sign(subtraction) == 0 || Math.sign(subtraction) == -1) {
          this.inputList[index].difference = commonUtil.formatToCurrency(Number(subtraction));
        }
      }
    },

    /**
     * 戻るボタン処理
     */
    backbtn() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    /**
     * 一覧画面へ渡す値を設定
     */
    updateOk() {
      if (this.errorFlg) {
        this.updateDialogMessage = false;
        this.checkFlg = true;

        this.$router.push({
          name: appConfig.SCREEN_ID.P_RCV_102,
          params: {
            suppliersSelected: this.suppliersNm,
            arrivalSelected: this.arrivalSid,
            // 画面遷移フラグ
            checkFlg: this.checkFlg,
            // 入荷元一覧
            arrivalList: this.$route.params.arrivalList,
            arrivalNm: this.$route.params.arrivalSelected,
            // 入荷予定一覧検索条件
            searchFilter: this.previousSearchFilter,
            // 一覧画面から引き継いだ予定リスト出力対象のSIDリスト
            targetData: this.$route.params.targetData,
          },
        });
      } else {
        this.updateDialogMessage = false;
        this.errorFlg = true;
      }
    },

    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 入力カンマ処理 数量
     */
    changeBall(val, index) {
      var ballSu = commonUtil.zen2han(val.ballSu).replaceAll(",", "");
      ballSu = ballSu.replace(/[^0-9]/gi, "");
      if (ballSu.length > 9) {
        ballSu = ballSu.substring(0, 9);
      }
      if (!isNaN(Number(ballSu))) {
        this.inputList[index].ballSu = commonUtil.formatToCurrency(Number(ballSu));
      }
      // 数値変換
      this.changeQty(val, index);
    },
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    /**
     * 登録ボタン押下
     */
    rcvAchAdd() {
      let cnt = 0;
      let cntChenge = [];
      this.chengeCount = [];
      if (this.$refs.editedList.validate()) {
        // 明細部、入力チェック実施
        for (var i = 0; i < this.inputList.length; i++) {
          var checkBallNum = Number(
            commonUtil.zen2han(this.inputList[i].ballSu).replaceAll(",", "")
          );

          // 数値かつ整数チェック
          if (!Number.isInteger(checkBallNum)) {
            // 数量が数値でない、または整数でない時
            cnt = i + 1;
          }

          if (this.inputList[i].oriBallSu == this.inputList[i].ballSu) {
            // 修正していないレコードを探し、レコード数を数える
            cntChenge.push(this.inputList[i]);
          } else {
            this.chengeCount.push(this.inputList[i]);
          }
        }
        // 明細部、入力エラーなし（カウント0）かつ、修正していないレコードがinputListのレコード数と一致しない場合、確認メッセージ表示
        if (cnt == 0 && cntChenge.length != this.inputList.length) {
          this.getLotSerialCheck();
        } else {
          if (cntChenge.length == this.inputList.length) {
            // 修正点がなかった時
            this.errorFlg = false;
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-RCV-006_001_E");
          }
        }
      } else {
        // 処理なし
      }
    },

    /**
     * ロット、S/Nの項目チェック
     */
    getLotSerialCheck() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      //商品明細取得
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所SID
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 商品共通SID
      config.params.itemStandardSid = this.chengeCount[this.checkCount].itemStandardSid;
      // 削除Flg
      config.params.deleteFlg = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_ITEMSEARCH_DETAILS, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            var count = 0;
            // 成功
            if (jsonData.resCom.resComCode == "000") {
              let list = jsonData.resIdv.itemsStandard.itemCommons;
              for (let i = 0; i < list.length; i++) {
                let validFrom = null;
                let validTo = null;
                let arrivalDate = new Date(this.arrivalScheduleDate);
                let checkFlg = false;
                if (list[i].validFrom != null) {
                  validFrom = dateTimeHelper.convertUTC2JST(list[i].validFrom);
                }
                if (list[i].validTo != null) {
                  validTo = dateTimeHelper.convertUTC2JST(list[i].validTo);
                }
                if (validTo != null) {
                  if (arrivalDate >= new Date(validFrom) && arrivalDate <= new Date(validTo)) {
                    checkFlg = true;
                  }
                } else {
                  checkFlg = true;
                }
                if (checkFlg) {
                  //
                  //日付チェック
                  var lotSerial = this.chengeCount[this.checkCount].lot.split("／");
                  //ロット有無チェック
                  if (
                    list[i].lotAircraftnumberFlg != null &&
                    list[i].lotAircraftnumberFlg != "" &&
                    list[i].lotAircraftnumberFlg != "0"
                  ) {
                    //ロット有無が有りの場合
                    if (lotSerial[0].trim() == "-") {
                      //エラーメッセージ表示
                      this.errorFlg = false;
                      this.updateDialogMessage = true;
                      this.backMessage = messsageUtil.getMessage("P-RCV-006_003_E", [
                        this.chengeCount[this.checkCount].itemCd,
                        this.chengeCount[this.checkCount].incidental,
                        "ロット",
                      ]);
                      count++;
                      break;
                    }
                  } else {
                    //ロット有無が無しの場合
                    if (lotSerial[0].trim() != "-") {
                      //エラーメッセージ表示
                      this.errorFlg = false;
                      this.updateDialogMessage = true;
                      this.backMessage = messsageUtil.getMessage("P-RCV-006_004_E", [
                        this.chengeCount[this.checkCount].itemCd,
                        this.chengeCount[this.checkCount].incidental,
                        "ロット",
                      ]);
                      count++;
                      break;
                    }
                  }
                }
              }
              this.checkCount++;
              if (count == 0) {
                if (this.checkCount < this.chengeCount.length) {
                  this.getLotSerialCheck();
                } else {
                  // ローディング画面表示OFF
                  this.loadingCounter = 0;
                  this.checkCount = 0;
                  this.addFlg = true;
                  this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_002_C");
                  this.ConfirmDialog.isOpen = true;
                  this.ConfirmDialog.screenFlag = true;
                  this.ConfirmDialog.changeFlag = false;
                  resolve();
                }
              } else {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
                this.checkCount = 0;
              }
            } else {
              // エラー時
              this.showMessage(jsonData.resCom.resComMessage);
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          });
      });
    },

    /**
     * 検索処理（入荷実績差異GETAPI）
     */
    getDetail() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      // 入荷伝票SID
      config.params.inListSid = this.arrSid;
      // 工程コード(入荷)
      config.params.procCd = "100";
      // 基準日対応
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.arrivalScheduleDate);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_INDIFFERENC, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            const apiData = jsonData.resIdv.inDifference;
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              for (var j = 0; j < apiData.length; j++) {
                // API結果：入荷予定一覧明細
                let apiDataItem = apiData[j];
                // API結果保持変数
                let inputListItem = {};
                // 入荷伝票明細SID
                inputListItem.inListSubSid = apiDataItem.inListSubSid;
                // 入荷伝票明細No
                inputListItem.inListSubNo = apiDataItem.inListSubNo;
                // 品番
                inputListItem.itemCdShow = apiDataItem.itemCd;
                inputListItem.itemCd = !apiDataItem.itemCd
                  ? ""
                  : apiDataItem.itemCd.substring(0, 50).trim();
                //付帯
                inputListItem.incidental = !apiDataItem.itemCd
                  ? ""
                  : apiDataItem.itemCd.slice(-50).trim();
                // 品名
                inputListItem.itemName = apiDataItem.itemName;
                // ロット
                inputListItem.lotShow = apiDataItem.lotNo;
                let lot = "";
                if (apiDataItem.lotNo) {
                  lot = apiDataItem.lotNo.trim() ? apiDataItem.lotNo.substring(0, 20).trim() : "-";
                }
                inputListItem.lot = lot;

                // 入荷予定数
                let productQtyNum = apiDataItem.inScheduleQuantity;
                inputListItem.productQty = String(
                  commonUtil.formatToCurrency(Number(productQtyNum))
                );
                // 差
                let difference = apiDataItem.subtraction;
                inputListItem.difference = String(commonUtil.formatToCurrency(Number(difference)));
                inputListItem.oriDifference = String(
                  commonUtil.formatToCurrency(Number(difference))
                );
                // 入荷実績数
                let acheieveQty = apiDataItem.actualQuantity;
                inputListItem.acheieveQty = String(
                  commonUtil.formatToCurrency(Number(acheieveQty))
                );
                inputListItem.oriAcheieveQty = String(
                  commonUtil.formatToCurrency(Number(acheieveQty))
                );

                // 数量（ボール数）
                let ballNum = "0";
                inputListItem.ballSu = String(commonUtil.formatToCurrency(Number(ballNum)));
                inputListItem.oriBallSu = String(commonUtil.formatToCurrency(Number(ballNum)));
                // 商品標準SID
                inputListItem.itemStandardSid = apiDataItem.itemStandardSid;
                // 更新日時
                inputListItem.updateDatetime = apiDataItem.updateDatetime;
                // 品質区分
                inputListItem.qualityDivName = apiDataItem.qualityDivName;
                // 品質区分
                inputListItem.qualityDiv = apiDataItem.qualityDiv;
                // 完了フラグ
                inputListItem.completeFlg = apiDataItem.completeFlg;
                // 前加工品番フラグ
                inputListItem.preProcessingFlg = apiDataItem.preProcessingFlg;
                // inputListに格納
                this.inputList.push(inputListItem);
              }
              resolve(response);
            } else {
              // エラー時
              this.showMessage(jsonData.resCom.resComMessage);
              reject();
            }
          })
          .catch((ex) => {
            this.showMessage(ex);
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).then(() => {
        // 品質区分名設定
        this.arrivalStatusSelected = this.inputList[0].qualityDivName;
      });
    },

    /**
     * 実績登録POSTAPI接続
     */
    deleteStock() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();
      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_111;
      // 修正ﾘｽﾄ抽出
      var bodyList = this.inputList
        .filter((item) => item.ballSu != 0) // 必要な要素だけを抽出
        .map((item) => ({
          // 操作画面名
          opeDispName: i18n.tc("label.lbl_receivingAchieveHacAddpc"),
          // 工程CD（入荷:100）
          procCd: "100",
          // 入出区分（入:01）
          inOutDiv: "01",
          // 日時
          yyyymmddHhmmss: dateTimeHelper.convertUTC(),
          // ロット
          lotNo: item.lotShow,
          // 商品標準SID
          itemStandardSid: item.itemStandardSid,
          // 品番
          itemCd: item.itemCdShow,
          // 管理No
          itemManageNo: controlNumberUtil.createControlNumber(
            sessionStorage.getItem("userInfo.userSid")
          ),
          // 修正数量
          stock: item.ballSu.replaceAll(",", ""),
          // FromSID（入荷元SID）
          fromSid: this.fromSid,
          // ToSID（営業所SID）
          toSid: sessionStorage.getItem("sales_office_sid"),
          // 有償無償区分
          freeCostDiv: this.isPaid,
          // 品質区分
          qualityDiv: item.qualityDiv,
          // 責任区分
          blameDiv: this.isRes,
          // 入荷伝票明細SID
          inListSubSid: item.inListSubSid,
          // 営業所SID
          officeSid: sessionStorage.getItem("sales_office_sid"),
          // 取引先SID
          clientSid: this.suppliersNm,
          // 予定更新日時
          scheduleUpdateDatetime: this.scheduleUpdateDatetime,
          // 明細更新日時
          detailUpdateDatetime: item.updateDatetime,
        }));

      // 実績一覧に追加
      body.reqIdv.actuals = bodyList;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_ACTUALS_HAC, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.showMessage(messsageUtil.getMessage("P-RCV-001_001_C"));
              // 遷移ボタン
              this.infoDialog.homePageFlag = true;
              this.infoDialog.rcvSchHacListFlg = true;
              this.infoDialog.firstPageFlag = false;
              resolve(response);
            } else {
              // エラー時
              this.showMessage(jsonData.resCom.resComMessage);
              reject();
            }
          })
          .catch((ex) => {
            this.showMessage(ex);
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    showMessage(message) {
      this.infoDialog.message = message;
      this.infoDialog.title = appConfig.DIALOG.title;
      this.simpleDialog = true;
      this.infoDialog.firstPageFlag = true;
    },
  },
  watch: {
    isMenu(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}

//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
}

.padding-bottom {
  margin-bottom: 20px;
}

.menu-btn {
  margin: 0.1rem 0.2rem;
  font-weight: bold;
}

::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}

#lbl-user-name {
  font-weight: bold;

  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
}

.table-actualQuantity {
  text-align: right !important;
}

.actual-quantity {
  padding-right: 40px;
}
</style>
