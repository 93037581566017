<template>
  <div>
    <v-tooltip bottom :disabled="!isCarrierCompNameTooltipFlg">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex" v-bind="attrs" v-on="on">
          <v-text-field
            ref="textElement"
            v-model="carrierCompName"
            class="txt-single"
            readonly
            :disabled="disabled"
            :label="$t('label.lbl_shippingCompanyName')"
            :rules="required ? [vRules.inputRequired, ...rules] : [...rules]"
            outlined
            dense
          />
          <span class="require asterisk-spacer" v-if="required">*</span>
          <v-btn color="primary" class="api-btn" @click="isOpenTruck = true" :disabled="disabled">
            {{ $t("btn.btn_addShpAdress") }}
          </v-btn>
        </div>
      </template>
      <span>{{ carrierCompName }} </span>
    </v-tooltip>

    <!-- トラック検索モーダル -->
    <v-dialog v-model="isOpenTruck" :max-width="isInitFlg ? 800 : 0">
      <div style="background: white; overflow-x: hidden">
        <v-container fluid id="sub-bar">
          <v-row>
            <v-col>
              <div id="lbl-screen-name">
                {{ $t("label.lbl_shippingAddress") }}
              </div>
            </v-col>
          </v-row>
        </v-container>
        <!-- データテーブル -->
        <v-form ref="form" lazy-validation>
          <v-container fluid>
            <v-data-table
              id="listData"
              fixed-header
              :headers="headerItems"
              :items="inputList"
              disable-filtering
              height="130px"
              :hide-default-footer="true"
            >
              <!-- 輸送会社名リストボックス -->
              <template v-slot:[`item.routeCompany`]="{}">
                <c-code-input
                  ref="carrierComp"
                  v-model="internalValue.carrierCompCode"
                  :codeType="appConfig.CODETYPE.OUT_CARRIER_COMP_DIV"
                />
              </template>
              <!-- 路線会社名リストボックス -->
              <template v-slot:[`item.shippingcompany`]="{}">
                <v-tooltip bottom :disabled="!isDeliveryTraderTooltipFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex" v-bind="attrs" v-on="on">
                      <v-autocomplete
                        ref="deliveryTraderTextElement"
                        v-model="internalValue.deliveryTraderSid"
                        :items="deliveryTraderList"
                        :hint="
                          commonUtil.findPropertyByValue(
                            deliveryTraderList,
                            internalValue.deliveryTraderSid
                          )
                        "
                        :disabled="
                          internalValue.carrierCompCode == appConfig.CARRIERCOMPDIV.DELIVERY
                            ? false
                            : true
                        "
                        :rules="
                          internalValue.carrierCompCode == appConfig.CARRIERCOMPDIV.DELIVERY
                            ? [vRules.selectRequired, ...rules]
                            : [...rules]
                        "
                        item-value="value"
                        item-text="text"
                        persistent-hint
                        dense
                      >
                        <template slot="item" slot-scope="data">
                          <span class="auto-txt-size"> {{ data.item.text }} </span></template
                        >
                      </v-autocomplete>
                      <span
                        class="require asterisk-spacer"
                        v-if="
                          internalValue.carrierCompCode == appConfig.CARRIERCOMPDIV.DELIVERY
                            ? true
                            : false
                        "
                        >*</span
                      >
                    </div>
                  </template>
                  <span
                    >{{
                      commonUtil.findPropertyByValue(
                        deliveryTraderList,
                        internalValue.deliveryTraderSid,
                        "value",
                        "text"
                      )
                    }}
                  </span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-row col="6">
              <div class="search-row-exeBtn">
                <div class="btn-area">
                  <v-col col="5">
                    <v-btn color="primary" class="api-btn" @click="choiceBtn">
                      {{ $t("label.lbl_choice") }}
                    </v-btn>
                  </v-col>
                </div>
              </div>
            </v-row>
          </v-container>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { i18n } from "@/lang/lang.js";
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { getParameter } from "@/assets/scripts/js/GetParameter";
import CCodeInput from "@/components/CCodeInput";

export default {
  inheritAttrs: false,
  components: { CCodeInput },
  props: {
    // 値
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
    // 取引先SID
    clientSid: {
      required: true,
      validator(value) {
        return typeof value === "string" || value === null;
      },
      default: null,
    },
    // 必須
    required: {
      type: Boolean,
      default: false,
    },
    // 無効化
    disabled: {
      type: Boolean,
      default: false,
    },
    // バリデーション
    rules: {
      type: Array,
      default: () => [],
    },
  },
  inject: ["errorMessage", "loadingSpinner", "commonInputMixin"],
  data: () => ({
    appConfig,
    // 値
    internalValue: {
      // 輸送会社区分
      carrierCompCode: "",
      // 路線業者
      deliveryTraderSid: "",
    },
    // 初期表示フラグ
    isInitFlg: true,
    // 輸送会社名
    carrierCompName: "",
    // 路線業者リスト
    deliveryTraderList: [],
    // 輸送会社選択ダイアログフラグ
    isOpenTruck: false,
    // 輸送会社名ツールチップフラグ
    isCarrierCompNameTooltipFlg: false,
    // 輸送会社名ツールチップフラグ
    isDeliveryTraderTooltipFlg: false,
    // 共通機能
    commonUtil: {
      findPropertyByValue(list, targetValue, keyToCompare = "value", keyToReturn = "name") {
        return list.find((item) => item[keyToCompare] === targetValue)?.[keyToReturn] || "";
      },
    },
    // バリデーション
    vRules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      selectRequired: (value) => !!value || i18n.tc("check.chk_select"),
    },
    // ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_shippingClassification"),
        value: "routeCompany",
        width: "387px",
        align: "center",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_routecompany"),
        value: "shippingcompany",
        width: "387px",
        align: "center",
        sortable: false,
      },
    ],
    inputList: [
      {
        shippingcompany: "",
        routeCompany: "",
      },
    ],
  }),
  methods: {
    // 初期化
    init() {
      // ダイアログフラグ
      this.isOpenTruck = true;
      new Promise((resolve) => setTimeout(resolve, 0)).then(() => {
        // ダイアログフラグ
        this.isOpenTruck = false;
        // 初期表示フラグ
        this.isInitFlg = true;
        // 輸送会社名取得
        this.getCarrierCompName();
      });
    },

    // 路線業者（路線会社リストボックス）
    getDeliveryTrader(params) {
      // ローディング画面表示ON
      this.loadingSpinner.counter++;
      // 路線業者
      const results = getParameter.getDeliveryTradersMst(params);
      Promise.all([results])
        .then((result) => {
          // リスト設定
          this.deliveryTraderList = result[0];

          this.$nextTick(() => {
            setTimeout(() => {
              // 幅を超えたらツールチップを表示
              const textElement =
                this.$refs.deliveryTraderTextElement.$el.querySelector(".v-input__control input");
              this.isDeliveryTraderTooltipFlg = textElement.clientWidth < textElement.scrollWidth;
            }, 0);
          });
        })
        .catch((ex) => {
          if (!this.errorMessage.isError) {
            this.errorMessage.isError = true;
            this.errorMessage.message = ex;
          }
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingSpinner.counter--;
        });
    },

    // 輸送会社名取得
    getCarrierCompName() {
      this.carrierCompName =
        this.internalValue.carrierCompCode == appConfig.CARRIERCOMPDIV.DELIVERY
          ? this.deliveryTraderList.find(
              (item) => item.value == this.internalValue.deliveryTraderSid
            ).text
          : this.$refs.carrierComp?.internalItems.find(
              (item) => item.value == this.internalValue.carrierCompCode
            )?.text ?? "";

      this.$nextTick(() => {
        setTimeout(() => {
          // 幅を超えたらツールチップを表示
          const textElement = this.$refs.textElement.$el.querySelector(".v-input__control input");
          this.isCarrierCompNameTooltipFlg = textElement.clientWidth < textElement.scrollWidth;
        }, 0);
      });
    },

    // 選択ボタン
    choiceBtn() {
      // バリデーション
      if (this.$refs.form.validate()) {
        // 輸送会社名取得
        this.getCarrierCompName();
        this.$emit("input", this.internalValue);
        this.isOpenTruck = false;
      }
    },
  },
  watch: {
    // 取引先SID
    clientSid(newValue) {
      // 初期化
      this.internalValue = {
        // 輸送会社区分
        carrierCompCode: "",
        // 路線業者
        deliveryTraderSid: "",
      };
      this.$emit("input", this.internalValue);

      // 取引先SIDが存在する場合
      if (newValue) {
        // 路線業者取得
        this.getDeliveryTrader({ clientSid: this.clientSid, isMst: "0", searchCategory: "0" });
      }
    },
    // ダイアログ
    isOpenTruck(newValue) {
      // 開く時
      if (newValue == true) {
        // 親データ保存
        this.internalValue = JSON.parse(JSON.stringify(this.value));
        // 輸送会社名取得
        this.getCarrierCompName();
      }
    },
    // 輸送会社区分
    "internalValue.carrierCompCode": function (newValue) {
      // 路線ではない場合、路線業者を初期化
      if (newValue != appConfig.CARRIERCOMPDIV.DELIVERY) {
        this.$set(this.internalValue, "deliveryTraderSid", "");
      }
    },
    "commonInputMixin.shipTo.carrierCompCd": function (newValue) {
      if (newValue) {
        this.internalValue = {
          // 輸送会社区分
          carrierCompCode: this.commonInputMixin.shipTo.carrierCompCd ?? "",
          // 路線業者
          deliveryTraderSid: "",
        };
        // 輸送会社名取得
        this.getCarrierCompName();
        this.$emit("input", this.internalValue);
      }
    },
  },
  mounted() {
    // 初期化
    this.init();
  },
  beforeUpdate() {
    // 路線業者
    this.getDeliveryTrader({ clientSid: this.clientSid, isMst: "0", searchCategory: "0" });
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
// popup画面の為、本画面ではmargin-leftは別設定
//データテーブル
#listData {
  width: 1000px;
  border-collapse: collapse;
  &.v-data-table--fixed-header ::v-deep {
    //テーブル項目部分
    th {
      text-align: center !important;
      font-size: large !important;
      color: black;
      padding: 0.1rem;
      background: #fbe9db;
      height: 1.75rem !important;
      &:nth-child(0) {
        border-bottom: 1px solid $border-color;
      }
      &:not(:nth-child(0)) {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-top: 1px solid $border-color;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
        }
      }
      &:last-child {
        border-right: 1px solid $border-color;
      }
    }
    //テーブルデータ部分
    td {
      height: 3rem !important;
      &:nth-child(1) {
        background: #eef1de;
        font-size: small !important;
        text-align: center !important;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
      &:last-child {
        text-align: center !important;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }
  }
}
</style>
