<template>
  <!-- 非同期API状況ダイアログ -->
  <v-dialog :max-width="800" persistent no-click-animation :value="isShow">
    <Loading :loadingCounter="loadingCounter" />
    <v-card>
      <v-card-title id="sub-bar">
        <span id="lbl-screen-name">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <!-- 状況テーブルデータ -->
          <v-data-table
            id="AsyncApiInfoStatusData"
            fixed-header
            :headers="headerItems"
            :items="internalItems"
            disable-sort
            :hide-default-footer="true"
          >
            <!-- 指示作成状況メッセージ -->
            <template v-slot:[`item.message`]="{ item }">
              <span>
                <!-- 指示作成状況メッセージを表示 -->
                {{ item.message }}
              </span>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-divider v-if="homePageFlag"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!--ボタン領域-->
        <div class="search-row-exeBtn">
          <div class="btn-area">
            <!-- ホーム -->
            <v-btn v-if="homePageFlag" @click="movePage('M_HOM')" style="margin-right: 10px">
              {{ $t("menu.P-MENU-000") }}
            </v-btn>
            <!-- 遷移ボタン -->
            <v-btn v-if="movePageInfo.flag" @click="movePage(movePageInfo.page)">
              {{ movePageInfo.label }}
            </v-btn>
            <!-- 閉じるボタン -->
            <v-btn v-if="closeButtonFlag" @click="close" :disabled="isDisabledCloseBtn">
              {{ $t("btn.btn_close") }}
            </v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { i18n } from "@/lang/lang.js";
import Loading from "@/components/loading";

export default {
  name: "AsyncApiInfoDialog",
  components: { Loading },
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "確認",
      required: false,
    },
    showReturnHome: {
      type: Boolean,
      required: false,
    },
    movePageInfo: {
      type: Object,
      default: null,
      required: false,
    },
    stepFunctionsUrl: {
      type: String,
    },
    body: {
      type: Object,
      default: null,
    },
    finishProcessCallback: {
      type: Function,
      default: null,
    },
  },
  inject: ["errorMessage", "loadingSpinner"],
  data: () => ({
    // 閉じるボタンフラグ
    isDisabledCloseBtn: false,
    internalItems: [],
    homePageFlag: false,
    closeButtonFlag: false,
    loadingCounter: 0,
    // 状況ヘッダー
    headerItems: [
      {
        text: i18n.tc("label.lbl_message"),
        value: "message",
        align: "left",
        width: "15 % ",
      },
    ],
  }),
  methods: {
    movePage(pageNm, param) {
      this.$router.push({ name: appConfig.SCREEN_ID[pageNm], params: param ?? {} });
    },

    stepFunctionsPost(body) {
      this.loadingCounter = 1;
      new Promise((resolve, reject) => {
        this.$httpClient
          .stepFunctionsPostAsync(this.stepFunctionsUrl, body)
          .then((response) => {
            const jsonData = JSON.parse(response);
            // 正常時
            if (jsonData.resCom.resComCode === "000") {
              this.internalItems.push({
                message: i18n.tc("label.lbl_messageFinished"),
              });
              this.handleButtonVisibility();
              this.loadingCounter = 0;
              if (this.finishProcessCallback) {
                this.finishProcessCallback();
              }
            }
            //異常時
            else {
              this.internalItems.push({ message: jsonData.resCom.resComMessage });
              this.handleButtonVisibility();
              this.internalItems.push({
                message: i18n.tc("label.lbl_messageFinished"),
              });
              // ローディング画面表示OFF
              this.loadingCounter = 0;
              if (this.finishProcessCallback) {
                this.finishProcessCallback();
              }
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.internalItems.push({
              message: String(ex),
            });
            this.handleButtonVisibility();
            this.internalItems.push({
              message: i18n.tc("label.lbl_messageFinished"),
            });
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            if (this.finishProcessCallback) {
              this.finishProcessCallback();
            }
            reject(resolve);
          });
      });
    },
    handleButtonVisibility() {
      this.homePageFlag = this.showReturnHome;
      this.movePageInfo.flag = true;
    },
    close() {
      this.isShow = false;
    },
  },
  watch: {
    body(newBody) {
      if (newBody) {
        this.internalItems = [
          {
            message: i18n.tc("label.lbl_messageStart"),
          },
        ];
        this.stepFunctionsPost(newBody);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#AsyncApiInfoStatusData {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }

    //テーブル項目部分
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2rem !important;

      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    //テーブルデータ部分
    td {
      font-size: large;
    }
  }
}
</style>
