<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <!-- 新規登録時：displayFlg==1 -->
      <NavBar v-if="displayFlg == '1'" tittle="label.lbl_affiliationAddRegi" />
      <!-- 修正時：displayFlg == 2 -->
      <NavBar v-if="displayFlg == '2'" tittle="label.lbl_affiliationAddUpDate" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- １行目スタート -->
          <!-- 登録画面-->
          <v-form ref="form_display" lazy-validation>
            <v-row class="search-row">
              <!-- 所属コード -->
              <div class="search-textbox first-search-item" style="float: left">
                <v-text-field
                  dense
                  outlined
                  v-model="affiliationCode"
                  :label="$t('label.lbl_affiliationCode')"
                  persistent-hint
                  clear-icon="mdi-close-circle"
                  clearable
                  maxlength="50"
                  :error-messages="affiliationCodeCheckMsg"
                  :disabled="displayFlg == '2'"
                  :rules="[rules.inputRequired]"
                >
                </v-text-field>
              </div>
              <span class="require asterisk-spacer">*</span>
            </v-row>
          </v-form>

          <v-row>
            <div class="search-row-exeBtn" style="margin: 10px 12px 0 0">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn color="primary" id="get-search" class="other-btn" @click="backPage">{{
                  $t("btn.btn_back")
                }}</v-btn>
              </div>
              <div class="btn-area">
                <!--コピーボタン-->
                <v-btn color="primary" id="btn-search" class="other-btn" @click="makeCopy">{{
                  $t("btn.btn_copy")
                }}</v-btn>
                <!--修正登録ボタン-->
                <v-btn
                  v-if="displayFlg == 2"
                  color="primary"
                  id="btn-search"
                  class="post-btn"
                  @click="upsert"
                  >{{ $t("btn.btn_fixinsert") }}</v-btn
                >
                <!--登録ボタン-->
                <v-btn
                  v-if="displayFlg == 1"
                  color="primary"
                  id="btn-search"
                  class="post-btn"
                  @click="upsert"
                  >{{ $t("btn.btn_insert") }}</v-btn
                >
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            max-height="530px"
          >
            <!-- チェックボックス -->
            <template v-slot:[`item.check`]="{ item }">
              <input type="checkbox" v-model="item.check" style="transform: scale(2)" />
            </template>
            <!-- 所属名 -->
            <template v-slot:[`item.affiliationName`]="{ item }">
              <v-text-field
                dense
                outlined
                v-model="item.affiliationName"
                maxlength="100"
                :error-messages="item.affiliationNameCheckMsg"
                clear-icon="mdi-close-circle"
                clearable
                class="adjust-position"
              />
            </template>
            <!-- 所属名カナ -->
            <template v-slot:[`item.affiliationNameKana`]="{ item }">
              <v-text-field
                dense
                outlined
                v-model="item.affiliationNameKana"
                maxlength="100"
                :error-messages="item.affiliationNameKanaCheckMsg"
                clear-icon="mdi-close-circle"
                clearable
                class="adjust-position"
              />
            </template>
            <!-- 所属名略称 -->
            <template v-slot:[`item.affiliationNameShort`]="{ item }">
              <v-text-field
                dense
                outlined
                v-model="item.affiliationNameShort"
                maxlength="20"
                :error-messages="item.affiliationNameShortCheckMsg"
                clear-icon="mdi-close-circle"
                clearable
                class="adjust-position"
              />
            </template>
            <!-- 電話番号 -->
            <template v-slot:[`item.telNo`]="{ item }">
              <v-text-field
                dense
                outlined
                v-model="item.telNo"
                maxlength="20"
                :error-messages="item.telNoCheckMsg"
                clear-icon="mdi-close-circle"
                clearable
                class="adjust-position"
              />
            </template>
            <!-- 所属区分 -->
            <template v-slot:[`item.department`]="{ item }">
              <v-autocomplete
                v-model="item.department"
                :items="departmentPullList"
                :error-messages="item.departmentCheckMsg"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </template>
            <!-- 有効開始日時 -->
            <template v-slot:[`item.validStartDate`]="{ item, index }">
              <div class="calendar table-date-style">
                <v-text-field
                  outlined
                  dense
                  v-model="item.validStartDate"
                  class="txt-single table-date-style text-box"
                  @change="changeDateFrom(item.validStartDate, index)"
                  :rules="[rules.yyyymmdd]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :error-messages="item.dateStartCheckMsg"
                  :ref="'validStartDate' + index"
                  maxlength="10"
                ></v-text-field>
                <div class="calender-btn">
                  <v-menu
                    v-model="item.dateMenuFrom"
                    :close-on-content-click="false"
                    :nudge-right="-100"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="date-calendar"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" text x-small>
                        <v-icon>mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                    <v-date-picker
                      v-model="item.validStartDateCal"
                      @input="inputCallenderFrom(item.validStartDateCal, index)"
                    />
                  </v-menu>
                </div>
              </div>
            </template>
            <!-- 有効終了日時 -->
            <template v-slot:[`item.validEndDate`]="{ item, index }">
              <div class="calendar table-date-style">
                <v-text-field
                  outlined
                  dense
                  v-model="item.validEndDate"
                  class="txt-single table-date-style text-box"
                  @change="changeDateTo(item.validEndDate, index)"
                  :rules="[rules.yyyymmdd]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :error-messages="item.dateEndCheckMsg"
                  :ref="'validEndDate' + index"
                  maxlength="10"
                ></v-text-field>
                <div class="calender-btn">
                  <v-menu
                    v-model="item.dateMenuTo"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="date-calendar"
                    :nudge-right="-150"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" text x-small>
                        <v-icon>mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                    <v-date-picker
                      v-model="item.validEndDateCal"
                      @input="inputCallenderTo(item.validEndDateCal, index)"
                    />
                  </v-menu>
                </div>
              </div>
            </template>
            <!-- 削除-->
            <template v-slot:[`item.delete`]="{ item }">
              <v-btn small @click="deleteItem(item)" text>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-form>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- 設定ボタン押下後エラー時出現エラー -->
      <v-dialog v-model="copyDialogMsg" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ alertMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okClicked"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :title="ConfirmDialog.title"
        :okAction="postApi"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <!-- 登録成功時ダイアログ -->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :belongMstFlg="infoDialog.belongMstFlg"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import ConfirmDialog from "../../components/ConfirmDialog";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_MST_019,
  components: {
    ConfirmDialog,
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    belongSid: "",
    affiliationCode: "",
    validFrom: "", //有効開始日時（標準）
    validTo: "", //有効終了日時（標準）
    // 配列のインデックス
    index: 0,
    editedIndex: "",
    // エラーメッセージ
    affiliationCodeCheckMsg: "",
    affiliationNameCheckMsg: "",
    affiliationNameKanaCheckMsg: "",
    affiliationNameShortCheckMsg: "",
    telNoCheckMsg: "",
    departmentCheckMsg: "",
    dateStartCheckMsg: "",
    dateEndCheckMsg: "",
    // 所属区分プルダウン
    departmentPullList: [],
    // メニュー
    openMenu: null,
    // 表示切替(1:登録画面 2:修正・削除画面)
    displayFlg: "",
    // 一覧データ
    inputList: [],
    // 修正前データ
    initList: [],
    // 削除・修正用リスト
    deleteList: [],
    // カレンダーメニュー表示フラグ
    dateMenuTo: false,
    dateMenuFrom: false,
    // 有効開始日と有効終了日
    validEndDate: "",
    validStartDate: "",
    validEndDateCal: "",
    editedList: [],
    // コピー時生成するデータ
    defaultItem: {
      check: "",
      affiliationName: "",
      affiliationNameKana: "",
      affiliationNameShort: "",
      telNo: "",
      department: "",
      validStartDate: "",
      validEndDate: "",
    },
    // テーブルヘッダー
    headerItems: [
      // 選択
      {
        text: "",
        value: "check",
        align: "center",
        width: "69px",
      },
      // 所属名
      {
        text: i18n.tc("label.lbl_affiliationName"),
        value: "affiliationName",
        width: "15%",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 所属名カナ
      {
        text: i18n.tc("label.lbl_affiliationNameKana"),
        value: "affiliationNameKana",
        width: "15%",
        align: "left",
        sortable: true,
      },
      // 所属名略称
      {
        text: i18n.tc("label.lbl_affiliationNameAbbreviation"),
        value: "affiliationNameShort",
        width: "15%",
        align: "left",
        sortable: true,
        class: "asta",
      },
      // 電話番号
      {
        text: i18n.tc("label.lbl_telephone"),
        value: "telNo",
        align: "left",
        width: "15%",
      },
      // 所属区分
      {
        text: i18n.tc("label.lbl_department"),
        value: "department",
        width: "145px",
        align: "left",
        class: "asta",
      },
      // 有効開始日時
      {
        text: i18n.tc("label.lbl_validStartDateAndTime"),
        value: "validStartDate",
        width: "200px",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 有効開始終了日時
      {
        text: i18n.tc("label.lbl_validEndDateAndTime"),
        value: "validEndDate",
        width: "224px",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 削除
      {
        text: i18n.tc("btn.btn_delete"),
        value: "delete",
        align: "center",
        width: "90px",
      },
    ],
    // コピー時アラートダイアログ
    copyDialogMsg: false,
    alertMessage: "",
    warningCheckFlg: "0",
    // 更新前所属区分
    beforeDepartmentList: [],
  }),
  methods: {
    init() {
      // 新規登録か修正・削除か判断
      this.displayFlg = this.$route.params.displayFlg ? this.$route.params.displayFlg : "1";
      // 検索条件を保存
      this.previousSearchFilter = this.$route.params;
      if (this.displayFlg === "1") {
        // 標準有効開始日（裏で持っている値）
        this.validFrom = dateTimeHelper.convertJST();
        // 標準有効終了日（裏で持っている値）
        this.validTo = dateTimeHelper.convertUTC2JST("2099/12/31 14:59:59.999");
      } else if (this.displayFlg === "2") {
        this.belongSid = this.$route.params.belongSid;
      }
      this.getInitData();
    },

    getInitData() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const departmentList = getParameter.getCodeMst(appConfig.CODETYPE.DEPARTMENT_TYPE_DIV);
      let itemDetails;
      if (this.displayFlg === "2") {
        itemDetails = this.getAffiliationDetails();
      }
      Promise.all([departmentList, itemDetails])
        .then((result) => {
          this.departmentPullList = result[0];
          if (this.displayFlg === "1") {
            this.inputList.push({
              validStartDate: dateTimeHelper.convertJST().substr(0, 10),
              validEndDate: dateTimeHelper.convertUTC2JST("2099/12/31 14:59:59.999").substr(0, 10),
              validStartDateCal: dateTimeHelper.dateCalc(
                dateTimeHelper.convertUTC2JST(dateTimeHelper.convertJST().substr(0, 10))
              ),
              validEndDateCal: dateTimeHelper.dateCalc(
                dateTimeHelper.convertUTC2JST("2099/12/31 14:59:59.999").substr(0, 10)
              ),
              affiliationName: "",
              affiliationNameKana: "",
              affiliationNameShort: "",
              telNo: "",
              department: "",
            });
          } else {
            this.inputList = result[1];
            this.initList = JSON.parse(JSON.stringify(result[1]));
          }
        })
        .catch((ex) => {
          console.log(ex);
          this.showDialog(ex, "result");
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    okClicked() {
      this.copyDialogMsg = false;
    },
    // コピーボタン押下時処理
    makeCopy() {
      // ２行以上はコピーできないように制御するためチェックした行を確認
      var checkCnt = [];
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].check) {
          checkCnt.push(this.inputList[i]);
        }
      }

      if (checkCnt.length == 0) {
        // コピー対象未選択
        this.copyDialogMsg = true;
        this.alertMessage = messsageUtil.getMessage("P-MST-006_002_E");
      } else if (checkCnt.length <= 1) {
        // 選択された行が２未満ならデータを複製する
        for (var m = 0; m < this.inputList.length; m++) {
          if (this.inputList[m].check) {
            this.defaultItem = {
              validStartDate: this.inputList[m].validStartDate,
              validEndDate: this.inputList[m].validEndDate,
              validStartDateCal: this.inputList[m].validStartDateCal,
              validEndDateCal: this.inputList[m].validEndDateCal,
              affiliationName: this.inputList[m].affiliationName,
              affiliationNameKana: this.inputList[m].affiliationNameKana,
              affiliationNameShort: this.inputList[m].affiliationNameShort,
              telNo: this.inputList[m].telNo,
              department: this.inputList[m].department,
            };
            this.inputList.push(Object.assign({}, this.defaultItem));
            // チェック外す
            this.inputList[m].check = false;
          }
        }
      } else {
        // チェックが２行以上の場合は、アラートダイアログ
        this.copyDialogMsg = true;
        this.alertMessage = messsageUtil.getMessage("P-MST-001_001_E");
      }
    },
    // 登録ボタン押下時確認ダイアログ
    upsert() {
      if (this.displayFlg === "1" || this.checkObjectsIdentical().length !== 0) {
        this.loadingCounter = 1;
        // 画面ヘッダー部の入力チェックOKだった場合
        if (this.$refs.form.validate()) {
          //エラー内容初期化処理--------------------------------------------------
          for (var i = 0; i < this.inputList.length; i++) {
            this.inputList[i].affiliationCodeCheckMsg = i18n.tc("");
            this.inputList[i].affiliationNameCheckMsg = i18n.tc("");
            this.inputList[i].affiliationNameKanaCheckMsg = i18n.tc("");
            this.inputList[i].affiliationNameShortCheckMsg = i18n.tc("");
            this.inputList[i].telNoCheckMsg = i18n.tc("");
            this.inputList[i].departmentCheckMsg = i18n.tc("");
            this.inputList[i].dateEndCheckMsg = i18n.tc("");
            this.inputList[i].dateStartCheckMsg = i18n.tc("");
          }
          this.loadingCounter = 0;
          this.warningCheckFlg = "0";
          //エラー内容初期化処理--------------------------------------------------
          // エラーの数
          let count = 0;

          //明細部の入力チェックOKだった場合
          if (this.$refs.editedList.validate()) {
            if (!this.affiliationCode) {
              this.affiliationCodeCheckMsg = i18n.tc("check.chk_input");
              count++;
            }
            // 詳細画面入力チェック実施
            for (var a = 0; a < this.inputList.length; a++) {
              for (var d = 0; d < this.inputList.length; d++) {
                if (!this.inputList[d].affiliationName) {
                  this.inputList[d].affiliationNameCheckMsg = i18n.tc("check.chk_input");
                  count++;
                }
                if (!this.inputList[d].affiliationNameShort) {
                  this.inputList[d].affiliationNameShortCheckMsg = i18n.tc("check.chk_input");
                  count++;
                }
                if (!this.inputList[d].validStartDate) {
                  this.inputList[d].dateStartCheckMsg = i18n.tc("check.chk_input");
                  count++;
                }
                if (!this.inputList[d].validEndDate) {
                  this.inputList[d].dateEndCheckMsg = i18n.tc("check.chk_input");
                  count++;
                }
                if (!this.inputList[d].affiliationName) {
                  this.inputList[d].affiliationNameCheckMsg = i18n.tc("check.chk_input");
                  count++;
                }
                if (!this.inputList[d].affiliationName) {
                  this.inputList[d].affiliationNameCheckMsg = i18n.tc("check.chk_input");
                  count++;
                }
                if (!this.inputList[d].department) {
                  this.inputList[d].departmentCheckMsg = i18n.tc("check.chk_input");
                  count++;
                }
                if (this.inputList[d].department === "02" && !this.inputList[d].telNo) {
                  this.inputList[d].telNoCheckMsg = i18n.tc("check.chk_input");
                  count++;
                }
              }
            }
            if (this.checkDuplication()) {
              count++;
            }

            const today = new Date();
            today.setHours(0, 0, 0, 0);
            // 所属区分に関するエラーハンドリング
            for (let i = 0; i < this.inputList.length; i++) {
              if (
                this.displayFlg === "2" &&
                this.inputList[i].department === "02" &&
                this.beforeDepartmentList[i] === "01"
              ) {
                // 所属区分変更時の有効期間チェック
                if (new Date(this.inputList[i].validEndDate) < new Date(today)) {
                  count++;
                  this.showDialog(messsageUtil.getMessage("P-MST-019_003_E"), "confirm");
                  break;
                }
              }

              if (this.inputList[i].department === "01") {
                // 管理部門 -> 一般部門 変更時エラー
                if (this.displayFlg === "2" && this.beforeDepartmentList[i] === "02") {
                  count++;
                  this.showDialog(messsageUtil.getMessage("P-MST-019_001_E"), "confirm");
                  break;
                }
              } else if (this.inputList[i].department === "02") {
                if (new Date(this.inputList[i].validStartDate) > new Date(today)) {
                  // 管理部門の有効期間が未来の場合エラー
                  count++;
                  this.showDialog(messsageUtil.getMessage("P-MST-019_004_E"), "confirm");
                  break;
                } else if (this.inputList[i].validEndDate !== "2099/12/31") {
                  // 管理部門の有効終了日時が"2099/12/31"以外だとエラー
                  count++;
                  this.showDialog(messsageUtil.getMessage("P-MST-019_005_E"), "confirm");
                  break;
                }
              }
            }

            // 数値チェック、期間重複チェック、所属区分チェック問題なしなら確認ダイアログ
            if (count === 0) {
              //新規かつ管理部門の際、または一般部門から管理部門への変更の際は警告表示
              for (let i = 0; i < this.inputList.length; i++) {
                if (this.displayFlg === "1" && this.inputList[0].department === "02") {
                  this.ConfirmDialog.message = messsageUtil.getMessage("P-MST-019_002_C");
                  break;
                } else if (
                  this.beforeDepartmentList[i] === "01" &&
                  this.inputList[i].department === "02"
                ) {
                  this.ConfirmDialog.message = messsageUtil.getMessage("P-MST-019_002_C");
                  break;
                } else {
                  this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
                }
              }
              this.ConfirmDialog.title = appConfig.DIALOG.confirm;
              this.ConfirmDialog.isOpen = true;
              this.ConfirmDialog.screenFlag = true;
              this.ConfirmDialog.changeFlag = false;
            }
          }
        }
      }
    },
    // 確認ダイアログOK押下時
    postApi() {
      if (this.$refs.form.validate()) {
        // errorメッセージ解除
        this.dateEndCheckMsg = "";
        this.dateStartCheckMsg = "";
        this.affiliationCodeCheckMsg = "";
        this.affiliationNameCheckMsg = "";
        this.affiliationNameKanaCheckMsg = "";
        this.affiliationNameShortCheckMsg = "";
        this.telNoCheckMsg = "";
        this.departmentCheckMsg = "";
        // ローディング画面表示ON
        this.loadingCounter = 1;

        const checkedList = this.displayFlg === "1" ? this.inputList : this.checkObjectsIdentical();
        const belongList = [];
        const body = this.$httpClient.createRequestBodyConfig();
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_019;
        if (this.belongSid) {
          body.reqIdv.belongSid = this.belongSid;
        }
        body.reqIdv.affiliationCode = this.affiliationCode.trim();

        checkedList.forEach((row) => {
          belongList.push({
            belongSid: this.belongSid,
            officialName: row.affiliationName,
            officialNameKana: row.affiliationNameKana,
            officialNameShort: row.affiliationNameShort,
            telNo: row.telNo,
            belongDiv: row.department,
            validFrom: dateTimeHelper.convertUTC(row.validStartDate),
            validTo: dateTimeHelper.convertUTC(row.validEndDate + " 23:59:59.999"),
          });
        });
        body.reqIdv.belongList = belongList;

        if (this.displayFlg === "2") {
          const deletableValidFromList = [];
          this.deleteList.forEach((row) => {
            deletableValidFromList.push(dateTimeHelper.convertUTC(row.beforeValidFrom));
          });
          this.inputList.forEach((row) => {
            if (row.validFrom !== row.beforeValidFrom) {
              deletableValidFromList.push(dateTimeHelper.convertUTC(row.beforeValidFrom));
            }
          });
          body.reqIdv.deletableValidFromList = deletableValidFromList;
        }

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.MST_BELONG_UPDATE, body, appConfig.APP_CONFIG)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode === "000") {
                this.showDialog(messsageUtil.getMessage("P-RCV-001_001_C"), "result");

                this.infoDialog.belongMstFlg = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                this.infoDialog.firstPageFlag = false;
                resolve(response);
                // エラー時
              }
              //janコードが別で使用されている場合
              else if (jsonData.resCom.resComCode === "004") {
                this.warningCheckFlg = "1";
                this.ConfirmDialog.message = jsonData.resCom.resComMessage;
                this.ConfirmDialog.isOpen = true;
                this.ConfirmDialog.title = "警告";
                this.ConfirmDialog.screenFlag = true;
                this.ConfirmDialog.changeFlag = false;
                reject();
              } else {
                this.showDialog(jsonData.resCom.resComMessage, "result");
                reject();
              }
            })
            .catch((ex) => {
              this.showDialog(ex, "result");
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    /**
     * 所属マスタ取得
     */
    getAffiliationDetails() {
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.belongSid = this.belongSid;
      config.params.searchKbn = "1";

      var list = [];
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_BELONG_LIST, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 成功
            if (jsonData.resCom.resComCode == "000") {
              jsonData.resIdv.belongList.forEach((row) => {
                this.affiliationCode = row.affiliationCode;
                this.beforeDepartmentList.push(row.belongDiv);
                const validFrom = dateTimeHelper.convertUTC2JST(row.validFrom).substr(0, 10);
                const validto = dateTimeHelper.convertUTC2JST(row.validTo).substr(0, 10);
                list.push({
                  affiliationName: row.officialName,
                  affiliationNameKana: row.officialNameKana,
                  affiliationNameShort: row.officialNameShort,
                  telNo: row.telNo,
                  department: row.belongDiv,
                  validStartDate: validFrom,
                  validEndDate: validto,
                  validStartDateCal: dateTimeHelper.dateCalc(validFrom),
                  validEndDateCal: dateTimeHelper.dateCalc(validto),
                  beforeValidFrom: validFrom,
                });
              });
              resolve(list);
            } else {
              // エラー時
              this.showDialog(jsonData.resCom.resComMessage, "result");
            }
          })
          .then(() => {
            // カレンダーとテキストボックスを連動させる処理
            for (var t = 0; t < this.inputList.length; t++) {
              this.inputList[t].validStartDateCal = dateTimeHelper.dateCalc(
                dateTimeHelper.convertUTC2JST(this.inputList[t].validStartDate)
              );
              this.inputList[t].validEndDateCal = dateTimeHelper.dateCalc(
                dateTimeHelper.convertUTC2JST(this.inputList[t].validEndDate)
              );
            }
          })
          .catch((ex) => {
            console.error("getAffiliationDetails() err", ex);
            this.showDialog(ex, "result");
            reject();
          });
      });
    },
    // 戻るボタン押下：ダイアログ表示
    backPage() {
      var searchFilter = this.previousSearchFilter;
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_020,
        params: {
          searchFilter,
        },
      });
    },

    /**
     * 行削除処理
     */
    deleteItem(item) {
      //一覧ボディが1行のみの場合
      if (this.inputList.length === 1) {
        this.showDialog(messsageUtil.getMessage("P-MST-006_003_E"), "result");
        return;
      }
      this.editedIndex = this.inputList.indexOf(item);
      var deleteIndex = this.deleteList.length;
      // 削除したリストを退避する（既存データを削除したときのみ
      if (this.inputList[this.editedIndex].beforeValidFrom) {
        this.deleteList[deleteIndex] = this.inputList[this.editedIndex];
      }
      // 画面上からは明細削除
      this.inputList.splice(this.editedIndex, 1);
    },
    /**
     * 日付入力されたら修正
     */
    changeDateFrom(val, index) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length === 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }

      if (dateTimeHelper.validDate(strDt, "/")) {
        // 選択した行の有効開始日も更新
        this.inputList[index].validStartDate = strDt;
        this.inputList[index].validStartDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      }
    },
    changeDateTo(val, index) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        // 選択した行の有効終了日も更新
        this.inputList[index].validEndDate = strDt;
        this.inputList[index].validEndDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      }
    },
    /**
     * 日付テキストボックスとカレンダーの連動処理
     * カレンダーで選択した日付がテキストボックスに反映される
     */
    inputCallenderFrom(validStartDateCal, index) {
      this.inputList[index].validStartDate = this.formatDate(validStartDateCal);
      this.inputList[index].dateMenuFrom = false;
    },
    inputCallenderTo(validEndDateCal, index) {
      this.inputList[index].validEndDate = this.formatDate(validEndDateCal);
      this.inputList[index].dateMenuTo = false;
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    checkObjectsIdentical() {
      const list = [];
      for (let initItem of this.initList) {
        for (let inputItem of this.inputList) {
          if (JSON.stringify(initItem) !== JSON.stringify(inputItem)) {
            list.push(inputItem);
          }
        }
      }
      return list;
    },

    checkDuplication() {
      var errFlg = false;
      for (let i = 0; i < this.inputList.length; i++) {
        var start1 = new Date(this.inputList[i].validStartDate);
        var end1 = new Date(this.inputList[i].validEndDate);
        if (start1 > end1) {
          this.inputList[i].dateEndCheckMsg = i18n.tc("check.chk_inputValiFrom");
          errFlg = true;
        } else {
          for (let j = i + 1; j < this.inputList.length; j++) {
            var start2 = new Date(this.inputList[j].validStartDate);
            var end2 = new Date(this.inputList[j].validEndDate);
            if (start1 <= end2 && start2 <= end1) {
              this.inputList[j].dateStartCheckMsg = i18n.tc("check.chk_duplicate");
              this.inputList[i].dateEndCheckMsg = i18n.tc("check.chk_duplicate");
              errFlg = true;
            }
          }
        }
      }
      return errFlg;
    },
    // エラーダイアログ表示処理
    showDialog(message, titleKey) {
      this.infoDialog.message = message;
      if (titleKey === "confirm") {
        this.infoDialog.title = appConfig.DIALOG.confirm;
      } else if (titleKey === "result") {
        this.infoDialog.title = appConfig.DIALOG.title;
      }
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = true;
      this.infoDialog.firstPageFlag = true;
    },
  },
  computed: {},
  watch: {
    isMenu(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    validStartDateCal(val) {
      this.validStartDate = this.formatDate(val);
    },
    validEndDateCal(val) {
      this.validEndDate = this.formatDate(val);
    },
  },
  created() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.adjust-position {
  margin-top: 11px;
}

.calender-btn {
  margin-top: auto;
  margin-bottom: auto;
}

.search-textbox-remark {
  width: 41.5rem;
  margin-left: 15px;
}

.calendar {
  display: flex;
}

#listData.v-data-table ::v-deep .v-text-field__details {
  margin-bottom: 0px;
}

::v-deep .categories a::before {
  content: "\f01c";
  font-family: blogicon;
}
</style>
