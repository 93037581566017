<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-col>
        <div id="lbl-screen-name">{{ $t("label.lbl_exitingHistorySearch") }}</div>
      </v-col>
    </v-container>
    <v-container fluid>
      <v-row class="search-row">
        <!-- 入出荷日(From)-->
        <div class="date-style first-search-item">
          <v-text-field
            outlined
            dense
            v-model="inScheduleDateFrom"
            :label="$t('label.lbl_inOutScheduleDate') + '（From）'"
            @change="changeDate($event, 1)"
            :rules="[
              rules.inputRequiredCreate(inScheduleDateFrom, inScheduleDateTo),
              rules.yyyymmdd_create,
            ]"
            class="txt-single"
            clear-icon="mdi-close-circle"
            clearable
          ></v-text-field>
        </div>

        <div class="calender-btn-area">
          <v-menu
            v-model="inScheduleDateFromMenu"
            :close-on-content-click="false"
            :nudge-right="-100"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="date-calendar"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text x-small>
                <v-icon class="icon-calendar">mdi-calendar</v-icon>
              </v-btn>
            </template>
            <v-date-picker
              v-model="inScheduleDateFromCal"
              @input="inScheduleDateFromMenu = false"
            ></v-date-picker>
          </v-menu>
          <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate(1)" text x-small>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate(1)" text x-small>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>

        <!-- 空白 -->
        <span class="item-spacer">&nbsp;</span>
        <div class="from_to">～</div>
        <!-- 空白 -->
        <span class="item-spacer">&nbsp;</span>

        <!-- 入出荷日(To) -->
        <div class="date-style">
          <v-text-field
            outlined
            dense
            v-model="inScheduleDateTo"
            :label="$t('label.lbl_inOutScheduleDate') + '（To）'"
            @change="changeDate($event, 2)"
            :rules="[
              rules.inputRequiredCreate(inScheduleDateTo, inScheduleDateTo),
              rules.yyyymmdd_create,
            ]"
            class="txt-single"
            clear-icon="mdi-close-circle"
            clearable
          ></v-text-field>
        </div>

        <div class="calender-btn-area">
          <v-menu
            v-model="inScheduleDateToMenu"
            :close-on-content-click="false"
            :nudge-right="-100"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="date-calendar"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text x-small>
                <v-icon class="icon-calendar">mdi-calendar</v-icon>
              </v-btn>
            </template>
            <v-date-picker
              v-model="inScheduleDateToCal"
              @input="inScheduleDateToMenu = false"
            ></v-date-picker>
          </v-menu>
          <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate(2)" text x-small>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate(2)" text x-small>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-row>
      <v-row class="search-row">
        <!-- 品番/品名-->
        <div class="search-autocomplete first-search-item">
          <v-autocomplete
            dense
            id="get-productCode"
            v-model="itemStandardSid"
            :items="productCodeList"
            @change="(event) => changeProductNm(event)"
            :hint="setProductCode()"
            :search-input.sync="search"
            :label="$t('label.lbl_productCnCd')"
            persistent-hint
            outlined
          >
            <!-- アイテム一覧の表示 -->
            <!-- 伝票No -->
            <template v-slot:[`item.billingSlipNo`]="{ item }">
              <div class="listNo-style">{{ item.billingSlipNo }}</div>
            </template>
            <template slot="item" slot-scope="data">
              <span class="auto-txt-size">
                {{ data.item.text }}
              </span>
            </template>
            <!-- * -->
          </v-autocomplete>
        </div>

        <!-- 空白 -->
        <span class="item-spacer">&nbsp;</span>

        <!-- 部品補足情報-->
        <div class="search-autocomplete">
          <v-text-field
            dense
            v-model="partsSubInformation"
            maxlength="200"
            :label="$t('label.lbl_partsSubInformation')"
            outlined
            :rules="[rules.limitNumLength200]"
          />
        </div>

        <!-- 空白 -->
        <span class="item-spacer">&nbsp;</span>

        <div class="search-autocomplete">
          <v-text-field
            dense
            :label="$t('label.lbl_group')"
            v-model="groupName"
            outlined
            maxlength="200"
            :rules="[rules.limitNumLength200]"
          />
        </div>

        <!-- 空白 -->
        <span class="item-spacer">&nbsp;</span>

        <!-- 所属 -->
        <div class="search-autocomplete">
          <c-belong-input outlined v-model="belongSid" @input="handleBelongSidChange()" />
        </div>

        <!-- 空白 -->
        <span class="item-spacer">&nbsp;</span>

        <!-- 部品要求者 -->
        <div class="search-autocomplete">
          <c-user-input
            outlined
            :label="$t('label.lbl_partsRequester')"
            v-model="partsRequester"
            :belongSid="belongSid"
          />
        </div>

        <!-- 空白 -->
        <span class="item-spacer">&nbsp;</span>

        <!-- 管理番号 -->
        <div class="search-autocomplete" style="width: 250px">
          <v-text-field
            v-model="itemManageNo"
            :label="$t('label.lbl_managementNoJP')"
            class="txt-single"
            clear-icon="mdi-close-circle"
            clearable
            outlined
            dense
            maxlength="20"
            :rules="[rules.limitLength20]"
          />
        </div>

        <!-- 検索ボタン-->
        <div id="btn_search">
          <v-btn color="primary" class="api-btn" @click="clickSearchBtn()">
            {{ $t("btn.btn_search") }}
          </v-btn>
        </div>
      </v-row>
    </v-container>
    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        :headers="headerItems"
        :items="inputList"
        :fixed-header="true"
        disable-sort
        disable-pagination
        :hide-default-footer="true"
        height="500px"
      >
        <!-- 商品コード／品名改行&ツールチップ -->
        <template v-slot:[`item.productName`]="{ item }">
          <!-- 商品名が15文字以上だった場合ツールチップで表示 -->
          <div v-if="item.productName && item.productName.length > 10">
            <div v-if="item.incidental">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" style="max-width: 400px" v-bind="attrs" v-on="on">
                    {{ item.itemCd }}<br />
                    {{ item.incidental }}<br />
                    {{ item.productName }}
                  </div>
                </template>
                <div>{{ item.itemCd }}</div>
                <div>{{ item.incidental }}</div>
                <div>{{ item.productName }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" style="max-width: 400px" v-bind="attrs" v-on="on">
                    {{ item.itemCd }}<br />
                    {{ item.productName }}
                  </div>
                </template>
                <div>{{ item.itemCd }}</div>
                <div>{{ item.productName }}</div>
              </v-tooltip>
            </div>
          </div>
          <!-- 商品名が15文字以下だった場合ツールチップ無しで表示 -->
          <div v-else>
            <div>{{ item.itemCd }}</div>
            <div>{{ item.incidental }}</div>
            <div>{{ item.productName }}</div>
          </div>
        </template>

        <!-- 選択ボタン -->
        <template v-slot:[`item.choice`]="{ item }">
          <v-btn class="post-btn" @click="choiceExitingHistory(item)">
            {{ $t("btn.btn_select") }}
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
    <!-- 閉じるボタン -->
    <v-card-actions>
      <div class="search-row-exeBtn">
        <div id="btn_close" align="right">
          <v-btn @click="close()" class="other-btn">
            <div id="lbl-screen-name">{{ $t("btn.btn_close") }}</div>
          </v-btn>
        </div>
      </div>
    </v-card-actions>
  </div>
</template>

<script>
import { i18n } from "../../lang/lang.js";
import Loading from "@/components/loading";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import commonRules from "@/mixins/CommonRules";
import commonMixin from "@/mixins/CommonMixin";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";

export default {
  name: "exitingHistorySearch",
  components: {
    Loading,
  },
  props: {
    entity: { type: Object, default: null },
    detailDialogData: {},
    hoge1: {
      default: "",
    },
    isOpenDetailDialog: { type: Boolean, default: false },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 取引先
    suppliersSelected: "",
    productCodeList: [],
    itemStandardSid: null,
    search: null,
    partsSubInformation: null,
    groupName: null,
    belongSid: null,
    partsRequester: null,
    itemManageNo: null,
    // 入出荷日From
    inScheduleDateFrom: null,
    inScheduleDateFromMenu: false,
    inScheduleDateFromCal: null,
    // 入出荷日To
    inScheduleDateTo: null,
    inScheduleDateToMenu: false,
    inScheduleDateToCal: null,
    headerItems: [
      // No
      {
        text: "No",
        class: "listHeader",
        value: "No",
        width: "5%",
        sortable: false,
        align: "center",
      },
      // 品番
      {
        text: i18n.tc("label.lbl_productCnCd"),
        width: "15%",
        value: "productName",
        sortable: false,
        align: "center",
      },
      // 部品補足情報
      {
        text: i18n.tc("label.lbl_partsSubInformation"),
        value: "partsSubInformation",
        width: "20%",
        align: "center",
        sortable: false,
      },
      // 部品要求者
      {
        text: i18n.tc("label.lbl_partsRequester"),
        value: "partsRequesterName",
        width: "15%",
        align: "center",
        sortable: false,
      },
      // 選択
      {
        text: i18n.tc("label.lbl_choice"),
        value: "choice",
        width: "10%",
        align: "center",
        sortable: false,
      },
    ],
    inputList: [],
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    rules: {
      limitNumLength200: (value) =>
        value == null || value.length <= 200 || i18n.tc("check.chk_limitLength_1-200"),
      limitLength20: (value) =>
        value == null || value.length <= 20 || i18n.tc("check.chk_inputMorefifty20"), // 文字数の制約
    },
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.inputList = [];
      this.itemStandardSid = null;
      this.partsSubInformation = null;
      this.groupName = null;
      this.belongSid = null;
      this.partsRequester = null;
      this.itemManageNo = null;
      this.suppliersSelected = this.entity.suppliersSelected;

      this.inScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
      this.inScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.inScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
      this.inScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
    },

    //ダイアログを閉じる
    close() {
      this.$emit("closePopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputList = [];
    },
    /**
     * プルダウンチェンジイベント
     */
    changeProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.productCodeList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.search = val.text;
      }
      this.productCodeList = this.productCodeList.filter((v) => v.value == value);
    },
    // 品番
    setProductCode() {
      for (var i = 0; i < this.productCodeList.length; i++) {
        if (this.productCodeList[i].value == this.itemStandardSid) {
          return this.productCodeList[i].name;
        }
      }
    },
    /**
     * 検索ボタン
     */
    clickSearchBtn() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.clientSid = this.suppliersSelected;
      // 所属
      if (this.belongSid) {
        config.params.belongSid = this.belongSid;
      }
      //  商品標準SID
      if (this.itemStandardSid) {
        config.params.itemStandardSid = this.itemStandardSid;
      }
      //入出荷日From
      config.params.inOutScheduleDateFrom = dateTimeHelper.convertUTC(this.inScheduleDateFrom);
      //入出荷日To
      config.params.inOutScheduleDateTo = dateTimeHelper.convertUTC(
        this.inScheduleDateTo + " 23:59:59.999"
      );
      // 部品要求者
      if (this.partsRequester) {
        config.params.partsRequesterSid = this.partsRequester;
      }
      // 部品補足情報
      if (this.partsSubInformation) {
        config.params.partsSubInformation = this.partsSubInformation;
      }
      // グループ
      if (this.groupName) {
        config.params.groupName = this.groupName;
      }
      // 管理番号
      if (this.itemManageNo) {
        config.params.itemManageNo = this.itemManageNo;
      }
      // 出庫依頼有無
      config.params.outBoundRequest = "01";
      // 出荷実績有無
      config.params.shippingExistDiv = "01";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_WAREINOUT_PARTS, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 成功
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const inOutList = jsonData.resIdv.inOutList;
              let list = [];
              this.inputList = [];
              inOutList.forEach((row) => {
                list.push({
                  No: inOutList.indexOf(row) + 1,
                  itemCd: row.itemCd,
                  itemStandardSid: row.itemStandardSid,
                  productName: row.itemName,
                  groupName: row.groupName,
                  partsRequesterSid: row.userSid,
                  partsRequesterName: row.partsRequesterName,
                  belongSid: row.belongSid,
                  partsSubInformation: row.partsSubInformation,
                });
              });
              this.inputList = list;

              // 初期化
              this.resultList = [];
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve();
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    handleBelongSidChange() {
      this.partsRequester = "";
    },

    choiceExitingHistory(item) {
      this.$emit("choiceExitingHistory", item);
      this.close();
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;
      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;
      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力
     */
    changeDate(val, flg) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      var formatedDt = null;
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        formatedDt = dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(strDt.substr(0, 10)));
      }

      if (flg === 1) {
        this.inScheduleDateFromCalFromCal = formatedDt;
      } else {
        this.inScheduleDateToCal = formatedDt;
      }
    },

    /**
     * 日付を－１日します。
     */
    prevDate(flg) {
      if (flg === 1) {
        this.inScheduleDateFromCal = commonFunction.addDate(this.inScheduleDateFromCal, "");
      } else {
        this.inScheduleDateToCal = commonFunction.addDate(this.inScheduleDateToCal, "");
      }
    },

    /**
     * 日付を＋１日します。
     */
    nextDate(flg) {
      if (flg === 1) {
        this.inScheduleDateFromCal = commonFunction.addDate(this.inScheduleDateFromCal, "add");
      } else {
        this.inScheduleDateToCal = commonFunction.addDate(this.inScheduleDateToCal, "add");
      }
    },
  },
  watch: {
    entity: {
      handler(newValue, oldValue) {
        console.info("handler", newValue, oldValue);
      },
    },
    // eslint-disable-next-line no-unused-vars
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    search(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // 遷移後
        if (this.transitionFlg) {
          this.transitionFlg = false;
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.productCodeList = list.concat(result[0]);
              this.productCodeList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.productCodeList.length = 0;
        }
      }
    },
    inScheduleDateFromCal: {
      handler(val) {
        this.inScheduleDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    inScheduleDateToCal: {
      handler(val) {
        this.inScheduleDateTo = this.formatDate(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  padding: none;
  height: 3rem;
}

// .pad {
//   padding-left: 0;
//   padding-right: 0;
// }

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
