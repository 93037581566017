import Vue from "vue";

export default {
  components: {},
  props: {},
  data: () => ({
    commonInputMixin: Vue.observable({
      // 出荷先
      shipTo: { carrierCompCd: "" },
    }),
  }),
  provide() {
    return {
      commonInputMixin: this.commonInputMixin,
    };
  },
  methods: {},
};
