<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <Loading />
    <!-- 検索項目 -->
    <v-container fluid>
      <!--ボタン領域-->
      <v-row style="margin-left: 3px">
        <div class="search-row-exeBtn pt-2">
          <!-- 詳細内容 -->
          <div style="float: left">
            <!-- 閉じるボタン-->
            <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_close')">
              {{ $t("btn.btn_close") }}
            </v-btn>
          </div>
          <div style="float: right">
            <!-- 追加ボタン -->
            <v-btn
              color="primary"
              id="btn-content-config"
              class="other-btn"
              @click="viewConfirmDialog('btn_add')"
              :disabled="lastListFlg == true"
              >{{ $t("btn.btn_add") }}</v-btn
            >
          </div>
        </div>
      </v-row>
    </v-container>
    <!-- 入力フォーム -->
    <v-form ref="editList" lazy-validation>
      <!-- コンテナ -->
      <v-container fluid>
        <!-- データテーブル -->
        <!-- ヘッダーを固定(fixed-header) -->
        <!-- ヘッダーバインディング(headerItems) -->
        <!-- アイテムバインディング(inputList) -->
        <!-- 波紋エフェクトの無効化(ripple) -->
        <!-- フィルタリングの無効化(disable-filtering) -->
        <!-- ページングの無効化(disable-pagination) -->
        <!-- デフォルトフッターの非表示(hide-default-footer) -->
        <!-- 高さの指定(height) -->
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          disable-sort
          :hide-default-footer="true"
          height="750px"
        >
          <!-- 商品コード/品名-->
          <template v-slot:[`item.productCd`]="{ item }" style="padding: 0 5px">
            <div id="table-productCd">
              <CItemInputRegistable
                v-model="item.productCd"
                :itemCd.sync="item.itemCd"
                :itemName.sync="item.productNm"
                :clientSid="commonInput.suppliersSelected"
                :date="arrivalScheduleDate"
                :items="item.productList"
                :rules="[
                  $inputRules.isRequiredRow(headerItems, item),
                  $inputRules.isCreatedRowRequired(item.inListSubSid, item.productCd),
                  inputProductValid(item),
                ]"
              />
            </div>
          </template>
          <!-- 入り数-->
          <template v-slot:[`item.productQty`]="{ item, index }">
            <div id="table-productQty">
              <v-text-field
                class="input-number"
                outlined
                dense
                v-model="item.productQty"
                @change="changeQty(item, index)"
                @click="clickPop(item.productQty)"
                maxlength="9"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[
                  rules.isNumber,
                  rules.isInteger,
                  rules.isRangeCheck0,
                  rules.limitNumLength,
                ]"
                :ref="'productQtyCheck' + index"
                required
              />
            </div>
          </template>
          <!-- 備考-->
          <template v-slot:[`item.remarks`]="{ item }">
            <v-text-field
              outlined
              dense
              v-model="item.remarks"
              class="text-box"
              persistent-hint
              clear-icon="mdi-close-circle"
              clearable
            />
          </template>
          <!-- 詳細情報ボタン -->
          <template v-slot:[`item.detailInfo`]="{ item, index }">
            <v-btn small @click="openDetailsAdd(item, index)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-form>
    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :redMessage="infoDialog.redMessage"
      :screenFlag="infoDialog.screenFlag"
      :changeFlag="infoDialog.changeFlag"
      :firstPageFlag="infoDialog.firstPageFlag"
    />
    <!-- 詳細情報画面ポップアップ -->
    <detail-info-dialog
      :inListSubSid="detailInfo.inListSubSid"
      :clientSid="detailInfo.clientSid"
      :inScheduleDate="detailInfo.inScheduleDate"
      :itemCd="detailInfo.itemCd"
      :itemName="detailInfo.itemName"
      :itemManageNo="detailInfo.itemManageNo"
      :warehouse="detailInfo.warehouse"
      :locationSid="detailInfo.locationSid"
      :deliveryType="detailInfo.deliveryType"
      :groupName="detailInfo.groupName"
      :retentionDuedate="detailInfo.retentionDuedate"
      :amount="detailInfo.amount ?? ''"
      :currencyUnits="detailInfo.currencyUnits"
      :controlNumber="detailInfo.controlNumber"
      :specificationNoticeNumber="detailInfo.specificationNoticeNumber"
      :partsRequester="detailInfo.partsRequester"
      :invoiceNo="detailInfo.invoiceNo"
      :partsSubInformation="detailInfo.partsSubInformation"
      :remarks="detailInfo.remarks"
      :isShow.sync="openDatailsAdd"
      @addDetailsInfo="addDetailsInfo"
    />
  </div>
</template>

<script>
import Loading from "./loading"; // ローディング画面
import { getParameter } from "../assets/scripts/js/GetParameter"; // マスタ取得
import ConfirmDialog from "./ConfirmDialog"; // 確認ダイアログ
import SimpleDialog from "./SimpleDialog"; // シンプルダイアログ
import DetailInfoDialog from "@/components/DetailInfoDialog";
import { dateTimeHelper } from "../assets/scripts/js/DateTimeHelper"; // 日時ヘルパー
import { i18n } from "../lang/lang.js"; // 多言語対応
import { commonUtil } from "../assets/scripts/js/CommonUtil"; // 共通ユーティリティ
import { appConfig } from "../assets/scripts/js/AppConfig"; // アプリ設定
import { messsageUtil } from "../assets/scripts/js/MesssageUtil"; // メッセージユーティリティ
import commonRules from "@/mixins/CommonRules";
import commonMixin from "@/mixins/CommonMixin";

export default {
  // 表示名
  name: "DetailListDialog",

  // コンポーネント
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    DetailInfoDialog,
  },
  mixins: [commonMixin, commonRules],
  // プロパティ
  props: {
    isMenu: { type: Boolean, default: false },
    viewDataList: { type: Array, default: null },
    isOpenDetailDialog: { type: Boolean, default: false },
    listLength: { type: Number, default: 0 },
    warehouseList: { type: Array, default: null },
    suppliersNm: { type: String, default: "" },
    suppliersSelected: { type: String, default: "" },
    arrivalScheduleDate: { type: String, default: "" },
    lotPullList: { type: Array, default: null },
    screenId: { type: String, default: null },
  },

  // データ
  data: () => ({
    commonInput: {
      suppliersSelected: "",
      // 入荷元プルダウン
      arrivalList: [],
      // 納品種別
      deliveryDivSelected: "",
      // 納品種別プルダウン
      deliveryDivList: [],
      // グループ
      groupSelected: "",
      // グループプルダウン
      groupList: [],
      // 部品要求者
      partsRequesterSelected: "",
      // 部品要求者プルダウン
      partsRequesterList: [],
    },
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // APIメッセージ確認ダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      sixPageFlag: false,
      // ダイアログの外クリック制御
      outsideClickNotCloseFlg: false,
    },
    // 確認ダイアログ設定
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    // 詳細情報ダイアログ
    detailInfo: {
      warehouse: "",
      locationSid: "",
      deliveryType: "",
      groupName: "",
      retentionDuedate: "",
      amount: null,
      currencyUnits: "",
      controlNumber: "",
      specificationNoticeNumber: "",
      partsRequester: "",
      remarks: "",
    },
    // ヘッダー項目
    headerItemsForRcv: [
      // No
      {
        text: "No",
        class: "listHeader",
        value: "No",
        width: "60px",
        sortable: false,
        align: "center",
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "100%",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      // 入り数
      {
        text: i18n.tc("label.lbl_productHacQty"),
        value: "productQty",
        width: "200px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      // 詳細情報
      {
        text: i18n.tc("label.lbl_dateilsInfo"),
        value: "detailInfo",
        width: "180px",
        align: "center",
        class: "asta",
      },
    ],

    detailDialog: {
      // 保管期限
      retentionPeriod: "",
      retentionPeriodCal: "",
      dateMenu: null,

      // 金額
      amount: "",
      formattedAmount: "",

      // 通貨単位
      monetaryUnit: "",
      monetaryUnitList: [],

      // コントロールナンバー
      controlNumber: "",

      // 通知仕様ナンバー
      notificationSpecificationNumber: "",

      // 備考
      remarks: "",
    },
    headerItems: [],
    // 合計リスト
    inputSumList: [],
    lastListFlg: false,
    warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
    simpleDialog: false,
    inputList: [],
    defaultItem: {
      productNm: "",
      productCd: "",
      productHint: "",
      productList: [],
      productAcc: "",
      productQty: "",
      lotNo: commonUtil.createLotNo(
        "", // lot
        "", // serial
        "", // dueDate
        "", // lotSubkey1
        "" // lotSubkey2
      ),
      locationSid: "",
      accInfoList: [],
      accInfos: "",
      isNew: false,
      search: "",
      warehouse: "",
      warehouseList: [],
      locationList: [],
      locationHint: "",
      locaSearch: "",
      location: "",
      cntOpeHist: 0,
      completeFlg: "0",
      isAdd: true,
    },
    dummyItemList: [],
    focusIdx: 0,
    location: "",
    // 詳細画面ポップアップ
    openDatailsAdd: false,
    // バリデーション
    vRules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
    },
  }),

  // メソッド
  methods: {
    // 初期表示処理
    init() {
      this.lastListFlg = false;
      this.focusIdx = this.listLength;
      this.inputList = [];
      for (var i = 0; i < 10; i++) {
        this.defaultItem.No = this.focusIdx + i + 1;
        this.defaultItem.productNm = "";
        this.defaultItem.warehouseList = this.warehouseList;
        this.inputList.push({ ...Object.assign({}, this.defaultItem) });
      }

      this.headerItems = this.headerItemsForRcv;
      this.commonInput.suppliersSelected = this.suppliersSelected;
    },

    /**
     * 詳細情報登録画面をポップアップで開く
     */
    openDetailsAdd(item, index) {
      this.index = index;

      this.detailInfo = {
        inListSubSid: item.inListSubSid,
        clientSid: this.commonInput.suppliersSelected,
        inScheduleDate: this.commonInput.inScheduleDate,
        itemCd: item.itemCd,
        itemName: item.productNm,
        warehouse: item.warehouseSid,
        locationSid: item.locationSid,
      };
      item.inScheduleSub?.map((item) => {
        this.detailInfo[item.subInformation] = item.subInformationComment;
      });

      this.openDatailsAdd = true;
    },

    /**
     * 詳細情報ダイアログに渡す、詳細情報追加処理
     */
    addDetailsInfo(value) {
      this.openDatailsAdd = false;

      this.inputList[this.index].warehouseSid = value.infoCategoryDtl.warehouse;
      this.inputList[this.index].locationSid = value.infoCategoryDtl.locationSid;
      this.inputList[this.index].inScheduleSub = [
        {
          subInformation: "deliveryType",
          subInformationComment: value.infoCategoryDtl.deliveryType,
        },
        {
          subInformation: "groupName",
          subInformationComment: value.infoCategoryDtl.groupName,
        },
        {
          subInformation: "partsRequester",
          subInformationComment: value.infoCategoryDtl.partsRequester,
        },
        {
          subInformation: "retentionDuedate",
          subInformationComment: value.infoCategoryDtl.retentionDuedate,
        },
        {
          subInformation: "amount",
          subInformationComment: value.infoCategoryDtl.amount,
        },
        {
          subInformation: "currencyUnits",
          subInformationComment: value.infoCategoryDtl.currencyUnits,
        },
        {
          subInformation: "controlNumber",
          subInformationComment: value.infoCategoryDtl.controlNumber,
        },
        {
          subInformation: "specificationNoticeNumber",
          subInformationComment: value.infoCategoryDtl.specificationNoticeNumber,
        },
        {
          subInformation: "partsSubInformation",
          subInformationComment: value.infoCategoryDtl.partsSubInformation,
        },
        {
          subInformation: "invoiceNo",
          subInformationComment: value.infoCategoryDtl.invoiceNo,
        },
        {
          subInformation: "remarks",
          subInformationComment: value.infoCategoryDtl.remarks,
        },
      ];
      this.inputList[this.index].locationReserve = [
        {
          locationSid: value.infoCategoryDtl.locationSid,
        },
      ];
    },

    /**
     * 商品有効日時のバリデーション
     */
    inputProductValid(item) {
      if (item.productList && item.productList.length > 0) {
        const product = item.productList.find((v) => v.value == item.productCd);
        if (product && this.arrivalScheduleDate) {
          const arrivalScheduleDate = new Date(this.arrivalScheduleDate);
          const validFrom = new Date(dateTimeHelper.convertUTC2JST(product.validFrom));
          const validTo = new Date(dateTimeHelper.convertUTC2JST(product.validTo));
          // 仮登録商品に対するチェック対象から除外
          if (product.validTo == null) {
            return true;
          }
          // 有効日時が期限内の場合、エラー対象外
          if (validFrom <= arrivalScheduleDate && arrivalScheduleDate <= validTo) {
            return true;
          } else {
            return i18n.tc("check.chk_inputProductFromToValidate");
          }
        }
      }

      if (
        (item.warehouse != "" && item.warehouse != null) ||
        (item.location != "" && item.location != null)
      ) {
        if (item.productCd == "" || item.productCd == null) {
          return i18n.tc("check.chk_input");
        }
      }

      return true;
    },

    /**
     * 仮登録行を追加
     */
    addDummy: function (item) {
      // 取引先が選択されてない場合、処理しない
      if (this.suppliersSelected == null || this.suppliersSelected == "") {
        return;
      }
      if (item.productList.length == 0) {
        // 選択されてない場合、仮登録追加
        const list = [
          {
            text: i18n.tc("label.lbl_newProductAdd"),
            value: "product0",
            name: i18n.tc("label.lbl_newProductAdd"),
            isNew: true,
          },
        ];
        item.productList = list;
        if (this.dummyItemList.length > 0) {
          item.productList = list.concat(this.dummyItemList);
        }
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 入力時カンマ除去処理
     */
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },

    /**
     * 入荷予定修正削除画面：入荷予定数、入力カンマ処理
     */
    changeQty(val, index) {
      // カンマを除去して数値項目に変換
      let proQty = commonUtil.zen2han(val.productQty).replaceAll(",", "");
      proQty = proQty.replace(/[^0-9]/gi, "");

      if (proQty.length > 9) {
        proQty = proQty.substring(0, 9);
      }

      if (!isNaN(Number(proQty))) {
        this.inputList[index].productQty = commonUtil.formatToCurrency(Number(proQty));
      }
      let totalNum = 0;
      this.totalCount = 0;
      if (!isNaN(Number(proQty)) || Number(totalNum)) {
        for (let j = 0; j < this.inputList.length; j++) {
          var proNum = commonUtil.zen2han(this.inputList[j].productQty).replaceAll(",", "");

          // 各行の予定数の合計を計算
          totalNum = Number(totalNum) + Number(proNum);
        }
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));
      }

      let sumList = [];
      sumList.push({
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
    },

    /**
     * 確認ボタン押下時の処理
     */
    async viewConfirmDialog(button) {
      let message;
      let action;
      switch (button) {
        case "btn_add":
          if (!this.$refs.editList.validate()) {
            return;
          } else {
            message = await messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_add")]);
            action = await this.actionSubmitAddRow;
          }
          break;
        case "btn_close":
          message = await messsageUtil.getMessage("P-COM-001_004_W");
          action = await this.close;
          break;
        default:
          message = "";
      }
      this.confirmDialog.message = await message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },

    /**
     * 行追加確認ボタン押下処理
     */
    actionSubmitAddRow() {
      let sendList = [];
      var no = Number(this.inputList[0].No);
      var i = 0;
      sendList = this.inputList.filter((item) => item.productCd != null && item.productCd != "");
      let error = 0;
      sendList.forEach((data) => {
        if (!Object.prototype.hasOwnProperty.call(data, "inScheduleSub")) {
          error++;
          return;
        }
        data.No = no + i;
        i += 1;
      });

      if (error) {
        this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_008_E");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.isOpen = true;
        return;
      }

      this.viewDataList.push(this.inputList[0]);

      this.$emit("child-event", true, sendList);

      this.close();
    },

    /**
     * 閉じる処理
     */
    close() {
      this.init();
      this.$emit("update:isOpenDetailDialog", false);
    },
  },

  // 監視
  watch: {
    inputList: function (inputL) {
      inputL.forEach((value) => {
        if (value.isWatchAdded === void 0) {
          this.$watch(
            () => value.search,
            (val) => {
              if (val ?? false) {
                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val.replace(/\s+/g, "") == value.productHint.replace(/\s+/g, "")) {
                  return;
                }

                // 仮登録の場合変更しない
                if (value.productCd == "product0") {
                  return;
                }
                // // クリア処理
                value.productCd = "";
                value.productHint = "";
                value.productNm = "";
                value.productHint = "";
                value.isNew = false;
                value.productQty = null;
                value.locationSid = null;
                value.location = null;
                value.locationHint = null;
                value.warehouseSid = null;
                value.warehouse = null;

                // 入力桁数が定義した数以上の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  var itemsBiz;
                  itemsBiz = getParameter.getItemsBizAddName2(
                    this.suppliersNm,
                    dateTimeHelper.convertUTC(this.arrivalScheduleDate),
                    val.replace(/\s+/g, "")
                  );

                  Promise.all([itemsBiz])
                    .then((result) => {
                      // 画面の初期値を設定します。
                      value.productList = result[0];
                      // console.debug("inputList: function (inputL) Result[0]", result[0]);
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                      this.infoDialog.firstPageFlag = true;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                } else {
                  value.productList.length = 0;
                }
              } else {
                this.addDummy(value);
              }
            }
          );
          this.$watch(
            () => value.locaSearch,
            function handler(val, oldVal) {
              if ((val ?? false) !== false && val !== oldVal) {
                if (this.suppliersSelected == null || this.suppliersSelected == "") {
                  // 取引先が選択されてない場合、処理しない
                  return;
                }

                if (val == value.locationHint) {
                  // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                  return;
                }

                // 入力桁数が定義した数異常の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  this.loadingCounter = 1;

                  const config = this.$httpClient.createGetApiRequestConfig();
                  config.params.locationNo = val;
                  config.params.officeSid = sessionStorage.getItem("sales_office_sid");
                  config.params.clientSid = this.searchSuppliersSelected;
                  if (value.warehouse) {
                    config.params.warehouseSid = value.warehouse;
                  }
                  config.params.reqComReferenceDate = dateTimeHelper.convertUTC(
                    this.arrivalScheduleDate
                  );
                  return new Promise((resolve, reject) => {
                    this.$httpClient
                      .secureGet(appConfig.API_URL.BIZ_LOCATIONSAVA, config)
                      .then((response) => {
                        // console.debug("$watch Response", response);
                        const jsonData = JSON.parse(JSON.stringify(response.data));
                        if (jsonData.resCom.resComCode == "000") {
                          const list = [];
                          jsonData.resIdv.locations.forEach((row) => {
                            list.push({
                              text: row.locationNo,
                              value: row.locationSid,
                              name: row.locationNo,
                              validFrom: row.validFrom,
                              validTo: row.validTo,
                            });
                          });
                          value.locationList = list;
                          resolve(response);
                        } else {
                          this.infoDialog.message = jsonData.resCom.resComMessage;
                          this.infoDialog.title = appConfig.DIALOG.title;
                          this.simpleDialog = true;
                          this.infoDialog.firstPageFlag = true;
                          reject();
                        }
                      })
                      .catch((ex) => {
                        this.infoDialog.message = ex;
                        this.infoDialog.title = appConfig.DIALOG.title;
                        this.simpleDialog = true;
                        // OK NGボタン
                        this.infoDialog.firstPageFlag = true;
                        reject();
                      })
                      .finally(() => {
                        // ローディング画面表示OFF
                        this.loadingCounter = 0;
                      });
                  });
                } else {
                  value.locationList.length = 0;
                }
              }
            }
          );
        }
        value.isWatchAdded = true;
      });
    },
    isOpenDetailDialog(val) {
      if (val) {
        this.init();
      }
    },
    "detailDialog.retentionPeriodCal": function (newVal) {
      this.detailDialog.retentionPeriod = this.formatDate(newVal);
    },
  },
  mounted() {},
  // Vueインスタンスが生成された後
  created() {
    // 初期表示処理を実行
    this.init();
  },
};
</script>

<style>
@import "../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
}

.toatlNum {
  margin-left: 10px;
}

.link {
  text-decoration: none;
}
#listData.v-data-table ::v-deep th {
  padding: 0 8px;
}

#listData.v-data-table ::v-deep .v-data-table__wrapper {
  overflow-y: hidden;
}

.highlight-column {
  background-color: rgb(221, 229, 240);
}

::v-deep .custom-table table {
  border-collapse: collapse !important;
}

.custom-table th,
.custom-table td {
  border: 1px solid rgb(118, 118, 118) !important;
  height: 56px !important;
}

.custom-table thead tr:first-child th {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid rgb(118, 118, 118) !important;
}

.custom-table tr:hover {
  background-color: transparent !important;
}

::v-deep .right-align-input .v-input__control .v-input__slot input {
  text-align: right !important;
}

.gap-3 > * {
  margin-left: 12px;
}
.gap-3 > *:last-child {
  margin-right: 12px;
}
</style>
