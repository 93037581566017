<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_arrivalScheduleHacCopy" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <v-row class="search-row">
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷No-->
            <div class="textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                maxlength="50"
                v-model="commonInput.txt_slipNo"
                :label="$t('label.lbl_slipNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[rules.limitLength50]"
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷予定日付-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="commonInput.arrivalScheduleDate"
                :label="$t('label.lbl_arrivalScheduleDate')"
                :rules="[rules.inputRequired, rules.yyyymmdd]"
                @change="changeDate"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="commonInput.arrivalScheduleDateCal"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn_yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn_tomorrow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <div style="float: left">
                <!--画面クリアボタン-->
                <v-btn class="other-btn btn_clear" @click="clearScreen()">{{
                  $t("btn.btn_clear")
                }}</v-btn>
                <!--出庫履歴検索ボタン-->
                <v-btn class="other-btn btn_shipping" @click="searchShippingHistory()">{{
                  $t("label.lbl_exitingHistorySearch")
                }}</v-btn>
              </div>

              <!--ボタン領域-->
              <div class="btn-area">
                <!-- 追加ボタン-->
                <v-btn
                  color="primary"
                  class="api-btn btn_fixinsert"
                  v-on:click="add"
                  :disabled="isDoAddButton"
                >
                  {{ $t("btn.btn_add") }}
                </v-btn>

                <!-- 登録ボタン-->
                <v-btn id="btn-pack" class="post-btn btn_slipdelete" value="1" @click="rcvScheAdd">
                  {{ $t("btn.btn_insert") }}
                </v-btn>
                <ConfirmDialog
                  :isShow.sync="ConfirmDialog.isOpen"
                  :message="ConfirmDialog.message"
                  :okAction="addRcvSch"
                  :redMessage="ConfirmDialog.redMessage"
                  :screenFlag="ConfirmDialog.screenFlag"
                  :changeFlag="ConfirmDialog.changeFlag"
                />
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <!-- 品番/品名-->
            <template v-slot:[`item.itemStandardSid`]="{ item }" style="padding: 0 5px">
              <div id="table-productCd">
                <CItemInputRegistable
                  v-model="item.itemStandardSid"
                  :itemCd.sync="item.itemCd"
                  :clientSid="commonInput.suppliersSelected"
                  :items="commonInput.productList"
                  :errorMessages="item.alertMessageProCd"
                  :date="commonInput.arrivalScheduleDate"
                />
              </div>
            </template>

            <!-- 部品補足情報-->
            <template v-slot:[`item.partsSubInformation`]="{ item }">
              <v-text-field
                :label="$t('label.lbl_partsSubInformation')"
                outlined
                dense
                v-model="item.partsSubInformation"
                class="text-box"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="200"
                :rules="[rules.limitNumLength200]"
              />
            </template>

            <!-- グループ名 -->
            <template v-slot:[`item.groupName`]="{ item }">
              <v-text-field
                :label="$t('label.lbl_group')"
                outlined
                dense
                v-model="item.groupName"
                class="text-box"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="200"
                :rules="[rules.limitNumLength200]"
                :errorMessages="item.alertMessageGroupName"
              />
            </template>

            <!-- 所属 -->
            <template v-slot:[`item.belongSid`]="{ item }">
              <div id="table-belongSid">
                <c-belong-input
                  v-model="item.belongSid"
                  class="pt-6 pb-4"
                  @input="handleBelongSidChange(item)"
                />
              </div>
            </template>

            <!-- 部品要求者 -->
            <template v-slot:[`item.partsRequester`]="{ item }">
              <div id="table-partsRequester">
                <c-user-input
                  :label="$t('label.lbl_partsRequester')"
                  v-model="item.partsRequester"
                  class="pt-6 pb-4"
                  :belongSid="item.belongSid"
                  :errorMessages="item.alertMessagePartsRequester"
                />
              </div>
            </template>

            <!-- 複製 -->
            <template v-slot:[`item.duplication`]="{ item, index }">
              <div class="table-duplication">
                <v-text-field
                  outlined
                  dense
                  v-model="item.duplication"
                  class="text-box"
                  persistent-hint
                  clear-icon="mdi-close-circle"
                  clearable
                  style="flex: 0 0 8ch"
                  :rules="[rules.checkInteger]"
                />
                <v-btn small @click="makeCopy(item.duplication, index)" text>
                  <v-icon>mdi-content-duplicate</v-icon>
                </v-btn>
              </div>
            </template>

            <!-- 削除-->
            <template v-slot:[`item.deleteData`]="{ item }">
              <v-btn small @click="deleteItem(item)" text>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-form>

      <!-- 出庫履歴検索 -->
      <v-dialog v-model="dialogDetails" :max-width="2000">
        <ExitingHistorySearch
          id="detailsDialig"
          :isOpenDetailDialog.sync="dialogDetails"
          :entity="detailDialogData"
          :hoge1="1"
          class="overflow"
          v-on:closePopup="closePopup"
          @choiceExitingHistory="(list) => (addList = list)"
        />
      </v-dialog>

      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 戻るボタンのダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :redMessage="infoDialog.redMessage"
        :homePageFlag="infoDialog.homePageFlag"
        :rcvSchHacListFlg="infoDialog.rcvSchHacListFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :okAction="init"
        :rcvSchDownloadListFlg="infoDialog.rcvSchDownloadListFlg"
        :rcvSchDownloadList="rcvSchDownloadList"
      />
      <CheckDialog
        :isShow.sync="checkDialog.isOpen"
        :message="checkDialog.message"
        :screenFlag="checkDialog.screenFlag"
        :okAction="checkDialog.okAction"
        :redMessage="checkDialog.redMessage"
        :changeFlag="checkDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import NavBar from "../../components/NavBar.vue";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { i18n } from "../../lang/lang.js";
import ConfirmDialog from "@/components/ConfirmDialog";
import CheckDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import ExitingHistorySearch from "../../views/exiting/ExitingHistorySearch.vue";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";

export default {
  name: appConfig.SCREEN_ID.P_ENT_101,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    CheckDialog,
    ConfirmDialog,
    NavBar,
    ExitingHistorySearch,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // 共通入力
    commonInput: {
      //入荷No
      txt_slipNo: "",
      // 入荷予定日
      arrivalScheduleDate: "",
      // 入荷予定日カレンダー
      arrivalScheduleDateCal: "",
      // 取引先
      suppliersSelected: "",
      // 取引先プルダウン
      supplierList: [],
      // 入荷先
      fromSid: "",
      // 無償理由
      freeReasonSelected: "",
      // クリアフラグ
      clearFlg: false,
    },
    u_10: false,
    loadingCounter: 0,
    // 一覧index
    editedIndex: -1,
    //一覧ボディ領域
    editedList: [],
    // メニュー
    openMenu: null,
    // 入荷予定日付キャレンダー
    dateMenu: false,
    // フォーカス移動フラグ
    moveFocusFlag: false,
    // 追加ボタンdisabledフラグ
    isDoAddButton: false,
    checkDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 初期データ
    defaultData: [],
    // 入荷予定品目一覧ヘッダ
    headerItems: [
      // No
      {
        text: i18n.tc("label.lbl_No"),
        class: "listHeader",
        value: "rowNumber",
        width: "5%",
        sortable: false,
        align: "center",
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "itemStandardSid",
        width: "15%",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      // 部品補足情報
      {
        text: i18n.tc("label.lbl_partsSubInformation"),
        value: "partsSubInformation",
        width: "15%",
        align: "center",
        sortable: false,
      },
      // グループ名
      {
        text: i18n.tc("label.lbl_group"),
        value: "groupName",
        width: "15%",
        align: "center",
        sortable: true,
        class: "asta",
        isRequired: true,
      },
      // 所属
      {
        text: i18n.tc("label.lbl_belong"),
        value: "belongSid",
        width: "15%",
        align: "center",
        sortable: false,
      },
      // 部品要求者
      {
        text: i18n.tc("label.lbl_partsRequester"),
        value: "partsRequester",
        width: "15%",
        align: "center",
        sortable: false,
        class: "asta",
        isRequired: true,
      },
      // 複製
      {
        text: i18n.tc("label.lbl_copy"),
        value: "duplication",
        width: "10%",
        align: "center",
        sortable: false,
      },
      // 削除
      { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "5%", align: "center" },
    ],
    inputList: [],
    addList: [],
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    defaultItem: {},
    focusIdx: 0,
    dialogDetails: false,
    detailDialogData: {
      suppliersSelectedName: "",
      qualityDiv: "",
      itemName: "",
      itemStandardSid: "",
    },
    inListSids: [],
    pdfUrl: null,
    inScheduleSub: [
      "deliveryType",
      "groupName",
      "retentionDuedate",
      "amount",
      "currencyUnits",
      "controlNumber",
      "specificationNoticeNumber",
      "partsRequester",
      "remarks",
      "invoiceNo",
      "partsSubInformation",
    ],
    // 入力チェック
    rules: {
      limitNumLength200: (value) =>
        value == null || value.length <= 200 || i18n.tc("check.chk_limitLength_1-200"),
      checkInteger: function (value) {
        let isValid;
        const regex = /^-?\d+$/;
        if (regex.test(value) || !value) {
          isValid = true;
        } else {
          isValid = false;
        }
        if (!isValid) {
          return i18n.tc("check.chk_inputInteger");
        }
        return true; // 整数の場合
      },
    },
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  created() {
    // 実績登録画面で必要な値
    // 入荷予定日（明細）
    this.commonInput.arrivalScheduleDate = this.$route.params.arrivalScheduleDate.substr(0, 10);
    // 入荷元名（実績登録画面表示用）
    this.commonInput.arrivalSelected = this.$route.params.arrivalSelected;
    // 入荷元SID（明細）
    this.fromSid = this.$route.params.fromSid;
    // 入荷No（明細）
    this.commonInput.txt_slipNo = this.$route.params.inListNo;
    // 責任区分(自責/他責)
    this.isRes = this.$route.params.blameDiv;
    // 無償理由名
    this.commonInput.freeReasonSelected = this.$route.params.reasonCdName;
    // 予定更新日時
    this.scheduleUpdateDatetime = this.$route.params.scheduleUpdateDatetime;
    // 品質区分
    this.qualityDiv = this.$route.params.qualityDiv;

    // 入荷予定一覧の検索条件
    this.previousSearchFilter = this.$route.params.searchFilter;
    // 取引先SID
    this.suppliersNm = this.$route.params.suppliersNm;
    // 入荷元SID
    this.arrivalSid = this.$route.params.arrivalSid;
    // 処理区分(通常/返品)（明細）
    this.arrivalStatusSelectedList = this.$route.params.processingdivision;
    // 画面遷移判断フラグ
    this.checkFlg = this.$route.params.checkFlg;
  },

  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      // 初期画面
      this.commonInput.txt_slipNo = this.$route.params.inListNo;
      this.commonInput.txt_slipNo = "";
      this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
        dateTimeHelper.convertJST()
      );
      this.commonInput.arrivalScheduleDate = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      for (var i = 0; i < 10; i++) {
        this.defaultItem.rowNumber = i + 1;
        this.defaultItem.search = "";
        this.inputList.push(Object.assign({}, this.defaultItem));
      }
      // 項目初期化時に発生するバリデーションチェックをキャンセルする
      this.$refs.form.resetValidation();
      this.inListSids = [];
      this.errorFlg = true;
      this.getCustomInfo(this.commonInput.arrivalScheduleDate);
    },

    /**
     * 初期データ取得
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.commonInput.supplierList = result[0];
          // 取引先が1件の場合、取引先を自動選択する
          this.commonInput.supplierList.length == 1
            ? (this.commonInput.suppliersSelected = this.commonInput.supplierList[0].value)
            : "";
          this.getFunctionPermissions();
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
          this.infoDialog.rcvSchDownloadListFlg = false;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 入荷予定登録画面：入荷予定日付を－１日
     */
    prevDate() {
      let date = new Date(this.commonInput.arrivalScheduleDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.commonInput.arrivalScheduleDateCal == null) {
        return;
      }
      if (this.commonInput.arrivalScheduleDateCal != toDate) {
        this.commonInput.arrivalScheduleDateCal = toDate;
      } else {
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() - 1)
        );
      }
      this.clearScreenOkClick();
    },

    /**
     * 入荷予定登録画面：入荷予定日付を＋１日
     */
    nextDate() {
      let date = new Date(this.commonInput.arrivalScheduleDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.commonInput.arrivalScheduleDateCal == null) {
        return;
      }
      if (this.commonInput.arrivalScheduleDateCal != toDate) {
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 2)
        );
      } else {
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 1)
        );
      }
      this.clearScreenOkClick();
    },

    /**
     * 入荷予定登録画面：与えられた日付から年月日を文字列で返却処理
     */
    dateCalc(date) {
      const dt = new Date(date);
      let year = dt.toISOString().substr(0, 4);
      let month = dt.toISOString().substr(5, 2);
      let day = dt.toISOString().substr(8, 2);
      // ISO時間
      const todayISOTime = dt.toISOString().substr(11, 2);
      if (todayISOTime >= 15) {
        // 月末
        if (
          (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10) &&
          day == 31
        ) {
          // 31日まである月
          month = Number(month) + 1;
          day = "1";
        } else if ((month == 4 || month == 6 || month == 9 || month == 11) && day == 30) {
          // 30日まである月
          month = Number(month) + 1;
          day = "1";
        } else if (month == 2) {
          if (year % 4 == 0 && day == 29) {
            // 2月29日まである年
            month = Number(month) + 1;
            day = "1";
          } else if (year % 4 != 0 && day == 28) {
            // 2月28日まである年
            month = Number(month) + 1;
            day = "1";
          }
        } else if (month == 12 && day == 31) {
          //12月
          year = Number(year) + 1;
          month = "1";
          day = "1";
        } else {
          day = Number(day) + 1;
        }
        if (month < 10 && month.length != 2) {
          month = "0" + month;
        }
        if (day < 10 && day.length != 2) {
          day = "0" + day;
        }
      }
      return year + "-" + month + "-" + day;
      // return dt.toISOString().substr(0, 10);
    },

    /**
     * 入荷予定登録画面：yyyy/MM/ddに変換処理（カレンダコンポーネントの日付はyyyy-MM-ddのため）
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    handleBelongSidChange(item) {
      if (!item.notDelFlg) {
        this.$set(item, "partsRequester", "");
      } else {
        item.notDelFlg = false;
      }
    },

    /**
     * コピー
     */
    makeCopy(copyNumber, insertNumber) {
      const copyList = JSON.parse(JSON.stringify(this.inputList[insertNumber])); //変更する
      copyList.notDelFlg = true;
      delete copyList.duplication;
      for (var i = 0; i < copyNumber; i++) {
        this.inputList.splice(insertNumber + 1 + i, 0, { ...copyList });
      }
      this.inputList.forEach((row, index) => {
        row.rowNumber = index + 1;
        row.notDelFlg = true;
      });
    },

    /**
     * 入荷予定登録画面：行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.inputList.splice(this.editedIndex, 1);

      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].rowNumber = i + 1;
      }
    },

    getClientName() {
      return commonFunction.getListSetText(
        this.commonInput.supplierList,
        this.commonInput.suppliersSelected
      );
    },

    // ダイアログオープン
    openPopup() {
      this.dialogDetails = true;
    },
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },

    /**
     * 明細の選択した商品コードに紐づく数量を返却するメソッド
     */
    getSelectedProductQuantity(index) {
      // 返却値
      let returnQuantity = 0;

      // inputListの商品コードを取得
      let inputListProductCd = this.inputList[index].itemStandardSid;

      if (!inputListProductCd) {
        // inputListの商品コードを取得

        // 選択した商品コードがproductListに何番目に入っているかを調べる。
        let selectedProductListIndex = -1;
        for (var i = 0; i < this.inputList[index].productList.length; i++) {
          if (inputListProductCd == this.inputList[index].productList[i].value) {
            selectedProductListIndex = this.inputList[index].productList[i].quantity;
            break;
          }
        }
        if (0 <= selectedProductListIndex) {
          // 選択している品番の数量を取得する。
          returnQuantity = this.inputList[index].productList[selectedProductListIndex].quantity;
        }
      }
      return returnQuantity;
    },

    /**
     * 一覧ボディ空時ダイアログ：OK押下
     */
    updateOk() {
      if (this.errorFlg) {
        if (this.toPage) {
          this.$router.push({
            name: this.toPage,
            params: {
              headerList: this.searchParam,
              backPage: this.toPage,
            },
          });
          return;
        }

        this.updateDialogMessage = false;
        this.checkFlg = true;
        this.$router.push({
          name: appConfig.SCREEN_ID.P_MST_095,
          params: {
            // 取引先
            suppliersSelected: this.suppliersNm,
            // 画面遷移フラグ
            checkFlg: this.checkFlg,
            // 入荷元ドロップダウン
            arrivalList: this.$route.params.arrivalList,
            // 入荷元ドロップダウンヒント
            arrivalNm: this.$route.params.arrivalOmitNm,
            // 入荷予定一覧検索条件
            searchFilter: this.previousSearchFilter,
          },
        });
      } else {
        this.updateDialogMessage = false;
        this.errorFlg = true;
      }
    },

    /**
     * 入荷予定登録画面：行追加処理
     */
    add() {
      // 追加ボタンをdisabledに
      this.isDoAddButton = true;

      if (this.inputList.length != 0) {
        this.focusIdx = this.inputList[this.inputList.length - 1].rowNumber;
      } else {
        this.focusIdx = 0;
      }

      for (var i = 0; i < 10; i++) {
        this.defaultItem.rowNumber = this.focusIdx + i + 1;
        this.inputList.push(Object.assign({}, this.defaultItem));
      }
      // フォーカス移動フラグをONに
      this.moveFocusFlag = true;
      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
      // console.debug("行追加");
    },

    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.isDoAddButton = false;
    },

    /**
     * 入荷予定登録画面：日付入力処理
     */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.commonInput.arrivalScheduleDateCal = null;
      }
    },

    /**
     * 入荷予定登録画面：登録ボタン押下
     */
    rcvScheAdd() {
      //エラー内容初期化処理--------------------------------------------------
      this.loadingCounter = 1;
      for (let i = 0; i < this.inputList.length; i++) {
        this.inputList[i].alertMessageProCd = i18n.tc("");
        this.inputList[i].alertMessageGroupName = i18n.tc("");
        this.inputList[i].alertMessagePartsRequester = i18n.tc("");
      }
      this.loadingCounter = 0;
      //エラー内容初期化処理--------------------------------------------------
      let errFlg = false;
      // 伝票部、入力チェック実施
      if (this.$refs.form.validate() && this.$refs.editedList.validate()) {
        // 明細部未入力チェック準備
        let checkList = this.inputList.filter((item) => item.itemStandardSid);

        if (checkList.length === 0) {
          // 上記でのチェックリストが0件の場合は、警告メッセージ表示
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-RCV-001_006_E");
          errFlg = true;
        }

        for (let i = 0; i < this.inputList.length; i++) {
          if (
            this.inputList[i].itemStandardSid ||
            this.inputList[i].partsSubInformation ||
            this.inputList[i].groupName ||
            this.inputList[i].belongSid ||
            this.inputList[i].partsRequester
          ) {
            if (!this.inputList[i].itemStandardSid) {
              this.inputList[i].alertMessageProCd = i18n.tc("check.chk_input");
              errFlg = true;
            }
            if (!this.inputList[i].groupName) {
              this.inputList[i].alertMessageGroupName = i18n.tc("check.chk_input");
              errFlg = true;
            }
            if (!this.inputList[i].partsRequester) {
              this.inputList[i].alertMessagePartsRequester = i18n.tc("check.chk_input");
              errFlg = true;
            }
          }
        }

        if (!errFlg) {
          this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
        } else {
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-RCV-001_006_E");
        }
      }
    },

    /**
     * 入荷予定登録画面：カーソル位置処理
     */
    setCursor() {
      let listLength = this.inputList.length;
      let cursolPosition = String(listLength - 10);
      eval("this.$refs.cursorProcd" + cursolPosition + ".focus()");
    },

    /**
     * 入荷予定登録(再入庫)画面：入荷予定API（POST）
     */
    addRcvSch() {
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        this.inListSids = [];

        // パラメータ設定
        const body = this.$httpClient.createRequestBodyConfig();
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_ENT_101;

        body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
        body.reqIdv.clientSid = this.commonInput.suppliersSelected;
        if (this.commonInput.txt_slipNo) {
          body.reqIdv.inListNo = this.commonInput.txt_slipNo;
        }
        body.reqIdv.inScheduleDate = dateTimeHelper.convertUTC(
          this.commonInput.arrivalScheduleDate
        );
        body.reqIdv.freeCostDiv = "01";
        body.reqIdv.processDiv = "01";
        body.reqIdv.blameDiv = "01";
        body.reqIdv.fromSid = this.commonInput.fromSid;
        body.reqIdv.toSid = sessionStorage.getItem("sales_office_sid");
        body.reqIdv.businessSid = "";
        body.reqIdv.deleteFlg = 0;
        body.reqIdv.updateDatetime = dateTimeHelper.convertUTC();

        let inScheduleDtl = [];
        // 入荷伝票明細No用カウンタ
        let inListSubNo = 1;
        this.inputList.forEach((row) => {
          if (row.itemStandardSid) {
            inScheduleDtl.push({
              inListSubNo: inListSubNo,
              itemStandardSid: row.itemStandardSid,
              itemCd: row.itemCd,
              lotNo: commonUtil.createLotNo(
                "", // lot
                "", // serial
                "", // dueDate
                "", // lotSubkey1
                "" // lotSubkey2
              ),
              inScheduleQuantity: "1",
              caseQuantity: null,
              ballQuantity: null,
              pieceQuantity: null,
              qualityDiv: "01",
              deleteFlg: 0,
              belongSid: row.belongSid,
              locationReserve: [],
              infoCategoryDtl: this.inScheduleSub.map((key) => {
                return {
                  subInformation: key,
                  subInformationComment: key === "deliveryType" ? "10" : row[key] ?? "",
                };
              }),
            });
            // 明細行追加後、入荷予定明細Noのカウンタを増やす
            inListSubNo++;
          }
        });
        body.reqIdv.inScheduleDtl = inScheduleDtl;

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_INSCHEDULES_HAC, body, appConfig.APP_CONFIG)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                this.inListSids.push(jsonData.resIdv.inListSid);
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                // 画面遷移ボタン
                this.infoDialog.homePageFlag = true;
                this.infoDialog.rcvSchHacListFlg = true;
                this.infoDialog.firstPageFlag = false;
                this.infoDialog.rcvSchDownloadListFlg = true;

                // エラー時
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;
                this.infoDialog.rcvSchDownloadListFlg = false;
                reject(new Error(jsonData.resCom.resComMessage));
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.rcvSchDownloadListFlg = false;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * 出荷履歴検索ボタン押下時の処理
     */
    searchShippingHistory() {
      this.dialogDetails = true;
      this.detailDialogData.suppliersSelected = this.commonInput.suppliersSelected;
      this.detailDialogData.suppliersSelectedName = this.getClientName();
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.checkDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.checkDialog.isOpen = true;
      this.checkDialog.screenFlag = true;
      this.checkDialog.changeFlag = false;
      this.checkDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期化
      this.inputList = [];
      this.clearFlg = true;
      this.defaultData = Object.assign({}, this.$data);

      for (var i = 0; i < 10; i++) {
        this.defaultItem.rowNumber = i + 1;
        this.defaultItem.search = "";
        this.inputList.push(Object.assign({}, this.defaultItem));
      }

      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    /**
     * PC制御マスタ-入荷元取得
     */
    getFunctionPermissions() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const functionPermissions = getParameter.getFunctionPermissions({
        conditions: [
          {
            clientSid: this.commonInput.suppliersSelected,
            attachResourceKey: appConfig.SCREEN_ID.P_ENT_101,
            item01: "defaultArrivalFrom",
          },
        ],
      });
      Promise.all([functionPermissions])
        .then((result) => {
          if (result[0].find((permission) => permission.item01 == "defaultArrivalFrom")) {
            this.commonInput.fromSid = result[0][0].workflowMatterId;
          } else {
            this.commonInput.fromSid = "";
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.rcvSchDownloadListFlg = false;
        })
        .finally(() => {
          if (!this.$route.params.searchFlg) {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          }
        });
    },

    /**
     * 予定リスト出力処理
     */
    rcvSchDownloadList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();
      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_ENT_101;
      // 取引先SID
      body.reqIdv.clientSid = this.commonInput.suppliersSelected;
      // 入荷伝票Sid
      body.reqIdv.inListSids = this.inListSids;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_IN_SCHEDULE_HAC_LIST, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // ファイル名
              this.pdfUrl = jsonData.resIdv.filePath;
              // ファイルダウンロード
              location.href = this.pdfUrl;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = messsageUtil.getMessage(jsonData.resCom.resComMessageId, [
                i18n.tc("label.lbl_upload"),
              ]);
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              this.infoDialog.homePageFlag = false;
              this.infoDialog.rcvSchHacListFlg = false;
              this.infoDialog.rcvSchHacAddFlg = false;
              this.infoDialog.rcvSchDownloadListFlg = false;
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.outsideClickNotCloseFlg = false;
            this.infoDialog.homePageFlag = false;
            this.infoDialog.rcvSchHacListFlg = false;
            this.infoDialog.rcvSchHacAddFlg = false;
            this.infoDialog.rcvSchDownloadListFlg = false;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
  },
  computed: {},
  watch: {
    "commonInput.arrivalScheduleDateCal": {
      handler(val) {
        this.commonInput.arrivalScheduleDate = this.formatDate(val);
      },
      deep: true,
    },
    "commonInput.arrivalScheduleDate": {
      handler() {
        // 入荷予定日の形式チェック
        if (dateTimeHelper.isDisplayDateFormat(this.commonInput.arrivalScheduleDate)) {
          // 入荷予定日がyyyy/MM/dd形式の場合
          // 取引先を取得する処理
          this.getCustomInfo(this.commonInput.arrivalScheduleDate);
        } else {
          // 上記以外の場合、処理なし
        }
      },
      deep: true,
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },

    inputList: function (inputL) {
      inputL.forEach((value) => {
        if (value.isWatchAdded === void 0) {
          this.$watch(
            () => value.search,
            (val) => {
              if (val ?? false) {
                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val == value.productHint) {
                  return;
                }

                // クリア処理
                value.itemStandardSid = "";
                value.productHint = "";
                value.productNm = "";
                value.productHint = "";
                value.isNew = false;

                // 入力桁数が定義した数以上の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  const itemsBiz = getParameter.getItemsBizAddName2(
                    this.commonInput.suppliersSelected,
                    dateTimeHelper.convertUTC(this.commonInput.arrivalScheduleDate),
                    val
                  );

                  Promise.all([itemsBiz])
                    .then((result) => {
                      // 画面の初期値を設定します。
                      value.productList = result[0];
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                      this.infoDialog.firstPageFlag = true;
                      this.infoDialog.rcvSchDownloadListFlg = false;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                } else {
                  value.productList.length = 0;
                }
              }
            }
          );
        }
        value.isWatchAdded = true;
      });
    },

    addList: function (add) {
      const emptyObjects = this.inputList.filter(
        (row) =>
          !row.itemStandardSid &&
          !row.partsSubInformation &&
          !row.groupName &&
          !row.belongSid &&
          !row.partsRequester
      );

      const addL = {
        isWatchAdded: true,
        search: "",
        itemCd: add.itemCd,
        itemStandardSid: add.itemStandardSid,
        partsSubInformation: add.partsSubInformation,
        groupName: add.groupName,
        belongSid: add.belongSid,
        partsRequester: add.partsRequesterSid,
        notDelFlg: true,
      };

      if (emptyObjects.length === 0) {
        this.inputList.push(addL);
        this.inputList.rowNumber = this.inputList.length;
      } else {
        addL.rowNumber = emptyObjects[0].rowNumber;
        this.$set(this.inputList, emptyObjects[0].rowNumber - 1, addL);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}
#lbl-user-name {
  // font-size: $menu-fontsize !important;
  font-weight: bold;
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
}
#listSumDataSpecial {
  ::-webkit-scrollbar-thumb {
    background: red;
  }

  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child {
      background-color: #eee;
      height: 0rem;
    }

    // グループヘッダー
    .v-row-group__header {
      background-color: #dde5f0;
      height: 0px;
    }

    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #ffffff;
    }

    // テーブルデータ部分
    td {
      font-size: large;

      &:nth-child(1) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(2) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(3) {
        text-align: right;
        width: 11%;
      }

      &:nth-child(4) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(5) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(6) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(7) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(8) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(9) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(10) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(11) {
        text-align: right;
        width: 5%;
      }
    }
  }

  white-space: nowrap;
  pointer-events: none;
  font-weight: 700;
  // overflow-y: scroll;
  // overflow-y: hidden;
}

.tableData {
  border-collapse: collapse;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 12px;
}

.highlight-column {
  background-color: rgb(221, 229, 240);
}

::v-deep .custom-table table {
  border-collapse: collapse !important;
}

.custom-table th,
.custom-table td {
  border: 1px solid rgb(118, 118, 118) !important;
  height: 56px !important;
}

.custom-table thead tr:first-child th {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid rgb(118, 118, 118) !important;
}

.custom-table tr:hover {
  background-color: transparent !important;
}

.table-duplication {
  display: flex;
  align-items: center; /* 縦方向の中央揃え */
  gap: 8px; /* テキストボックスとアイコンの間隔 */
}

::v-deep .right-align-input .v-input__control .v-input__slot input {
  text-align: right !important;
}
</style>
