<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <loading-spinner />
    <error-message-dialog />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_arrivalScheduleList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <c-client-input v-model="suppliersSelected" required />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 品番/品名 -->
            <div class="search-autocomplete" style="width: 250px">
              <c-item-input v-model="productCnCdSelected" :clientSid="suppliersSelected" />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 部品補足情報 -->
            <div class="search-textbox">
              <v-text-field
                outlined
                dense
                v-model="txt_partsSubInformation"
                maxlength="200"
                :label="$t('label.lbl_partsSubInformation')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!--入荷No-->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="txt_slipNo"
                maxlength="50"
                :label="$t('label.lbl_slipNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrAchieveDateFrom"
                class="txt-single date-style"
                :label="$t('label.lbl_arrivalScheduleDate') + '（From）'"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="arrAchieveDateFromCal" @input="dateMenu = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="preDateOfAchieveFrom"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDateOfAchieveFrom"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダーの間 -->
            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- toカレンダー -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrAchieveDateTo"
                class="txt-single date-style"
                :label="$t('label.lbl_arrivalScheduleDate') + '（To）'"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu2"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="arrAchieveDateToCal" @input="dateMenu2 = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="preDateOfAchieveTo"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDateOfAchieveTo"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->
          </v-row>
          <v-row class="search-row">
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- グループ名 -->
            <div class="search-autocomplete" style="width: 250px">
              <v-text-field
                v-model="txt_groupName"
                :label="$t('グループ')"
                persistent-hint
                dense
                item-text="name"
                item-value="id"
                return-object
                outlined
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 納品種別-->
            <div class="search-autocomplete" style="width: 250px">
              <c-code-input v-model="deliveryDivSelected" codeType="1031" width="250px" />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 部品要求者 -->
            <div class="search-autocomplete" style="width: 250px">
              <c-user-input :label="$t('部品要求者')" v-model="partsRequesterSelected" />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 作成日From-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="createDateFrom"
                :label="$t('label.lbl_createDate') + '（From）'"
                @change="changecreateDateFrom"
                :rules="[
                  rules.inputRequiredCreate(createDateFrom, createDateTo),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateCreateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="createDateFromCal"
                  @input="dateCreateMenuFrom = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="ctprevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="ctnextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- カレンダーの間 -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 作成日To-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="createDateTo"
                :label="$t('label.lbl_createDate') + '（To）'"
                @change="changecreateDateTo"
                :rules="[
                  rules.inputRequiredCreate(createDateTo, createDateFrom),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertCreateMessage"
              >
              </v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateCreateMenuTo"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="createDateToCal"
                  @input="dateCreateMenuTo = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="ctprevToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="ctnextToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn class="get-btn" @click="getList(true)">{{ $t("btn.btn_search") }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-search-area" style="float: right">
                <!--入荷予定リスト出力ボタン-->
                <v-btn
                  class="get-btn"
                  @click="downloadList(true)"
                  :disabled="isDisableDownloadBtn"
                  >{{ $t("予定リスト出力") }}</v-btn
                >
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_10_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_10_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_10_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="530px"
        >
          <!-- ヘッダーチェックボックス-->
          <template v-slot:[`header.check`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              @click="selectCheck(index)"
              style="transform: scale(2)"
            />
          </template>
          <template v-slot:[`item.productNm`]="{ item }">
            <v-btn color="blue darken-1" text>
              {{ item.productNm }}
            </v-btn>
          </template>

          <!-- 品番/品名 -->
          <template v-slot:[`item.productCd`]="{ item }">
            <v-tooltip bottom :disabled="item.isItemTooltipFlg">
              <template v-slot:activator="{ on, attrs }">
                <div class="ellipsis-tooltip" v-bind="attrs" v-on="on">
                  <div class="ellipsis">{{ item.productCd }}</div>
                  <div class="ellipsis">{{ item.product }}</div>
                </div>
              </template>
              <span>{{ item.productCd }}<br />{{ item.product }}</span>
            </v-tooltip>
          </template>

          <!-- 部品補足情報 -->
          <template v-slot:[`item.partsSubInformation`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.partsSubInformation }}
                </div>
              </template>
              <span>{{ item.partsSubInformation }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.accInfos`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.accInfos }}
                </div>
              </template>
              <span>{{ item.accInfos }}</span>
            </v-tooltip>
          </template>

          <!-- 伝票No -->
          <template v-slot:[`item.shipNo`]="{ item }">
            <div class="listNo-style">{{ item.shipNo }}</div>
          </template>

          <!-- 詳細ボタン -->
          <template v-slot:[`item.location`]="{ item }">
            <v-btn small @click="openUpdateDelete(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.achieveAdd`]="{ item }">
            <div id="table-achieveAdd" v-if="item.inStock == 1 || item.achieveRole == 'false'">
              <!-- 実績登録ボタン-->
            </div>
            <div v-else>
              <v-btn small @click="openAchieveAdd(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>

          <!-- 入荷済み -->
          <template v-slot:[`item.inStock`]="{ item }">
            <p class="txt-center" v-if="item.inStock == 0">未入荷</p>
            <p class="txt-center" v-if="item.inStock == 1">入荷済</p>
          </template>
          <!-- 登録方法 -->
          <template v-slot:[`item.howtoregister`]="{ item }">
            <p class="txt-center" style="color: red" v-if="item.entryDiv == '03'">
              {{ item.howtoregister }}
            </p>
            <p class="txt-center" v-else>{{ item.howtoregister }}</p>
          </template>
          <!-- 取込時間 -->
          <template v-slot:[`item.retrieveTime`]="{ item }">
            <p class="txt-center">{{ item.retrieveTime }}</p>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import ConfirmDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import CCodeInput from "@/components/CCodeInput";

export default {
  name: appConfig.SCREEN_ID.P_RCV_102,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
    CCodeInput,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    loadingCounter: 0,
    arrivalList: [],
    u_10: false,
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
    },
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // 総件数
    totalCount: 0,
    sortItem: "",
    sortOptions: {},
    // ロケーションダイアログ
    dialogLocation: false,
    // 入荷予定日付
    arrivalScheduleDateCal: "",
    arrivalScheduleDate: "",
    // 荷主伝票番号
    txt_slipNo: "",
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    inListSid: "", // 修正削除画面に渡すid
    // 取引先名+取引先コード
    suppliersNm: "",
    // 取引先名
    clientNm: "",
    // 品番/品名
    productCnCdSelected: "",
    productCnCdList: [],
    // 部品補足情報
    txt_partsSubInformation: "",
    // 有償/無償
    isPaid: "1",
    // 入荷先
    arrivalSelected: "",
    // 取引先
    suppliersSelected: "",
    // 処理区分
    arrivalStatusSelected: "",
    // メニュー
    openMenu: null,
    // 入荷予定No.
    txt_arrivalScheduleNo: "",
    // 入荷実績日付from
    arrAchieveDateFrom: "",
    arrAchieveDateFromCal: "",
    // 入荷実績日付to
    arrAchieveDateTo: "",
    arrAchieveDateToCal: "",
    // 入荷予定日付キャレンダー
    dateMenu: false,
    dateMenu2: false,
    // 作成日Fromカレンダー
    dateCreateMenuFrom: false,
    // 作成日Toカレンダー
    dateCreateMenuTo: false,
    // 作成日From
    createDateFromCal: "",
    createDateFrom: "",
    // 作成日To
    createDateToCal: "",
    createDateTo: "",
    // 納品種別
    deliveryDivSelected: "",
    // グループ
    txt_groupName: "",
    // 部品要求者
    partsRequesterSelected: "",
    pdfUrl: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    alertMessage: "",
    alertSupplierMessage: "",
    alertCreateMessage: "",
    // 実績登録権限
    achieveRole: false,
    // 初期データ
    defaultData: [],
    // 対象出力データリストinListSid
    targetData: [],
    // ヘッダ
    headerItems: [
      // 選択
      {
        text: "選択",
        value: "check",
        width: "5%",
        align: "center",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_slipNo"),
        value: "shipNo",
        width: "12%",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_arrivalScheduleDate"),
        value: "arrivalScheduleDate",
        width: "8%",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "14%",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_partsSubInformation"),
        value: "partsSubInformation",
        width: "14%",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_arrived"),
        value: "inStock",
        align: "center",
        width: "8%",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_howtoRegister"),
        value: "howtoregister",
        align: "center",
        width: "8%",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_retrieveTime"),
        value: "retrieveTime",
        align: "center",
        width: "18%",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "location",
        align: "center",
        width: "6%",
        sortable: false,
      },
      {
        text: i18n.tc("btn.btn_achieveAdd"),
        value: "achieveAdd",
        align: "center",
        width: "6%",
        sortable: false,
      },
    ],
    detailDialogData: {},
    inputList: [],
    dummy_arrivalList: [],
    supplierList: [],
    productList: [],
    sortDateMap: {},
    sortNoMap: {},
    // メニュー以外から遷移した場合true
    checkFlg: false,
    getArrivalList: "",
    productHintArrival: "",
    // 検索時の入荷元
    arrivalSelectedSearchCondition: "",
    // 検索時の入荷元リスト
    arrivalListSearchCondition: [],
    isDisableDownloadBtn: false,
  }),

  created() {
    if (
      this.$route.params.searchFilter &&
      Object.keys(this.$route.params.searchFilter).length !== 0
    ) {
      // 画面遷移前の検索条件をセット
      Object.assign(this, this.$route.params.searchFilter);
    }
    this.checkFlg = this.$route.params.checkFlg;
    if (this.suppliersSelected) {
      this.productHintArrival = this.$route.params.arrivalNm ? this.$route.params.arrivalNm : "";
      let arrivalList = this.$route.params.arrivalList;
      this.arrivalSelectedSearchCondition = this.arrivalSelected;
      this.arrivalListSearchCondition = arrivalList;
      this.arrivalList = arrivalList ? arrivalList : [];
      this.targetData = this.$route.params.targetData;
      this.getList(true);
    } else {
      this.suppliersSelected = "";
    }
  },
  methods: {
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.getList(false);
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getList(false);
    },
    // 初期化
    async init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      if (this.suppliersSelected) {
        // console.debug("遷移後");
        // 遷移フラグ
        this.transitionFlg = true;
      } else {
        this.arrivalSelected = "";
        this.suppliersSelected = "";
        this.txt_arrivalScheduleNo = "";
        this.arrivalScheduleDate = "";
        this.txt_slipNo = "";
        this.txt_groupName = "";
        this.deliveryDivSelected = "";
        this.partsRequesterSelected = "";
        this.totalCount = 0;
        this.inputList = [];
        this.targetData = [];
        this.isAllSelected = false;
        this.arrAchieveDateFrom = dateTimeHelper.convertJST();
        this.arrAchieveDateTo = dateTimeHelper.convertJST();

        this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      }
      this.getSupplier();

      // 実績登録権限を取得
      this.achieveRole = sessionStorage.getItem(appConfig.SCREEN_ID.P_RCV_103);

      this.setAllCheck();
    },
    // 入荷実績日付カレンダーFrom
    // 入荷実績日付をー１日
    preDateOfAchieveFrom() {
      if (this.arrAchieveDateFromCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateFromCal);
      this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    nextDateOfAchieveFrom() {
      if (this.arrAchieveDateFromCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateFromCal);
      this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    // 入荷実績日付カレンダーto
    preDateOfAchieveTo() {
      if (this.arrAchieveDateToCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateToCal);
      this.arrAchieveDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    nextDateOfAchieveTo() {
      if (this.arrAchieveDateToCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateToCal);
      this.arrAchieveDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    //作成日Fromに-1日する。
    ctprevDate() {
      this.createDateFromCal = commonFunction.addDate(this.createDateFromCal, "");
    },
    //作成日Fromに+1日する。
    ctnextDate() {
      this.createDateFromCal = commonFunction.addDate(this.createDateFromCal, "add");
    },
    //作成日Toに-1日する。
    ctprevToDate() {
      this.createDateToCal = commonFunction.addDate(this.createDateToCal, "");
    },
    //作成日Toに+1日する。
    ctnextToDate() {
      this.createDateToCal = commonFunction.addDate(this.createDateToCal, "add");
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * 進捗情報詳細画面を開きます。
     */
    openDetailDialog(dummy_input, targetSupplierCode, targetArrivalScheduleNo) {
      // 詳細画面へ渡す値を設定します。
      this.detailDialogData = dummy_input;
      // 選択された列名
      this.detailDialogData.suppliersSelected = targetSupplierCode;
      this.detailDialogData.txt_arrivalScheduleNo = targetArrivalScheduleNo;
      this.isOpenDetailDialog = true;
    },
    // 日付入力
    changeDate() {
      if (this.arrivalScheduleDate.length == 8) {
        var dt = this.arrivalScheduleDate;
        this.arrivalScheduleDate = dt.substr(0, 4) + "/" + dt.substr(4, 2) + "/" + dt.substr(6, 2);
      }
      dateTimeHelper.validDate(this.arrivalScheduleDate);
    },
    //from日付
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.arrAchieveDateFromCal = null;
      }
    },
    //to日付
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.arrAchieveDateToCal = null;
      }
    },

    changecreateDateFrom(val) {
      this.createDateFromCal = commonFunction.directInput(val);
    },

    changecreateDateTo(val) {
      this.createDateToCal = commonFunction.directInput(val);
    },

    /**
     * 入荷予定一覧画面：入荷予定修正・削除画面を開きます。
     */
    openUpdateDelete(item) {
      // 選択した行のアイテム
      this.editedIndex = this.inputList.indexOf(item);
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      // Fromの日付がtoよりも先だった場合、エラー
      if (this.suppliersSelected == "" || this.suppliersSelected == null) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        return;
      }
      // 検索部エラーチェック
      if (!this.$refs.form.validate()) {
        return;
      }
      if (new Date(this.arrAchieveDateFrom).getTime() > new Date(this.arrAchieveDateTo).getTime()) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else if (
        new Date(this.arrivalScheduleDateFrom).getTime() >
        new Date(this.arrivalScheduleDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else {
        // // 選択した行のアイテム
        this.editedIndex = this.inputList.indexOf(item);
        // 取引先名と取引先コードを取得
        let officeCd;
        for (var i = 0; i < this.dummy_supplierList.length; i++) {
          if (this.dummy_supplierList[i].value == this.inputList[this.editedIndex].clientSid) {
            this.clientNm = this.dummy_supplierList[i].text;
            officeCd = this.dummy_supplierList[i].clientSid;
          }
        }

        // 修正画面へ渡す値を設定します。
        this.$router.push({
          name: appConfig.SCREEN_ID.P_RCV_103,
          params: {
            // 検索領域
            // 取引先SID(API登録用)
            suppliersNm: this.inputList[this.editedIndex].clientSid,
            // 取引先リスト
            supplierList: this.dummy_supplierList,
            // 取引先名とコード合体(画面表示用)
            suppliersSelected: this.clientNm,
            arrivalSelected: this.arrivalSelectedSearchCondition,
            // 一覧領域
            //SIDを入れる
            arrSid: this.inputList[this.editedIndex].location, // 入荷伝票SID
            fromSid: this.inputList[this.editedIndex].fromSid, // 入荷元SID（明細）
            isPaid: this.inputList[this.editedIndex].isPaid, // 有償無償区分
            blameDiv: this.inputList[this.editedIndex].blameDiv, // 責任区分
            processDivName: this.inputList[this.editedIndex].processDivName, // 処理区分名
            arrivalScheduleDate: this.inputList[this.editedIndex].arrivalScheduleDate, // 入荷予定日
            // arrivalScheduleDate: this.inputList[this.editedIndex].rcvDate, // 入荷予定日（明細）
            groupName: this.inputList[this.editedIndex].groupName, // グループ名
            deliveryDivSelected: this.inputList[this.editedIndex].deliveryDivSelected, // 納品種別
            partsRequesterSelected: this.inputList[this.editedIndex].partsRequesterSelected, // 部品要求者
            reasonCdName: this.inputList[this.editedIndex].reasonCdName, // 無償理由
            inListNo: this.inputList[this.editedIndex].inListNo, // 入荷No（明細）
            arrivalto: this.inputList[this.editedIndex].arrivalto,
            processDivSelected: this.inputList[this.editedIndex].processingdivision,
            // 画面遷移判断フラグ
            checkFlg: this.checkFlg,
            // 入荷元名
            arrivalNm:
              this.inputList[this.editedIndex].fromBaseCd +
              "（" +
              this.inputList[this.editedIndex].arrivalto +
              "）",
            // 入荷元名(省略)
            arrivalOmitNm:
              commonUtil.omit(
                this.inputList[this.editedIndex].fromBaseCd,
                appConfig.CONSTANT.BASE_CD_DISPLAY_LENGTH
              ) +
              "（" +
              this.inputList[this.editedIndex].arrivalto +
              "）",
            // 入荷元一覧
            arrivalList: this.arrivalListSearchCondition,
            // 入荷予定一覧検索条件
            searchFilter: this.searchFilter,
            // 入荷元BaseCd
            baseCd: this.inputList[this.editedIndex].fromBaseCd,
            officeCd: officeCd,
            // 予定リスト出力対象のSIDリスト
            targetData: this.targetData,
          },
        });
      }
    },

    /**
     * 入荷実績登録画面に遷移します。
     */
    openAchieveAdd(item) {
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      // Fromの日付がtoよりも先だった場合、エラー
      if (this.suppliersSelected == "" || this.suppliersSelected == null) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        return;
      }
      // 検索部エラーチェック
      if (!this.$refs.form.validate()) {
        return;
      }
      if (new Date(this.arrAchieveDateFrom).getTime() > new Date(this.arrAchieveDateTo).getTime()) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else if (
        new Date(this.arrivalScheduleDateFrom).getTime() >
        new Date(this.arrivalScheduleDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else {
        // 選択した行のアイテム
        this.editedIndex = this.inputList.indexOf(item);

        // 取引先名と取引先コードを取得
        for (var i = 0; i < this.dummy_supplierList.length; i++) {
          if (this.dummy_supplierList[i].value == this.inputList[this.editedIndex].clientSid) {
            this.clientNm = this.dummy_supplierList[i].text;
          }
        }
        // this.clientNm = this.suppliersSelected;

        // 入荷元名と入荷元コードを取得
        for (var j = 0; j < this.arrivalList.length; j++) {
          if (this.arrivalList[j].value == this.inputList[this.editedIndex].fromSid) {
            this.arrivalNm = this.arrivalList[j].text;
          }
        }

        // 入荷実績登録画面へ渡す値を設定します。
        this.$router.push({
          name: appConfig.SCREEN_ID.P_RCV_111,
          params: {
            // 検索領域
            // 取引先SID(API登録用)
            suppliersNm: this.inputList[this.editedIndex].clientSid,
            // 取引先名とコード合体(画面表示用)
            suppliersSelected: this.clientNm,
            // 入荷元名（省略）
            arrivalSelected:
              commonUtil.omit(
                this.inputList[this.editedIndex].fromBaseCd,
                appConfig.CONSTANT.BASE_CD_DISPLAY_LENGTH
              ) +
              "（" +
              this.inputList[this.editedIndex].arrivalto +
              "）",
            arrivalSid: this.arrivalSelectedSearchCondition, // 入荷元SID
            // 一覧領域
            fromSid: this.inputList[this.editedIndex].fromSid, // 入荷元SID（明細）
            arrSid: this.inputList[this.editedIndex].location, // 入荷予定伝票SID（明細）
            arrivalScheduleDate: this.inputList[this.editedIndex].rcvDate, // 入荷予定日（明細）
            processDivSelected: this.inputList[this.editedIndex].processingdivision, // 処理区分(通常/返品)（明細）
            arrivalto: this.inputList[this.editedIndex].arrivalto, // ?
            blameDiv: this.inputList[this.editedIndex].blameDiv, // 責任区分(自責/他責)
            inListNo: this.inputList[this.editedIndex].inListNo, // 入荷No（明細）
            howtoregister: this.inputList[this.editedIndex].entryDiv, // ?
            isPaid: this.inputList[this.editedIndex].isPaid, // 有償無償
            processDivName: this.inputList[this.editedIndex].processDivName, // 処理区分名
            reasonCdName: this.inputList[this.editedIndex].reasonCdName, // 無償理由名
            scheduleUpdateDatetime: this.inputList[this.editedIndex].scheduleUpdateDatetime, // 予定更新日時
            // 画面遷移判断フラグ
            checkFlg: this.checkFlg,
            // 入荷元一覧
            arrivalList: this.arrivalListSearchCondition,
            // 入荷予定一覧検索条件
            searchFilter: this.searchFilter,
            // 予定リスト出力対象のSIDリスト
            targetData: this.targetData,
          },
        });
      }
    },

    //検索ボタン押下時及び他の画面から遷移した場合
    getList(isSearchConditionUpdate = false) {
      // 検索ボタン押下時選択チェック状態をリセットする。
      if (isSearchConditionUpdate && !this.checkFlg) {
        this.targetData = [];
      }

      // 入力チェック結果フラグ
      let inputCheckResult = false;
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      this.alertCreateMessage = "";
      let check = 0;
      // 取引先存在チェック
      if (this.suppliersSelected == "" || this.suppliersSelected == null) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        check++;
      }
      // Fromの日付がtoよりも先だった場合、エラー
      if (new Date(this.arrAchieveDateFrom).getTime() > new Date(this.arrAchieveDateTo).getTime()) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      } else if (
        new Date(this.arrivalScheduleDateFrom).getTime() >
        new Date(this.arrivalScheduleDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }
      // 作成日のFromの日付がtoよりも先だった場合、エラー
      if (this.createDateFrom && this.createDateTo) {
        if (new Date(this.createDateFrom).getTime() > new Date(this.createDateTo).getTime()) {
          this.alertCreateMessage = i18n.tc("check.chk_inputFromVali");
          check++;
        }
      }
      if (check == 0) {
        /**
         * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
         * エラーがでるので回避するためこのようなコードとなっている。
         */
        if (this.$route.params.suppliersSelected && this.checkFlg) {
          // 登録画面からの遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else {
          // 上記外の場合はチェックを行う。
          inputCheckResult = this.$refs.form.validate();
        }
        // 画面遷移フラグをfalseに戻す
        this.checkFlg = false;

        // 入力チェック実施
        if (inputCheckResult) {
          // ローディング画面表示ON
          this.loadingCounter = 1;
          this.$set(this.inputList, "", "");
          // httpClientで使用する共通IFパラメータ等を作成する。
          const config = this.$httpClient.createGetApiRequestConfig();

          // 営業所Sid
          config.params.officeSid = sessionStorage.getItem("sales_office_sid"); //2200000002の固定
          // 取引先Sid
          config.params.clientSid = this.suppliersSelected;
          // 入荷伝票No
          if (this.txt_slipNo) {
            config.params.inListNo = this.txt_slipNo;
          }
          // 品番
          if (this.productCnCdSelected) {
            config.params.itemStandardSid = this.productCnCdSelected;
          }
          // 部品補足情報
          if (this.txt_partsSubInformation) {
            config.params.partsSubInformation = this.txt_partsSubInformation;
          }
          // 入荷予定日From
          config.params.inScheduleDateFrom = dateTimeHelper.convertUTC(this.arrAchieveDateFrom);
          var arr = this.arrAchieveDateTo + " 23:59:59.999";
          // 入荷予定日To
          config.params.inScheduleDateTo = dateTimeHelper.convertUTC(arr);
          // グループ名
          if (this.txt_groupName) {
            config.params.groupName = this.txt_groupName;
          }
          // 納品種別
          if (this.deliveryDivSelected) {
            config.params.deliveryType = this.deliveryDivSelected;
          }
          // 部品要求者
          if (this.partsRequesterSelected) {
            config.params.partsRequester = this.partsRequesterSelected;
          }
          //作成日From
          if (this.createDateFrom) {
            config.params.createDateFrom = dateTimeHelper.convertUTC(this.createDateFrom);
          }
          //作成日To
          if (this.createDateTo) {
            config.params.createDateTo = dateTimeHelper.convertUTC(
              this.createDateTo + " 23:59:59.999"
            );
          }
          // ページャー処理
          if ((this.page > 2 && this.inputList.length == 0) || isSearchConditionUpdate) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
          // ページング
          config.params.reqComPaginationFlg = "1";
          config.params.reqComPageLimit = this.itemsPerPage;
          // ソート
          if (this.sortItem != "") {
            config.params.reqComSortItem = this.sortItem;
          }
          this.arrivalSelectedSearchCondition = this.arrivalSelected;
          this.arrivalListSearchCondition =
            this.arrivalSelected == "" || this.arrivalSelected == null
              ? []
              : new Array(...this.dummy_arrivalList);

          Object.assign(this.searchFilter, {
            suppliersSelected: this.suppliersSelected,
            txt_slipNo: this.txt_slipNo,
            productCnCdSelected: this.productCnCdSelected,
            txt_partsSubInformation: this.txt_partsSubInformation,
            arrAchieveDateFrom: this.arrAchieveDateFrom,
            arrAchieveDateFromCal: this.arrAchieveDateFromCal,
            arrAchieveDateTo: this.arrAchieveDateTo,
            arrAchieveDateToCal: this.arrAchieveDateToCal,
            txt_groupName: this.txt_groupName,
            deliveryDivSelected: this.deliveryDivSelected,
            partsRequesterSelected: this.partsRequesterSelected,
            createDateFrom: this.createDateFrom,
            createDateFromCal: this.createDateFromCal,
            createDateTo: this.createDateTo,
            createDateToCal: this.createDateToCal,
          });

          // 接続先のAPI_URLを入力
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.BIZ_INSCHEDULES_HAC, config)
              .then((response) => {
                this.inputList.splice(0);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                // 正常時
                if (jsonData.resCom.resComCode == "000") {
                  // alert("API接続に成功しました。");
                  this.pageCount = jsonData.resCom.resComPagination.totalPage;
                  this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                  this.arrivalList = jsonData.resIdv.inSchedules;

                  // for(){}繰り返し処理を行い、配列を格納する処理を記述する
                  for (let i = 0; i < this.arrivalList.length; i++) {
                    const checkFlag = this.targetData.includes(this.arrivalList[i].inListSid);
                    let data = {
                      check: checkFlag,
                      shipNo: this.arrivalList[i].inListNo,
                      arrivalScheduleDate: dateTimeHelper
                        .convertUTC2JST(this.arrivalList[i].inScheduleDate)
                        .substr(0, 10),
                      isPaid: this.arrivalList[i].freeCostDiv, //有償無償
                      inStock: this.arrivalList[i].completeFlg, //入荷済み現在null
                      processingdivision: this.arrivalList[i].processDiv, //処理区分
                      processDivName: this.arrivalList[i].processDivName, //処理区分名
                      reasonCdName: this.arrivalList[i].reasonCdName,
                      arrivalto: this.arrivalList[i].fromName, //入荷元
                      fromSid: this.arrivalList[i].fromSid, //入荷元SId
                      fromBaseCd: this.arrivalList[i].fromBaseCd, //入荷元コード
                      entryDiv: this.arrivalList[i].entryDiv, // 登録コード
                      howtoregister: this.arrivalList[i].entryDivName, // 登録方法
                      location: this.arrivalList[i].inListSid, //入荷予定明細Sid
                      suppliersSelected: this.suppliersSelected,
                      blameDiv: this.arrivalList[i].blameDiv,
                      inListNo: this.arrivalList[i].inListNo, //入荷No
                      inListSid: this.arrivalList[i].inListSid,
                      rcvDate: dateTimeHelper.convertUTC2JST(this.arrivalList[i].inScheduleDate), //入荷予定日
                      arrivalSelected: this.arrivalSelected,
                      officeSid: this.arrivalList[i].officeSid,
                      clientSid: this.arrivalList[i].clientSid,
                      scheduleUpdateDatetime: this.arrivalList[i].updateDatetime,
                      freeCostDivName: this.arrivalList[i].freeCostDivName,
                      achieveRole: this.achieveRole,
                      retrieveTime: dateTimeHelper
                        .convertUTC2JST(this.arrivalList[i].createDatetime)
                        .substr(0, 19),
                      productCd: this.arrivalList[i].itemCd,
                      product: this.arrivalList[i].itemName,
                      partsSubInformation: this.arrivalList[i].partsSubInformation,
                    };
                    this.inputList.push(data);

                    // ソート用Map作成
                    this.sortNoMap["shipNo"] = "inListNo";
                    this.sortDateMap["arrivalScheduleDate"] = "inScheduleDate";
                  }
                  // エラー時
                } else {
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  this.infoDialog.firstPageFlag = true;
                }
                this.setAllCheck();
                resolve(response);
              })
              .catch((resolve) => {
                console.error("getList() Resolve", resolve);
                this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;

                reject(resolve);
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        } else {
          // 処理なし
        }
      }
    },
    // 取引先API接続
    getSupplier() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getSupplier() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  clientSid: row.compSid,
                });
                this.dummy_supplierList = list;
              });
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getSupplier() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          })
          .then(() => {
            //if (this.$route.params.suppliersSelected) {
            //  this.getArrivalList();
            //}
          });
      });
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 画面遷移後の場合
      if (this.transitionFlg) {
        this.suppliersSelected = "";
        this.productCnCdSelected = "";
        this.arrivalStatusSelected = "";
        this.txt_slipNo = "";
        (this.txt_groupName = ""), (this.deliveryDivSelected = "");
        this.partsRequesterSelected = "";
        this.createDateFrom = "";
        this.createDateTo = "";
        this.createDateFromCal = "";
        this.createDateToCal = "";
        // 遷移フラグ
        this.transitionFlg = false;
      }
      // 変更検知用の設定
      this.arrAchieveDateFromCal = "";
      this.arrAchieveDateToCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
    /**
     * 画面入荷予定リスト出力ボタン押下時の処理
     */
    async downloadList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();
      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_102;
      // 取引先SID
      body.reqIdv.clientSid = this.suppliersSelected;
      // 入荷伝票Sid
      body.reqIdv.inListSids = this.targetData;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_IN_SCHEDULE_HAC_LIST, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // ファイル名
              this.pdfUrl = jsonData.resIdv.filePath;
              // ファイルダウンロード
              location.href = this.pdfUrl;

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = messsageUtil.getMessage(jsonData.resCom.resComMessageId, [
                i18n.tc("label.lbl_upload"),
              ]);
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        // this.listCheckbox = [];
        // this.listCheckbox = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
          const flag = this.targetData.includes(this.inputList[i].inListSid);
          if (!flag) {
            this.targetData.push(this.inputList[i].inListSid);
          }
        }
        this.isAllSelected = true;
        // this.isDisableDownloadBtn = false;
      } else {
        // this.listCheckbox = [];
        // this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;
          const idx = this.targetData.indexOf(this.inputList[j].inListSid);
          this.targetData.splice(idx, 1);
        }
        this.isAllSelected = false;
        // this.isDisableDownloadBtn = true;
      }
    },
    /**
     * 一覧チェックボックス処理
     */
    selectCheck(index) {
      this.inputList[index].check = !this.inputList[index].check;
      if (this.inputList[index].check) {
        this.targetData.push(this.inputList[index].inListSid);
      } else {
        const idx = this.targetData.indexOf(this.inputList[index].inListSid);
        this.targetData.splice(idx, 1);
      }
      this.setAllCheck();
    },
    setAllCheck() {
      if (!this.inputList.length) {
        this.isAllSelected = false;
        return;
      }
      this.isAllSelected = true;
      this.inputList.forEach((value, index) => {
        if (!this.inputList[index].check) {
          this.isAllSelected = false;
          return;
        }
      });
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },

    dialog(val) {
      val || this.close();
    },
    arrivalScheduleDateCal(val) {
      this.arrivalScheduleDate = this.formatDate(val);
    },
    arrAchieveDateFromCal: {
      handler(val) {
        this.arrAchieveDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    arrAchieveDateToCal: {
      handler(val) {
        this.arrAchieveDateTo = this.formatDate(val);
      },
      deep: true,
    },
    createDateFromCal(val) {
      this.createDateFrom = this.formatDate(val);
    },
    createDateToCal(val) {
      this.createDateTo = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getList(false);
    },
    suppliersSelected(newValue, oldValue) {
      // 入荷元リストクリア
      if (oldValue != null && oldValue != "") {
        this.arrivalList = [];
        this.arrivalSelected = "";
      }
      if (this.$route.params.suppliersSelected) {
        // 遷移後
      } else {
        // 入荷元を取得する処理
        // this.getArrivalList();
        // this.alertSupplierMessage = "";
      }
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_RCV_003_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getList(false);
        }
      },
      deep: true,
    },

    // 商品の有効日時バリデーション
    inputProductValid(item) {
      if (item.productList && item.productList.length > 0) {
        const product = item.productList.find((v) => v.value == item.productCd);
        if (product && this.commonInput.arrivalScheduleDate) {
          const arrivalScheduleDate = new Date(this.commonInput.arrivalScheduleDate);
          const validFrom = new Date(dateTimeHelper.convertUTC2JST(product.validFrom));
          const validTo = new Date(dateTimeHelper.convertUTC2JST(product.validTo));
          // 仮登録商品に対するチェック対象から除外
          if (product.validTo == null) {
            return true;
          }
          // 有効日時が期限内の場合、エラー対象外
          if (validFrom <= arrivalScheduleDate && arrivalScheduleDate <= validTo) {
            return true;
          } else {
            return i18n.tc("check.chk_inputProductFromToValidate");
          }
        }
      }
      return true;
    },
    targetData: {
      handler(newValue) {
        if (newValue.length) {
          this.isDisableDownloadBtn = false;
        } else {
          this.isDisableDownloadBtn = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}
.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
.link {
  text-decoration: none;
}
</style>
