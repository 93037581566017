<template>
  <div :style="width ? { width: width } : {}">
    <Loading :loadingCounter="loadingCounter" />
    <v-tooltip bottom :disabled="!isTooltipFlg">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex" v-bind="attrs" v-on="on">
          <v-autocomplete
            ref="textElement"
            class="txt-single"
            v-model="selectedItem"
            :items="internalItems"
            :search-input.sync="search"
            :label="$t('label.lbl_arrival')"
            :hint="commonUtil.findPropertyByValue(internalItems, selectedItem)"
            :readonly="readonly"
            :disabled="disabled"
            :append-icon="'$dropdown'"
            :rules="required ? [vRules.inputRequired, ...rules] : [...rules]"
            item-value="value"
            item-text="text"
            persistent-hint
            dense
            pa-3
            @blur="onBlur"
          >
            <template slot="item" slot-scope="data">
              <span class="test">
                {{ data.item.text }}
              </span>
            </template>
          </v-autocomplete>
          <span class="require asterisk-spacer" v-if="required">*</span>
        </div>
      </template>
      <span
        >{{ commonUtil.findPropertyByValue(internalItems, selectedItem, "value", "text") }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import { i18n } from "@/lang/lang.js";
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { getParameter } from "@/assets/scripts/js/GetParameter";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import Loading from "@/components/loading";

export default {
  inheritAttrs: false,
  components: {
    Loading,
  },
  props: {
    // 値
    value: {
      type: [String, Number],
      default: "",
    },
    // 取引先SID
    clientSid: {
      required: true,
      validator(value) {
        return typeof value === "string" || value === null;
      },
      default: null,
    },
    // 必須
    required: {
      type: Boolean,
      default: false,
    },
    // 読み取り専用
    readonly: {
      type: Boolean,
      default: false,
    },
    // 無効化
    disabled: {
      type: Boolean,
      default: false,
    },
    // 幅
    width: {
      type: String,
      default: undefined,
    },
    // バリデーション
    rules: {
      type: Array,
      default: () => [],
    },
  },
  inject: ["errorMessage"],
  data: () => ({
    // ローディングフラグ
    loadingCounter: 0,
    // 選択値
    selectedItem: "",
    // リスト
    internalItems: [],
    // ツールチップフラグ
    isTooltipFlg: false,
    search: "",
    // 共通機能
    commonUtil: {
      findPropertyByValue(list, targetValue, keyToCompare = "value", keyToReturn = "name") {
        return list.find((item) => item[keyToCompare] === targetValue)?.[keyToReturn] || "";
      },
    },
    // バリデーション
    vRules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
    },
  }),
  methods: {
    // 初期処理
    init() {
      // ローディング画面表示ON
      this.loadingCounter++;
      // 入荷元
      const arrivalList = getParameter.getRelatedBizPartialMatch(
        appConfig.ISGETCLIENT.FROMTO,
        dateTimeHelper.convertUTC(),
        undefined,
        this.clientSid,
        null
      );
      Promise.all([arrivalList])
        .then((result) => {
          // リスト設定
          this.internalItems = result[0];
          // 初期値設定
          this.selectedItem = this.value;
          // 選択値が存在しないかつ、リストが1件の場合、1行を選択
          if (!this.selectedItem && this.internalItems.length == 1) {
            this.selectedItem = this.internalItems[0].value;
          }
        })
        .catch((ex) => {
          if (!this.errorMessage.isError) {
            this.errorMessage.isError = true;
            this.errorMessage.message = ex;
          }
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter--;
        });
    },
    // フォーカスが離れた際
    onBlur() {
      if (this.search.length < appConfig.CNTCHARITEM || this.internalItems.length == 0) {
        this.selectedItem = "";
        this.internalItems = [];
      }
    },
  },
  watch: {
    value(newValue) {
      this.selectedItem = newValue;
      if (!newValue) {
        this.internalItems = [];
      } else {
        this.internalItems = this.internalItems.filter((item) => item.value == newValue);
      }
      this.$nextTick(() => {
        setTimeout(() => {
          // 選択値が存在しないかつ、リストが1件の場合、1行を選択
          if (!this.selectedItem && this.internalItems.length == 1) {
            this.selectedItem = this.internalItems[0].value;
          }
          // 入力幅を超えたらツールチップを表示
          const textElement = this.$refs.textElement.$el.querySelector(".v-input__control input");
          this.isTooltipFlg = textElement.clientWidth < textElement.scrollWidth;
        }, 0);
      });
    },
    selectedItem(newValue) {
      this.$emit("input", newValue);
    },
    internalItems(newValue) {
      this.$emit("getList", newValue);
    },
    search(newValue) {
      // 取引先SIDが存在かつ、検索内容が存在する場合
      if (this.clientSid && typeof newValue === "string") {
        if (newValue.trim().length == 0) {
          this.selectedItem = "";
          this.internalItems = [];
        } else if (newValue.length > 0) {
          // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
          if (
            newValue ==
            this.commonUtil.findPropertyByValue(
              this.internalItems,
              this.selectedItem,
              "value",
              "text"
            )
          ) {
            return;
          }
          // 入力桁数が定義した数以上の場合検索処理
          if (newValue.length >= appConfig.CNTCHARITEM) {
            // ローディング画面表示ON
            this.loadingCounter++;

            const arrivalList = getParameter.getRelatedBizPartialMatch(
              appConfig.ISGETCLIENT.FROMTO,
              dateTimeHelper.convertUTC(),
              undefined,
              this.clientSid,
              newValue
            );

            Promise.all([arrivalList])
              .then((result) => {
                // リスト設定
                this.internalItems = result[0];
                // 初期値設定
                this.selectedItem = this.value;
                // 選択値が存在しないかつ、リストが1件の場合、1行を選択
                if (!this.selectedItem && this.internalItems.length == 1) {
                  this.selectedItem = this.internalItems[0].value;
                }
              })
              .catch((ex) => {
                if (!this.errorMessage.isError) {
                  this.errorMessage.isError = true;
                  this.errorMessage.message = ex;
                }
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter--;
              });
          } else {
            this.internalItems = [];
          }
        }
      }
    },
  },
  computed: {},
  created() {
    this.init();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
</style>
