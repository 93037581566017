<template>
  <div :style="width ? { width: width } : {}">
    <v-tooltip bottom :disabled="!isTooltipFlg">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex" v-bind="attrs" v-on="on">
          <v-autocomplete
            ref="textElement"
            v-model="selectedItem"
            :items="internalItems"
            :search-input.sync="search"
            :hint="commonUtil.findPropertyByValue(internalItems, selectedItem)"
            :label="$t('label.lbl_shippingTo')"
            :readonly="readonly"
            :disabled="disabled"
            :append-icon="'$dropdown'"
            :rules="required ? [vRules.inputRequired, ...rules] : [...rules]"
            item-value="value"
            item-text="text"
            persistent-hint
            dense
            @blur="onBlur"
          >
            <template slot="item" slot-scope="data">
              <span class="auto-txt-size"> {{ data.item.text }} </span></template
            >
          </v-autocomplete>
          <span class="require asterisk-spacer" v-if="required">*</span>
        </div>
      </template>
      <span
        >{{ commonUtil.findPropertyByValue(internalItems, selectedItem, "value", "text") }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import { i18n } from "@/lang/lang.js";
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { getParameter } from "@/assets/scripts/js/GetParameter";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";

export default {
  inheritAttrs: false,
  components: {},
  props: {
    // 値
    value: {
      type: [String, Number],
      default: "",
    },
    // 取引先SID
    clientSid: {
      required: true,
      validator(value) {
        return typeof value === "string" || value === null;
      },
      default: null,
    },
    // 取引先SID（出荷先用）
    shipToClientSid: {
      required: true,
      validator(value) {
        return typeof value === "string" || value === null;
      },
      default: null,
    },
    // 必須
    required: {
      type: Boolean,
      default: false,
    },
    // 読み取り専用
    readonly: {
      type: Boolean,
      default: false,
    },
    // 無効化
    disabled: {
      type: Boolean,
      default: false,
    },
    // 幅
    width: {
      type: String,
      default: undefined,
    },
    // バリデーション
    rules: {
      type: Array,
      default: () => [],
    },
  },
  inject: ["errorMessage", "loadingSpinner", "commonInputMixin"],
  data: () => ({
    // 選択値
    selectedItem: "",
    // リスト
    internalItems: [],
    // ツールチップフラグ
    isTooltipFlg: false,
    // 検索
    search: "",
    // 共通機能
    commonUtil: {
      findPropertyByValue(list, targetValue, keyToCompare = "value", keyToReturn = "name") {
        return list.find((item) => item[keyToCompare] === targetValue)?.[keyToReturn] || "";
      },
    },
    // バリデーション
    vRules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
    },
  }),
  methods: {
    // 初期処理
    init() {
      // 初期値が存在する場合
      if (this.value) {
        // 初期値設定
        this.selectedItem = this.value;
        // 出荷先検索
        this.searchItems({
          searchKbn: "1",
          belongSid: this.value,
          referenceDate: dateTimeHelper.convertUTC(),
        });
      } else {
        // ローディング画面表示ON
        this.loadingSpinner.counter++;
        // PC画面制御
        const functionPermissions = getParameter.getFunctionPermissions({
          conditions: [
            {
              clientSid: this.clientSid,
              attachResourceKey: appConfig.SCREEN_ID.P_EXT_101,
              item01: "DefaultshippingTo",
            },
          ],
        });
        Promise.all([functionPermissions])
          .then((result) => {
            if (result[0][0]?.workflowMatterId) {
              // 出荷先検索
              this.searchItems({
                isGetClient: appConfig.ISGETCLIENT.FROMTO,
                compSid: this.shipToClientSid,
                relatedPartySid: result[0][0]?.workflowMatterId,
              }).then(() => {
                this.selectedItem = result[0][0]?.workflowMatterId;
              });
            }
          })
          .catch((ex) => {
            if (!this.errorMessage.isError) {
              this.errorMessage.isError = true;
              this.errorMessage.message = ex;
            }
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingSpinner.counter--;
          });
      }
    },
    // 出荷先検索
    searchItems(params) {
      // ローディング画面表示ON
      this.loadingSpinner.counter++;

      // 出荷先
      const shipToList = getParameter.getRelatedBizPartialMatchMst(params);
      return Promise.all([shipToList])
        .then((result) => {
          // リスト設定
          this.internalItems = result[0];
        })
        .catch((ex) => {
          if (!this.errorMessage.isError) {
            this.errorMessage.isError = true;
            this.errorMessage.message = ex;
          }
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingSpinner.counter--;
        });
    },
    // フォーカスが離れた際
    onBlur() {
      if (this.search.length < appConfig.CNTCHARITEM_2 || this.internalItems.length == 0) {
        this.selectedItem = "";
        this.internalItems = [];
      }
    },
  },
  watch: {
    value(newValue) {
      this.selectedItem = newValue;
      this.internalItems = this.internalItems.filter((item) => item.value == newValue);
      this.commonInputMixin.shipTo.carrierCompCd = this.internalItems[0]?.carrierCompCd ?? "";
      this.$nextTick(() => {
        setTimeout(() => {
          // 入力幅を超えたらツールチップを表示
          const textElement = this.$refs.textElement.$el.querySelector(".v-input__control input");
          this.isTooltipFlg = textElement.clientWidth < textElement.scrollWidth;
        }, 0);
      });
    },
    selectedItem(newValue) {
      this.$emit("input", newValue);
    },
    search(newValue) {
      // 検索内容が存在する場合
      if (typeof newValue === "string") {
        if (newValue.trim().length == 0) {
          this.selectedItem = "";
          this.internalItems = [];
        } else if (newValue.length > 0) {
          // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
          if (
            newValue ==
            this.commonUtil.findPropertyByValue(
              this.internalItems,
              this.selectedItem,
              "value",
              "text"
            )
          ) {
            return;
          }
          // 入力桁数が定義した数以上の場合検索処理
          if (newValue.length >= appConfig.CNTCHARITEM_2) {
            // 出荷先検索
            this.searchItems({
              isGetClient: appConfig.ISGETCLIENT.FROMTO,
              compSid: this.shipToClientSid,
              baseCd: newValue,
            });
          } else {
            this.internalItems = [];
          }
        }
      }
    },
  },
  computed: {},
  created() {
    this.init();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
</style>
