<template>
  <div style="background: white">
    <!-- ローディング画面です。 -->
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <!-- 画面名です。 -->
      <!-- 新規登録時：displayFlg==1 -->
      <NavBar v-if="displayFlg == '1'" tittle="label.lbl_userAdd" />
      <!-- 修正時：displayFlg == 2 -->
      <NavBar v-if="displayFlg == '2'" tittle="label.lbl_userUpdate" />
      <!-- 入力フォームです（バリデーションあり）。 -->
      <v-form ref="form" v-model="isValid" lazy-validation style="width: 50%; float: left">
        <v-container fluid>
          <v-col cols="4" />
          <!-- 登録画面 -->
          <v-row v-if="displayFlg == '1'" class="search-row">
            <!-- 社員コード -->
            <div class="search-autocomplete first-search-item">
              <!-- 入力必須です。 -->
              <v-text-field
                outlined
                dense
                v-model="employeeCode"
                :label="$t('label.lbl_employeeCode')"
                class="txt-single"
                :rules="[rules.inputRequired]"
                maxlength="20"
                clear-icon="mdi-close-circle"
                clearable
              >
              </v-text-field>
            </div>
            <!-- 必須マークです。 -->
            <span class="require asterisk-spacer">*</span>
          </v-row>
          <!-- 修正画面 -->
          <v-row v-if="displayFlg == '2'" class="search-row">
            <!-- 社員コード -->
            <div class="search-autocomplete first-search-item">
              <!-- 読み取り専用です。 -->
              <!-- 入力必須です。 -->
              <v-text-field
                dense
                readonly
                v-model="employeeCode"
                :label="$t('label.lbl_employeeCode')"
                class="txt-single"
                @click="getPassword()"
                persistent-hint
                margin-bottom="0"
                pa-3
                :rules="[rules.inputRequired]"
              >
              </v-text-field>
            </div>
            <!-- 必須マークです。 -->
            <span class="require asterisk-spacer">*</span>
          </v-row>
          <v-col cols="4" />
          <v-row class="search-row">
            <!-- ユーザー(姓) -->
            <div class="add-textbox-user">
              <v-text-field
                outlined
                dense
                v-model="lastName"
                :rules="[rules.inputRequired, rules.limitNumUser(lastName, firstName)]"
                :label="$t('label.lbl_lastName')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 必須マークです。 -->
            <span class="require asterisk-spacer">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- ユーザー(名) -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="firstName"
                :rules="[rules.inputRequired]"
                :label="$t('label.lbl_firstName')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 必須マークです。 -->
            <span class="require asterisk-spacer">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- ユーザー(略称) -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="shortName"
                :rules="[rules.inputRequired]"
                maxlength="50"
                :label="$t('label.lbl_shortName')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 必須マークです。 -->
            <span class="require asterisk-spacer">*</span>
          </v-row>
          <v-col cols="4" />
          <v-row class="search-row">
            <!-- ユーザー(姓)（カナ） -->
            <div class="add-textbox-user">
              <v-text-field
                outlined
                dense
                v-model="lastNameKana"
                :rules="[
                  rules.inputRequired,
                  rules.limitNumUserKana(lastNameKana, firstNameKana),
                  rules.formatKana,
                ]"
                :label="$t('label.lbl_lastNameKana')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 必須マークです。 -->
            <span class="require asterisk-spacer">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- ユーザー(名)（カナ） -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="firstNameKana"
                :rules="[rules.inputRequired, rules.formatKana]"
                :label="$t('label.lbl_firstNameKana')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 必須マークです。 -->
            <span class="require asterisk-spacer">*</span>
          </v-row>
          <v-col cols="4" />
          <v-row class="search-row">
            <!-- 登録画面 -->
            <!-- 営業所 -->
            <div v-if="displayFlg == '1'" class="search-autocomplete first-search-item">
              <v-text-field
                dense
                readonly
                v-model="officeName"
                :label="$t('label.lbl_salesOffice')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-text-field>
            </div>
            <!-- 修正画面 -->
            <!-- 営業所 -->
            <div v-if="displayFlg == '2'" class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="officeSelected"
                :items="officeList"
                :label="$t('label.lbl_salesOffice')"
                persistent-hint
                :rules="[rules.inputRequired]"
                :hint="setOffice()"
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 職種 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="jobTypeSelected"
                :items="jobTypeList"
                :label="$t('label.lbl_jobType')"
                :hint="setJobType()"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 権限種別 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="authorityTypeSelected"
                :items="authorityTypeList"
                :label="$t('label.lbl_authorityType')"
                @change="changeAuthorityType"
                persistent-hint
                :hint="setAuthority()"
                :rules="[rules.inputRequired]"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require">*</span>
          </v-row>
          <v-col cols="4" />
          <!-- HAC 所属(表示についてはPC制御マスタ使用が必要) -->
          <v-row class="search-row" v-if="partsRequesterAffiliationFlg">
            <c-belong-input
              v-model="commonForm.belongSelected"
              width="34.5rem"
              required
              class="first-search-item"
            />
          </v-row>
          <v-col cols="4" v-if="partsRequesterAffiliationFlg" />
          <!-- HACモック対応 部品要求者所属 -->
          <v-row class="search-row">
            <!-- メールアドレス -->
            <div class="add-textbox-mail">
              <v-text-field
                outlined
                dense
                v-model="emailAddress"
                maxlength="256"
                :label="$t('label.lbl_mailAddress')"
                :rules="[rules.formatMail]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
          </v-row>
          <v-col cols="4" />
          <!-- 登録画面 -->
          <!-- 有効開始日時-->
          <v-row v-if="displayFlg == '1'" class="add-textbox-date">
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="validFrom"
                class="txt-single date-style"
                :label="$t('label.lbl_validStartDateAndTime')"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="validFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="validFromCal" @input="validFromMenu = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <!-- 修正画面 -->
          <!-- 有効開始日時-->
          <v-row v-if="displayFlg == '2'" class="add-textbox-date">
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="validFrom"
                class="txt-single date-style"
                :label="$t('label.lbl_validStartDateAndTime')"
                @change="changeDateFrom"
                clear-icon="mdi-close-circle"
                :disabled="false"
                :readonly="true"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="validFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
                :disabled="true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" :disabled="true" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="validFromCal" @input="validFromMenu = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                :disabled="true"
                @click="prevDate"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                :disabled="true"
                @click="nextDate"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダーの間 -->
            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 有効終了日時 -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="validTo"
                class="txt-single date-style"
                :label="$t('label.lbl_validEndDateAndTime')"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="validToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="validToCal" @input="validToMenu = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-col cols="4" />
          <!-- 修正画面 -->
          <v-row class="search-row" v-if="displayFlg == '2'">
            <!-- 新パスワードです。 -->
            <div class="search-autocomplete first-search-item" style="width: 14rem">
              <!-- 入力必須です。 -->
              <!-- パスワード桁数です。 -->
              <v-text-field
                dense
                v-model="newPassword"
                :label="$t('label.lbl_new_password')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
                @input="passwordinput"
                :error-messages="passworderrorMsg"
                :rules="[rules.password]"
              >
              </v-text-field>
            </div>
            <span class="item-spacer">&nbsp;</span>
            <!-- パスワード生成です。 -->
            <div class="btn-search-area" style="float: left">
              <v-btn color="primary" id="get-search" @click="isPasswordGeneration">{{
                $t("label.lbl_password_generation")
              }}</v-btn>
            </div>
            <span class="item-spacer">&nbsp;</span>
            <div class="btn-search-area" style="float: left">
              <v-btn color="primary" id="get-search" @click="isPasswordInitialize">{{
                $t("label.lbl_password_initialize")
              }}</v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- パスワード有効期限日時です。 -->
            <!-- 読み取り専用です。 -->
            <!-- 入力必須です。 -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="passwordExpirationDatetime"
                class="txt-single date-style"
                :label="$t('label.lbl_password_expiration_datetime')"
                clear-icon="mdi-close-circle"
                :disabled="false"
                :readonly="true"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="validFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
                :disabled="true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" :disabled="true" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="validFromCal" @input="validFromMenu = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                :disabled="true"
                @click="prevDate"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                :disabled="true"
                @click="nextDate"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <!-- 修正画面 -->
          <v-row v-if="displayFlg == '2'">
            <div class="first-search-item text-label" style="float: left">
              <b style="font-size: 0.9rem">※新パスワードは{{ $t("check.chk_pass2") }}</b>
            </div>
          </v-row>
          <v-col cols="4" />
          <!-- 修正画面 -->
          <v-col cols="4" v-if="displayFlg == '2'" />
          <v-row>
            <div class="search-row-exeBtn">
              <!-- ボタン領域です。 -->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn color="primary" id="get-search" class="other-btn" @click="backPage">{{
                  $t("btn.btn_back")
                }}</v-btn>
              </div>
              <div class="btn-search-area" style="float: left">
                <!-- 修正ボタン -->
                <v-btn
                  v-if="displayFlg == 2"
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="sendUser"
                  >{{ $t("btn.btn_fix") }}</v-btn
                >
                <!-- 登録ボタン -->
                <v-btn
                  v-if="displayFlg == 1"
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="sendUser"
                  >{{ $t("btn.btn_insert") }}</v-btn
                >
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <v-form ref="form2" v-model="isValid" lazy-validation style="width: 50%; float: left">
        <v-container fluid v-if="UserManagerWarehouseFlg">
          <v-col cols="4" />
          <v-row class="search-row" style="margin-bottom: 10px">
            <div class="search-row-exeBtn">
              <!-- ボタン領域です。 -->
              <div class="btn-search-area" style="float: right">
                <!-- 追加ボタン -->
                <v-btn color="primary" id="get-search" class="other-btn" @click="add">{{
                  $t("btn.btn_rowsAdd")
                }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :ripple="false"
            disable-filtering
            disable-pagination
            :hide-default-footer="true"
            height="535px"
          >
            <!-- 取引先 -->
            <template v-slot:[`item.supplier`]="{ item, index }">
              <v-autocomplete
                v-model="item.supplier"
                :items="supplierList"
                :rules="[
                  rules.customerWhCheck(item.supplier, inputList, index, item.warehouse),
                  rules.inputRequiredList(item.supplier, item.warehouse, item.manager),
                ]"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </template>
            <!-- 倉庫 -->
            <template v-slot:[`item.warehouse`]="{ item, index }">
              <v-autocomplete
                v-model="item.warehouse"
                :items="warehouseList"
                :rules="[
                  rules.customerWhCheck(item.warehouse, inputList, index, item.supplier),
                  rules.inputRequiredList(item.warehouse, item.supplier, item.manager),
                ]"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </template>
            <!-- 担当 -->
            <template v-slot:[`item.manager`]="{ item }">
              <v-autocomplete
                v-model="item.manager"
                :items="managerList"
                :rules="[rules.inputRequiredList(item.manager, item.warehouse, item.supplier)]"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </template>
            <!-- 削除-->
            <template v-slot:[`item.delete`]="{ item }">
              <v-btn small @click="deleteItem(item)" text :disabled="item.disableEdit">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-form>

      <!-- ナビゲーションドロワーです。 -->
      <!-- 開閉状態バインディング(openMenu)です。 -->
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <!-- サイドメニューです。 -->
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- メッセージダイアログモーダルです。 -->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :addMoveListPageButtonText="infoDialog.addMoveListPageButtonText"
        :addModeListPage="infoDialog.addModeListPage"
        :addModeListPageParams="infoDialog.addModeListPageParams"
        :addMovePageButtonText="infoDialog.addMovePageButtonText"
        :addModePage="infoDialog.addModePage"
        :addModePageParams="infoDialog.addModePageParams"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />

      <!-- 登録確認メッセージダイアログです。 -->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="addUpdateUser"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
      />

      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- エラーメッセージ -->
      <v-dialog v-model="errDialog" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>{{
            $t("label.lbl_alert")
          }}</v-card-title>
          <v-card-text class="pa-4">
            <p>{{ errMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="errOkClicked"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- プルダウンを押下時出現ダイアログ -->
      <v-dialog v-model="changeDialog" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>{{
            $t("label.lbl_alert")
          }}</v-card-title>
          <v-card-text class="pa-4">
            <p>{{ changeMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="changeOk(changeStatus)"> OK </v-btn>
            <v-btn @click="changeNg(changeStatus)"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import NavBar from "../../components/NavBar.vue"; // ナビゲーションバー
import Loading from "@/components/loading"; // ローディング画面
import sideMenu from "@/components/Menu"; // サイドメニュー
import { i18n } from "../../lang/lang.js";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { passwordUtil } from "../../assets/scripts/js/PasswordUtil";
import { dateUtil } from "../../assets/scripts/js/DateUtil";
import { cognito } from "../../assets/scripts/js/Cognito";
import ConfirmDialog from "../../components/ConfirmDialog";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
  },
  mixins: [commonMixin, commonRules],
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },

  /**
   * データプロパティです。
   */
  data: () => ({
    commonForm: {
      belongSelected: "",
    },
    // 表示名です。
    name: "ユーザー情報", // appConfig.SCREEN_ID.P_CNT_004,
    // メニュー開閉制御です。
    openMenu: null,
    // ローディング画面表示フラグです。
    loadingCounter: 0,
    // メッセージダイアログです。
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      homePageFlag: false,
      addMoveListPageButtonText: "",
      addModeListPage: "",
      addModeListPageParams: {},
      addMovePageButtonText: "",
      addModePage: "",
      addModePageParams: {},
      outsideClickNotCloseFlg: false,
    },
    // 登録ボタン押下時確認メッセージです。
    ConfirmDialog: {
      message: "",
      redMessage: "",
      okAction: () => {},
      isOpen: false,
    },
    // 入力制御です。
    rules: {
      // 入力必須チェックです。
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      // パスワード桁数チェックです。
      password: (value) =>
        !value ||
        (value.length >= 8 && value.length <= 20) ||
        i18n.tc("check.chk_limitLength_8-20"),
      limitNumUser: (value1, value2) =>
        !value1 ||
        !value2 ||
        value1.length + value2.length <= 99 ||
        i18n.tc("check.chk_limitLength_99"),
      limitNumUserKana: (value1, value2) =>
        !value1 ||
        !value2 ||
        value1.length + value2.length <= 199 ||
        i18n.tc("check.chk_limitLength_199"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      formatKana: (value) =>
        !value || /^[ァ-ヴー]+$/.test(value) || i18n.tc("check.chk_inputZenkakuKana"),
      formatMail: (value) =>
        !value || /.+@.+\..+/.test(value) || i18n.tc("check.chk_formatAddress"),
      customerWhCheck: (value, list, index, value1) => {
        if (value != null && value != undefined && value != "") {
          for (var i = 0; i < list.length; i++) {
            if (index != i) {
              if (list[i].supplier == value1 && list[i].warehouse == value) {
                return "同じ組み合わせが存在します。";
              }
            }
          }
        }
        return true;
      },
      inputRequiredList: (value, value2, value3) => {
        if (!value) {
          if (value2 != null && value2 != undefined && value2 != "") {
            return i18n.tc("check.chk_input");
          }
          if (value3 != null && value3 != undefined && value3 != "") {
            return i18n.tc("check.chk_input");
          }
        }
        return true;
      },
    },
    // 入力判定結果です。
    isValid: false,
    // 社員コード
    employeeCode: "",
    // 有効開始日時
    validFrom: "",
    validFromCal: "",
    validFromMenu: false,
    // 有効終了日時
    validTo: "",
    validToCal: "",
    validToMenu: false,
    // パスワード有効期限日時です。
    passwordExpirationDatetime: "",
    // 新パスワードです。
    newPassword: "",
    passworderrorMsg: "",
    // 前画面で選択されたユーザSidです。
    userSid: "",
    // 前画面で選択されたユーザです。
    userInfo: {},
    // 前画面の検索条件です。
    selectPram: {},
    // 更新日時です。
    updateDatetime: "",
    // 営業所
    officeSelected: "",
    officeCd: "",
    officeName: "",
    officeList: [],
    // 職種
    jobTypeSelected: "",
    jobTypeList: [],
    // 権限
    authorityTypeSelected: "",
    authorityTypeList: [],
    // ユーザ名
    lastName: "",
    firstName: "",
    shortName: "",
    lastNameKana: "",
    firstNameKana: "",
    // メールアドレス
    emailAddress: "",
    // 表示切替(1:登録画面 2:修正・削除画面)
    displayFlg: "",
    // 初期値保持
    oldOffice: "",
    oldJobType: "",
    oldAuthorityType: "",
    oldName: "",
    oldNameKana: "",
    oldNameShort: "",
    oldMail: "",
    oldValidTo: "",
    oldPass: "",
    oldAffiliation: "",
    oldInputList: [],
    // 変更の保持
    beforeChangeOffice: "",
    beforeChangeAuthority: "",
    // 変更数
    checkCount: 0,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 警告ダイアログ
    errDialog: false,
    // プルダウン変更ダイアログ
    changeDialog: false,
    // ダイアログ表示メッセージ
    errMessage: "",
    changeMessage: "",
    // 有効終了日時バリデーション
    alertMessage: "",
    // 変更ステータス
    changeStatus: "",
    // 営業所変更フラグ
    changeOfficeFlg: false,
    //HAC対応 所属
    partsRequesterAffiliationFlg: false,
    UserManagerWarehouseFlg: false,

    headerItems: [
      //取引先
      {
        text: i18n.tc("label.lbl_supplier"),
        value: "supplier",
        width: "35%",
        align: "left",
        sortable: false,
      },
      //倉庫
      {
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "35%",
        align: "left",
        sortable: false,
      },
      //担当
      {
        text: "担当",
        value: "manager",
        width: "20%",
        align: "left",
        sortable: false,
      },
      //削除
      {
        text: i18n.tc("btn.btn_delete"),
        value: "delete",
        width: "10%",
        align: "center",
        sortable: false,
      },
    ],

    //取引先List
    supplierList: [],
    //倉庫List
    warehouseList: [],
    //担当List
    managerList: [],
    // 一覧データ
    inputList: [],
    defaultItem: {
      supplier: "",
      warehouse: "",
      delete: false,
    },
  }),

  /**
   * メソッドです。
   */
  methods: {
    /**
     * 初期起動処理です。
     */
    init() {
      // 新規登録か修正か判断
      this.displayFlg = this.$route.params.displayFlg;
      if (this.displayFlg == "1") {
        this.validFrom = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.validFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      } else if (this.displayFlg == "2") {
        // ユーザSIDを引き継ぎます。
        this.userSid = this.$route.params.userInfo.userSid;
        // ユーザ情報を引き継ぎます。
        this.userInfo = this.$route.params.userInfo;
        // 表示ユーザ情報を取得します。
        this.getUser();
      }
      this.user = sessionStorage.getItem("sales_office_sid");
      this.oldUser = this.user;
      this.officeCd = sessionStorage.getItem("office_cd");
      this.officeSelected = this.user;
      this.getCustomInfo();
      this.getAuthority();
      this.getMstCode();
      // 前画面の検索条件
      this.selectPram = this.$route.params.selectPram;
      //部品要求者所属
      this.partsRequesterAffiliationList = this.dummyList;
      this.getFunctionPermissions();
    },

    /**
     * 部品要求者必須情報取得
     */
    getFunctionPermissions() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // PC画面制御
      const functionPermissions = getParameter.getFunctionPermissions({
        conditions: [
          {
            // 部品要求者所属
            clientSid: "XXXXXXXXXX",
            attachResourceKey: appConfig.SCREEN_ID.P_MST_013,
            item01: "PartsRequesterAffiliation",
          },
          {
            // ユーザー担当倉庫
            clientSid: "XXXXXXXXXX",
            attachResourceKey: appConfig.SCREEN_ID.P_MST_013,
            item01: "UserManagerWarehouse",
          },
        ],
      });
      Promise.all([functionPermissions])
        .then((result) => {
          if (result[0].find((permission) => permission.item01 == "PartsRequesterAffiliation")) {
            this.partsRequesterAffiliationFlg = true;
            if (this.displayFlg == "2") {
              this.partsRequesterAffiliationSelected =
                this.$route.params.userInfo.partsRequesterAffiliationCode;
            }
          } else {
            this.partsRequesterAffiliationFlg = false;
          }
          if (result[0].find((permission) => permission.item01 == "UserManagerWarehouse")) {
            this.UserManagerWarehouseFlg = true;
          } else {
            this.UserManagerWarehouseFlg = false;
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * メニューを開閉します。
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 戻るボタン押下：ダイアログ表示
     */
    backPage() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },
    /**
     * 戻るボタンダイアログ：OK押下
     */
    updateOk() {
      // ユーザマスタ一覧画面に遷移します。
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_012,

        params: {
          // 前画面の検索条件を渡します。
          selectPram: this.selectPram,
          searchFlg: this.$route.params.searchFlg,
        },
      });
    },
    /**
     * 戻るボタンダイアログ：NG押下
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 変更ダイアログ：OK押下
     */
    changeOk(val) {
      if (val == "0") {
        // 営業所の変更
        this.officeSelected = this.newOffice;
        // 権限の取得
        this.getAuthority();
        // 変更の保持
        this.beforeChangeOffice = this.newOffice;
        // 営業所変更フラグ
        this.changeOfficeFlg = true;
        this.clearAuthorityType();
        this.beforeChangeAuthority = this.authorityTypeSelected;
      } else if (val == "1") {
        // 権限の変更
        this.authorityTypeSelected = this.newAuthority;
        // 変更の保持
        this.beforeChangeAuthority = this.newAuthority;
      }
      this.changeDialog = false;
    },
    /**
     * 変更ダイアログ：NG押下
     */
    changeNg(val) {
      if (val == "0") {
        // 営業所の変更
        this.officeSelected = this.beforeChangeOffice;
      } else if (val == "1") {
        // 権限の変更
        this.authorityTypeSelected = this.beforeChangeAuthority;
      }
      this.changeDialog = false;
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    errOkClicked() {
      this.errDialog = false;
    },

    /**
     * ユーザーマスタ取得APIを実行します。
     */
    getUser() {
      // 前画面からのユーザSIDの有無をチェックします。
      if (!(this.userSid ?? false)) return;

      // ローディング画面の表示をONにします。
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成します。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 各種パラメータを設定します。
      config.params.isMst = "1";
      config.params.reqComPaginationFlg = "0";
      config.params.userSid = this.userSid;

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // GET APIを実行します。
          .secureGet("master/users", config)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              jsonData.resIdv.users.forEach((row) => {
                if (
                  row.warehouseSid != null &&
                  row.warehouseSid != "" &&
                  row.clientSid != null &&
                  row.clientSid != "" &&
                  row.mainSubDiv != null &&
                  row.mainSubDiv != ""
                ) {
                  this.inputList.push({
                    supplier: row.clientSid,
                    warehouse: row.warehouseSid,
                    manager: row.mainSubDiv,
                    disableEdit: false,
                  });
                  this.oldInputList.push({
                    supplier: row.clientSid,
                    warehouse: row.warehouseSid,
                    manager: row.mainSubDiv,
                  });
                }
              });
              // ユーザ情報を取得します。
              let user = jsonData.resIdv.users[0];
              // ユーザSID
              this.userSid = user.userSid;
              // 職種
              this.jobTypeSelected = user.jobCd;
              // ユーザ（姓）
              this.lastName = user.userName.substr(0, user.userName.indexOf("　"));
              // ユーザ（名）
              this.firstName = user.userName.substr(user.userName.indexOf("　"));
              this.firstName = this.firstName.replaceAll(/\s+/g, "");
              // ユーザ（略称）
              this.shortName = user.userNameShort;
              // ユーザ（姓）（カナ）
              this.lastNameKana = user.userNameKana.substr(0, user.userNameKana.indexOf("　"));
              // ユーザ（名）（カナ）
              this.firstNameKana = user.userNameKana.substr(user.userNameKana.indexOf("　"));
              this.firstNameKana = this.firstNameKana.replaceAll(/\s+/g, "");
              // 権限
              this.authorityTypeSelected = user.roleSid;
              // メールアドレス
              this.emailAddress = user.emailAddress;
              // 社員コード
              this.employeeCode = user.userId;
              // 有効開始日時
              this.validFrom = dateTimeHelper.convertUTC2JST(user.validFrom);
              this.validFromCal = dateTimeHelper.dateCalc(this.validFrom);
              // 有効終了日時
              this.validTo = dateTimeHelper.convertUTC2JST(user.validTo);
              this.validToCal = dateTimeHelper.dateCalc(this.validTo);
              // パスワード有効期限日時
              this.passwordExpirationDatetime = dateUtil.formatDate(
                dateUtil.dateCalc(user.passwordExpirationDatetime)
              );
              // 更新日時
              this.updateDatetime = user.updateDatetime;
              //所属
              this.commonForm.belongSelected = user.belongSid;
              // 初期値保持
              this.oldOffice = this.user;
              this.beforeChangeOffice = this.oldOffice;
              this.oldJobType = user.jobCd;
              this.oldAuthorityType = user.roleSid;
              this.beforeChangeAuthority = this.oldAuthorityType;
              this.oldName = user.userName;
              this.oldNameKana = user.userNameKana;
              this.oldNameShort = user.userNameShort;
              this.oldMail = user.emailAddress;
              this.oldValidTo = dateUtil.formatDate(dateUtil.dateCalc(user.validTo));
              this.oldValidToReg = dateTimeHelper.convertUTC(user.validTo);
              this.oldPass = user.password;
              this.oldAffiliation = user.belongSid;
              // 入力フォームのバリデーションを行います。
              this.$refs.form.validate();
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              // エラーメッセージを設定します。
              this.infoDialog.message = jsonData.resCom.resComMessage;
              // タイトルを設定します。
              this.infoDialog.title = "結果";
              // メッセージダイアログを表示します。
              this.simpleDialog = true;
              // タスクを終了します（失敗）。
              reject(resolve);
            }
          })
          // 失敗時の処理です。
          .catch((resolve) => {
            // ログ出力します。
            console.error("getUsers() Resolve", resolve);
            // エラーメッセージを設定します。
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            // タイトルを設定します。
            this.infoDialog.title = appConfig.DIALOG.title;
            // メッセージダイアログを表示します。
            this.simpleDialog = true;
            // タスクを終了します（失敗）。
            reject(resolve);
          })
          // 成功・失敗に関わらず行う処理です。
          .finally(() => {
            // ローディング画面の表示をOFFにします。
            this.loadingCounter = 0;
          });
      });
    },

    passwordinput() {
      this.passworderrorMsg = "";
      //パスワードバリデーションチェック
      const regex = passwordUtil.passValidation.regex;
      if (this.newPassword) {
        if (21 > this.newPassword.length && this.newPassword.length > 7) {
          if (!regex.test(this.newPassword)) {
            this.passworderrorMsg = i18n.tc("check.chk_pass");
            return;
          }
        }
      }
    },

    /**
     * ユーザ情報を送信します。
     */
    sendUser() {
      // 入力フォームのバリデーションを行います。
      this.isValid = this.$refs.form.validate();

      // バリデーション結果が正常の場合の処理です。
      if (this.isValid && this.$refs.form2.validate()) {
        // メッセージを設定します。
        if (this.displayFlg == "1") {
          this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        } else if (this.displayFlg == "2") {
          if (
            // 有効終了日が有効開始日時よりも先日付であるかチェック
            new Date(this.validFrom).getTime() > new Date(this.validTo).getTime()
          ) {
            this.alertMessage = i18n.tc("check.chk_inputValiFrom");
            return;
          } else {
            this.alertMessage = "";
            this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_002_C");
          }
        }
        // 確認ダイアログを表示します。
        this.ConfirmDialog.isOpen = true;

        // screenFlagを立てます。
        this.ConfirmDialog.screenFlag = true;
      } else {
        // バリデーション結果が異常の場合の処理です。
      }
    },

    /**
     * ユーザ登録更新
     */
    addUpdateUser() {
      if (this.displayFlg == "1") {
        this.userAdd();
      } else if (this.displayFlg == "2") {
        // 変更チェック
        let changeResult = this.checkChangeForm();
        // 変更無しの場合
        if (changeResult == 0) {
          this.errDialog = true;
          this.errMessage = messsageUtil.getMessage("P-MST-013_001_E");
          return;
        }
        this.userUpdate();
      }
    },

    /**
     * ユーザ登録
     */
    userAdd() {
      // ローディング画面の表示をON
      this.loadingCounter = 1;

      return new Promise((resolve, reject) => {
        this.setDBUser()
          .then(() => {
            this.addCognitoUser()
              .then(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;

                // 遷移メッセージダイアログの表示
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.addMoveListPageButtonText = i18n.tc("label.lbl_userList");
                this.infoDialog.addModeListPage = "P_MST_012";
                this.infoDialog.addModeListPageParams = {
                  // 前画面の検索条件
                  selectPram: this.selectPram,
                };
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = false;
                this.infoDialog.homePageFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                resolve();
              })
              .catch((ex) => {
                //Cognito処理失敗時
                //DB戻し処理を実行
                // httpClientで使用する共通IFパラメータ等を作成します。
                const body = this.$httpClient.createRequestBodyConfig();

                // 共通IF項目
                // 画面IDを設定します。
                body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_013;

                // 固有IF項目
                // ユーザSIDを設定します。
                body.reqIdv.userSid = this.addUserSid;

                // 非同期処理を開始します。
                return new Promise((resolve, reject) => {
                  this.$httpClient
                    // ユーザ情報登録POST APIを実行します。
                    .securePost(appConfig.API_URL.MST_USER_DELETE, body, appConfig.APP_CONFIG)
                    // 成功時の処理です。
                    .then((response) => {
                      // console.debug("postApi() Response", response);
                      const jsonData = JSON.parse(JSON.stringify(response.data));
                      //正常時
                      if (jsonData.resCom.resComCode == "000") {
                        // タスクを終了します（成功）。
                        // ローディング画面表示OFF
                        this.loadingCounter = 0;
                        this.infoDialog.message = messsageUtil.getMessage("P-MST-013_002_E");
                        this.infoDialog.title = appConfig.DIALOG.title;
                        this.infoDialog.isOpen = true;
                        this.infoDialog.screenFlag = true;
                        this.infoDialog.firstPageFlag = true;
                        this.loadingCounter = 0;
                        // Cognito登録時にエラー
                        resolve(ex);
                        //エラー時
                      } else {
                        this.infoDialog.message = messsageUtil.getMessage("P-MST-013_003_E");
                        this.infoDialog.title = appConfig.DIALOG.title;
                        this.infoDialog.isOpen = true;
                        this.infoDialog.screenFlag = true;
                        this.infoDialog.firstPageFlag = true;
                        this.loadingCounter = 0;
                        reject(response);
                      }
                    })
                    // 失敗時の処理です。
                    .catch((ex) => {
                      // タスクを終了します（失敗）。
                      reject(ex);
                    });
                });
              });
          })
          .catch((ex) => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            // DB登録でエラー
            reject(ex);
          });
      })
        .catch((ex) => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
          console.log(ex);
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
          this.infoDialog.homePageFlag = false;
        })
        .finally(() => {});
    },

    /**
     * ユーザ情報を更新します。
     */
    userUpdate() {
      // ローディング画面の表示をONにします。
      this.loadingCounter = 1;

      const oldpassword = this.userInfo.password;

      // ユーザー編集開始
      return new Promise((resolve, reject) => {
        this.setDBUser()
          .then(() => {
            this.setCognitoUser(oldpassword)
              .then(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
                // DB、Cognitoともにパスワード変更完了
                this.userInfo.password = this.newPassword;

                // 遷移メッセージダイアログの表示
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.addMoveListPageButtonText = i18n.tc("label.lbl_userList");
                this.infoDialog.addModeListPage = "P_MST_012";
                this.infoDialog.addModeListPageParams = {
                  // 前画面の検索条件
                  selectPram: this.selectPram,
                };
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = false;
                this.infoDialog.homePageFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                resolve();
              })
              .catch((ex) => {
                //Cognito処理失敗時
                //DB戻し処理を実行
                // httpClientで使用する共通IFパラメータ等を作成します。
                const body = this.$httpClient.createRequestBodyConfig();

                // 共通IF項目
                // 画面ID
                body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_013;

                // 固有IF項目
                // ユーザSID
                body.reqIdv.userSid = this.userSid;
                // 新パスワード
                body.reqIdv.password = oldpassword;
                // 営業所
                body.reqIdv.officeSid = this.oldOffice;
                // 職種
                body.reqIdv.jobCd = this.oldJobType;
                // 権限
                body.reqIdv.roleSid = this.oldAuthorityType;
                // 有効開始日時
                body.reqIdv.validFrom = dateTimeHelper.convertUTC(this.validFrom);
                // 有効終了日時
                body.reqIdv.validTo = this.oldValidToReg;
                // ユーザー名
                body.reqIdv.userName = this.oldName;
                // ユーザー(略称)
                body.reqIdv.userNameShort = this.oldNameShort;
                // ユーザー名(カナ)
                body.reqIdv.userNameKana = this.oldNameKana;
                // メールアドレス
                body.reqIdv.emailAddress = this.oldMail;

                // 非同期処理を開始します。
                return new Promise((resolve, reject) => {
                  this.$httpClient
                    // ユーザ情報登録POST APIを実行します。
                    .securePost(appConfig.API_URL.MST_USER_UPDATE, body, appConfig.APP_CONFIG)
                    // 成功時の処理です。
                    .then((response) => {
                      // console.debug("postApi() Response", response);
                      const jsonData = JSON.parse(JSON.stringify(response.data));
                      //正常時
                      if (jsonData.resCom.resComCode == "000") {
                        // タスクを終了します（成功）。
                        // ローディング画面表示OFF
                        this.loadingCounter = 0;
                        this.infoDialog.message = messsageUtil.getMessage("P-MST-049_001_E");
                        this.infoDialog.title = appConfig.DIALOG.title;
                        this.infoDialog.isOpen = true;
                        this.infoDialog.screenFlag = true;
                        this.infoDialog.firstPageFlag = true;
                        this.loadingCounter = 0;
                        // Cognito登録時にエラー
                        resolve(ex);
                        //エラー時
                      } else {
                        this.infoDialog.message = messsageUtil.getMessage("P-MST-049_002_E");
                        this.infoDialog.title = appConfig.DIALOG.title;
                        this.infoDialog.isOpen = true;
                        this.infoDialog.screenFlag = true;
                        this.infoDialog.firstPageFlag = true;
                        this.loadingCounter = 0;
                        reject(response);
                      }
                    })
                    // 失敗時の処理です。
                    .catch((ex) => {
                      // タスクを終了します（失敗）。
                      reject(ex);
                    });
                });
              });
          })
          .catch((ex) => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            // DB登録でエラー
            reject(ex);
          });
      })
        .catch((ex) => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
          console.log(ex);
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
          this.infoDialog.homePageFlag = false;
        })
        .finally(() => {});
    },

    /**
     * 入力項目の変更チェック
     */
    checkChangeForm() {
      let newName = this.lastName + "　" + this.firstName;
      let newNameKana = this.lastNameKana + "　" + this.firstNameKana;

      if (this.oldOffice !== this.officeSelected) {
        this.checkCount++;
      } else if (this.oldJobType !== this.jobTypeSelected) {
        this.checkCount++;
      } else if (this.oldAuthorityType !== this.authorityTypeSelected) {
        this.checkCount++;
      } else if (this.oldName !== newName) {
        this.checkCount++;
      } else if (this.oldNameKana !== newNameKana) {
        this.checkCount++;
      } else if (this.oldNameShort !== this.shortName) {
        this.checkCount++;
      } else if (this.oldMail !== this.emailAddress) {
        this.checkCount++;
      } else if (this.oldValidTo !== this.validTo) {
        this.checkCount++;
      } else if (this.newPassword !== "" && this.newPassword !== this.oldPass) {
        this.checkCount++;
      } else if (this.commonForm.belongSelected !== this.oldAffiliation) {
        this.checkCount++;
      } else if (this.inputList.length !== this.oldInputList.length) {
        this.checkCount++;
      } else {
        for (let i = 0; i < this.inputList.length; i++) {
          if (
            this.inputList[i].supplier !== this.oldInputList[i].supplier ||
            this.inputList[i].warehouse !== this.oldInputList[i].warehouse ||
            this.inputList[i].manager !== this.oldInputList[i].manager
          ) {
            this.checkCount++;
            break;
          }
        }
      }
      return this.checkCount;
    },

    /**
     * DBにユーザー情報を設定します。
     */
    setDBUser() {
      // httpClientで使用する共通IFパラメータ等を作成します。
      const body = this.$httpClient.createRequestBodyConfig();

      // 共通IF項目
      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_013;

      // 固有IF項目
      // ユーザSID
      body.reqIdv.userSid = this.userSid;
      // ユーザID
      body.reqIdv.userId = this.employeeCode;
      // 新パスワード
      body.reqIdv.password = this.newPassword;
      // 更新日時
      body.reqIdv.updateDatetime = this.updateDatetime;
      // 営業所
      body.reqIdv.officeSid = this.officeSelected;
      // 営業所CD
      body.reqIdv.officeCd = this.officeCd;
      // 職種
      body.reqIdv.jobCd = this.jobTypeSelected;
      // 権限
      body.reqIdv.roleSid = this.authorityTypeSelected;
      // 有効開始日時
      body.reqIdv.validFrom = dateTimeHelper.convertUTC(this.validFrom);
      // 有効終了日時
      body.reqIdv.validTo = dateTimeHelper.convertUTC(this.validTo);
      // ユーザー名
      body.reqIdv.userName = this.lastName + "　" + this.firstName;
      // ユーザー(略称)
      body.reqIdv.userNameShort = this.shortName;
      // ユーザー名(カナ)
      body.reqIdv.userNameKana = this.lastNameKana + "　" + this.firstNameKana;
      // メールアドレス
      body.reqIdv.emailAddress = this.emailAddress;
      // 所属
      body.reqIdv.belongSid = this.commonForm.belongSelected;
      //ユーザー倉庫マスタ
      const userWarehouseList = [];
      for (let i = 0; i < this.inputList.length; i++) {
        if (
          this.inputList[i].warehouse &&
          this.inputList[i].supplier &&
          this.inputList[i].manager
        ) {
          userWarehouseList.push({
            clientSid: this.inputList[i].supplier,
            warehouseSid: this.inputList[i].warehouse,
            mainSubDiv: this.inputList[i].manager,
          });
        }
      }
      if (userWarehouseList.length != 0) {
        body.reqIdv.warehouseUserList = userWarehouseList;
      }

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // ユーザ情報登録POST APIを実行します。
          .securePost(appConfig.API_URL.MST_USER_UPDATE, body, appConfig.APP_CONFIG)
          // 成功時の処理です。
          .then((response) => {
            // console.debug("postApi() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            //正常時
            if (jsonData.resCom.resComCode == "000") {
              this.pass = jsonData.resIdv.userInfo.password;
              this.addUserSid = jsonData.resIdv.userInfo.userSid;
              // タスクを終了します（成功）。
              resolve(response);
              //エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject(response);
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            // タスクを終了します（失敗）。
            reject(ex);
          });
      });
    },

    /**
     * DBにユーザー情報を設定します。
     */
    getPassword() {
      // httpClientで使用する共通IFパラメータ等を作成します。
      const body = this.$httpClient.createRequestBodyConfig();

      // 共通IF項目
      // 画面IDを設定します。
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_COM_007;

      // 固有IF項目
      // ユーザSIDを設定します。
      body.reqIdv.userSid = this.userSid;
      // 新パスワードを設定します。
      body.reqIdv.password = this.newPassword;

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // ユーザ情報登録POST APIを実行します。
          .securePost("master/users_password", body, appConfig.APP_CONFIG)
          // 成功時の処理です。
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // タスクを終了します（成功）。
            console.log("暗号化前：" + this.newPassword);
            console.log("暗号化後：" + jsonData.resCom.resComMessage);
          })
          // 失敗時の処理です。
          .catch((ex) => {
            // タスクを終了します（失敗）。
            reject(ex);
          });
      });
    },

    /**
     * Cognitoにユーザー情報を設定します。
     */
    setCognitoUser(val) {
      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        if (this.newPassword !== "" && this.newPassword !== val) {
          console.log("setCognitoUser");
          cognito
            // Cognitoのユーザパスワード変更を実行します。
            .changePassword(this.userInfo.userId, val, this.newPassword)
            // 成功時の処理です。
            .then((response) => {
              // タスクを終了します（成功）。
              resolve(response);
            })
            // 失敗時の処理です。
            .catch((ex) => {
              // タスクを終了します（失敗）。
              reject(ex);
            });
        }
        // ユーザ名
        let newName = this.lastName + "　" + this.firstName;
        if (newName !== this.oldName) {
          console.log("updateCognitoName");
          cognito
            // Cognitoのユーザー名変更を実行します。
            .changeUserName(this.userInfo.userId, this.lastName, this.firstName)
            // 成功時の処理です。
            .then((response) => {
              // タスクを終了します（成功）。
              resolve(response);
            })
            // 失敗時の処理です。
            .catch((ex) => {
              // タスクを終了します（失敗）。
              reject(ex);
            });
        }
        resolve();
      });
    },
    /**
     * パスワード生成です。
     */
    isPasswordGeneration() {
      // パスワード生成がチェックされた場合の処理です。
      // ランダムなパスワードを画面に表示します。
      this.newPassword = passwordUtil.passwordGeneration();
      this.passwordinput();
    },
    /**
     * パスワード初期化です。
     */
    isPasswordInitialize() {
      // パスワード初期化がチェックされた場合の処理です
      // 初期パスワードを画面に表示します。
      this.newPassword = this.employeeCode + appConfig.USER_PASSWORD.addInitializeCode;
      this.passwordinput();
    },

    /**
     * Cognitoにユーザー登録
     */
    addCognitoUser() {
      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        cognito
          // Cognitoのユーザ登録を実行
          .userRegistration(this.employeeCode, this.pass, this.lastName, this.firstName)
          // 成功時の処理です。
          .then((response) => {
            // タスクを終了します（成功）
            resolve(response);
          })
          // 失敗時の処理です。
          .catch((ex) => {
            // タスクを終了します（失敗）
            reject(ex);
          });
      });
    },

    /**
     * 営業所プルダウン
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.isMst = "1";
      config.params.relatedPartyDiv = "02";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATED, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getCustomInfo() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [{ text: "", name: "", value: "" }];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                if (row.relatedPartyLanguage.length > 0) {
                  list.push({
                    text: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                    value: row.relatedPartyLanguage[0].relatedPartySid ?? "",
                    name: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                  });
                }
              });
              this.officeList = list;
              if (this.displayFlg == "1") {
                for (let i = 0; i < this.officeList.length; i++) {
                  if (this.officeSelected == this.officeList[i].value) {
                    this.officeName = this.officeList[i].text;
                  }
                }
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 営業所リストボックス取得処理
     */
    setOffice() {
      for (var i = 0; i < this.officeList.length; i++) {
        if (this.officeList[i].value == this.officeSelected) {
          if (this.displayFlg == "1") {
            this.officeSelected = this.officeList[i].text;
          } else {
            return this.officeList[i].name;
          }
        }
      }
    },

    /**
     * コードマスタ取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const jobList = getParameter.getCodeMst(appConfig.CODETYPE.JOB_TYPE);
      //取引先マスタ取得
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC("")
      );
      const whList = getParameter.getWarehouseBiz();
      const mgList = getParameter.getCodeMst(appConfig.CODETYPE.IN_CHARGE_DIV);

      Promise.all([jobList, clientList, whList, mgList])
        .then((result) => {
          // console.debug("getMstCode() Result", result);
          this.jobTypeList = result[0];
          this.supplierList = result[1];
          this.warehouseList = result[2];
          this.managerList = result[3];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 権限クリア
     */
    clearAuthorityType() {
      this.authorityTypeSelected = "";
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 有効開始日時を－１日します。
     */
    prevDate() {
      let date = new Date(this.validFromCal);
      this.validFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },

    /**
     * 有効開始日時を＋１日します。
     */
    nextDate() {
      let date = new Date(this.validFromCal);
      this.validFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * 有効終了日時を－１日します。
     */
    prevToDate() {
      let date = new Date(this.validToCal);
      this.validToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },

    /**
     * 有効終了日時を＋１日します。
     */
    nextToDate() {
      let date = new Date(this.validToCal);
      this.validToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * 日付入力(有効開始日時)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.validFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.validFromCal = null;
      }
    },

    /**
     * 日付入力(有効終了日時)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.validToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.validToCal = null;
      }
    },

    // 権限プルダウン
    getAuthority() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      if (this.officeSelected) {
        config.params.officeSid = this.officeSelected;
      }

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_ROLELIST, config)
          .then((response) => {
            // console.debug("getAuthority() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.roleList.forEach((row) => {
                list.push({
                  text: row.roleName,
                  value: row.roleSid,
                  name: row.roleName,
                });
              });
              this.authorityTypeList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 権限変更処理
     */
    changeAuthorityType(val) {
      if (
        val != this.oldAuthorityType &&
        val != this.beforeChangeAuthority &&
        !this.changeOfficeFlg
      ) {
        this.alertDisplay(val, "1");
      } else {
        this.beforeChangeAuthority = val;
        this.changeOfficeFlg = false;
      }
    },

    /**
     * 職種リストボックス取得処理
     */
    setJobType() {
      for (var i = 0; i < this.jobTypeList.length; i++) {
        if (this.jobTypeList[i].value == this.jobTypeSelected) {
          return this.jobTypeList[i].name;
        }
      }
    },

    /**
     * 権限リストボックス取得処理
     */
    setAuthority() {
      for (var i = 0; i < this.authorityTypeList.length; i++) {
        if (this.authorityTypeList[i].value == this.authorityTypeSelected) {
          return this.authorityTypeList[i].name;
        }
      }
    },

    /**
     * 警告メッセージの表示
     */
    alertDisplay(val1, val2) {
      this.changeDialog = true;
      // 営業所の変更
      if (val2 == "0") {
        this.changeStatus = "0";
        // 変更の値をセット
        this.newOffice = val1;
        this.changeMessage = messsageUtil.getMessage("P-MST-049_003_E", [
          i18n.tc("label.lbl_salesOffice"),
        ]);
        // 権限の変更
      } else if (val2 == "1") {
        this.changeStatus = "1";
        // 変更の値をセット
        this.newAuthority = val1;
        this.changeMessage = messsageUtil.getMessage("P-MST-049_003_E", [
          i18n.tc("label.lbl_authorityType"),
        ]);
      }
    },

    /**
     * 行追加
     */
    add() {
      this.inputList.push(Object.assign({}, this.defaultItem));
    },

    /**
     * 行削除
     */
    deleteItem(item) {
      this.inputList = this.inputList.filter((i) => i !== item);
    },
  },

  /**
   * インスタンス初期化時（DOM生成前）に実行される処理です。
   */
  created() {
    // 初期起動処理を実行します。
    this.init();
  },

  /**
   * インスタンス初期化時（DOM生成後）に実行される処理です。
   */
  mounted() {},

  /**
   * 算出プロパティです。
   */
  computed: {},

  /**
   * 監視プロパティです。
   */
  watch: {
    validFromCal(val) {
      this.validFrom = this.formatDate(val);
    },
    validToCal(val) {
      this.validTo = this.formatDate(val);
    },
    officeSelected(val) {
      if (val != this.oldUser && val != this.beforeChangeOffice) {
        this.alertDisplay(val, "0");
      } else {
        if (val || null) {
          this.officeSelected = val;
          this.getAuthority();
        } else {
          this.officeSelected = val;
          this.getAuthority();
        }
      }
    },
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.add-textbox-mail {
  width: 34rem;
  margin-top: 8px;
  margin-left: 15px;
}
.add-textbox-user {
  width: 16rem;
  margin-left: 15px;
}
.add-textbox-date {
  margin-top: 4px;
  margin-left: 5px;
}
</style>
